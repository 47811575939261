import { Component, Input, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotificationsService } from 'src/app/project/modules/notifications/notifications.service';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EStore } from '../../../../../shared/enums/store.enum';
import { TUser } from '../../../../user/user.model';
import { TWorkspacesById } from '../../../../workspace/workspace.model';
import { TSidePanel } from '../../side-panel.model';
import { SidePanelService } from '../../side-panel.service';
import { SidePanelUtilsService } from '../side-panel-utils.service';

@Component({
  selector: 'pp-side-panel-notifications',
  templateUrl: './side-panel-notifications.component.html',
  styleUrls: ['./side-panel-notifications.component.scss'],
})
export class SidePanelNotificationsComponent implements OnDestroy {
  @Input() ppUser: TUser;
  @Input() ppDisabled: boolean;

  EIconPath = EIconPath;
  notificationsAvailable = this.notificationsService.notificationsAvailable();
  sidePanel: TSidePanel = this.sidePanelService.getSidePanel();
  workspaces$: Observable<TWorkspacesById>;
  hasWorkspaces = false;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private store: Store<{
      workspaces: TWorkspacesById;
    }>,
    private notificationsService: NotificationsService,
    private sidePanelUtilsService: SidePanelUtilsService,
    private sidePanelService: SidePanelService,
  ) {
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      this.hasWorkspaces = !!workspaces;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  toggleNotifications(): void {
    this.sidePanelUtilsService.toggleNotifications();
  }
}
