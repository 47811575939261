import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { LimitExceededService } from 'src/app/project/components/change-limit-modal/limit-exceeded.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { logErrorInSentry } from 'src/app/project/modules/errors/response-error';
import {
  setChangedWorkspace,
  setChangedWorkspaces,
} from 'src/app/project/modules/workspace/workspace';
import { UpdateWorkspaceUsers } from 'src/app/project/modules/workspace/workspace.actions';
import { TWorkspace } from 'src/app/project/modules/workspace/workspace.model';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventUserAccess,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { ModalService } from '../../../components/modal/modal.service';
import { SiteSettingsUsersService } from '../../site/site-settings/site-settings-users/site-settings-users.service';
import { TImportShareWorkspaceUsersDTO, TShare } from '../share.model';
import { SharesService } from '../shares.service';

@Injectable({
  providedIn: 'root',
})
export class ImportUsersModalService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private readonly shouldCloseModal = new Subject<boolean>();

  constructor(
    private store: Store,
    private sharesService: SharesService,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private modalService: ModalService,
    private siteSettingsUsersService: SiteSettingsUsersService,
    private limitExceededService: LimitExceededService,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  importShares(
    workspace: TWorkspace,
    userList: TImportShareWorkspaceUsersDTO,
    shareWorkspaceId: string,
    isFromChangeLimitsModal: boolean = false,
  ): Observable<TShare[]> {
    return this.sharesService.importShares(shareWorkspaceId, userList).pipe(
      tap((response) => {
        this.onImportSharesSuccess({
          response,
          userList,
          workspace,
          shareWorkspaceId,
          isFromChangeLimitsModal,
        });
      }),
      catchError((error) => this.onImportSharesError(error)),
    );
  }

  private onImportSharesError(error: any): Observable<never> {
    logErrorInSentry(error);
    this.shouldCloseModal.next(false);

    const promptText = this.translationPipe.transform('prompt_share_import_error');

    if (error.status === EStatusCode.UPGRADE_REQUIRED) {
      this.limitExceededService.showLimitsExceededModal();

      return;
    }

    this.promptService.showError(promptText);

    return throwError(error);
  }

  private onImportSharesSuccess({
    response,
    userList,
    workspace,
    shareWorkspaceId,
    isFromChangeLimitsModal,
  }: {
    response: TShare[];
    userList: TImportShareWorkspaceUsersDTO;
    workspace: TWorkspace;
    shareWorkspaceId: string;
    isFromChangeLimitsModal: boolean;
  }): void {
    const promptText = this.translationPipe.transform('prompt_share_import');

    this.sharesService.addWorkspaceShares(response);
    this.addWorkspaceUsers(userList, workspace, shareWorkspaceId);
    // checking if the method has been called from the other component
    if (isFromChangeLimitsModal) {
      this.shouldCloseModal.next(true);
    } else {
      this.modalService.hideModal();
    }
    this.modalService.hideModal();

    setChangedWorkspace(shareWorkspaceId, true);
    this.siteSettingsUsersService.newShareCloseTriggerEvent();

    logEventInGTAG(EGoogleEventUserAccess.USER_ACCESS__IMPORT, {
      event_category: EGoogleEventCategory.USER_ACCESS,
    });
    this.promptService.showSuccess(promptText);
    setChangedWorkspaces(true);
    setChangedWorkspace(shareWorkspaceId);
  }

  private addWorkspaceUsers(
    userList: TImportShareWorkspaceUsersDTO,
    workspace: TWorkspace,
    workspaceId: string,
  ): void {
    userList.forEach((user) => {
      workspace.users.push(user.userId);
    });

    this.store.dispatch(
      new UpdateWorkspaceUsers({
        workspaceId,
        users: workspace.users,
      }),
    );
  }
}
