import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-side-panel-menu-button',
  templateUrl: './side-panel-menu-button.component.html',
  styleUrl: './side-panel-menu-button.component.scss',
})
export class SidePanelMenuButtonComponent {
  @ViewChild('menuButton') menuButton: ElementRef<HTMLButtonElement>;
  @Input() ppIconSrc: EIconPath;
  @Input() ppText: string;
  @Input() ppDataTarget: string;
  @Input() ppDisabled: boolean;
  @Input() ppDataTest: string;
  @Input() ppActive: boolean;
  @Input() ppShowIndicator: boolean;

  @Output() ppAction = new EventEmitter<void>();

  EIconPath = EIconPath;

  ngAfterViewInit(): void {
    this.menuButton.nativeElement.setAttribute('data-m-target', this.ppDataTarget);
    this.menuButton.nativeElement.setAttribute('data-test', this.ppDataTest);
  }

  action(): void {
    if (this.ppDisabled) {
      return;
    }

    this.ppAction.emit();
  }
}
