import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSidePanel,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TSidePanel } from '../../side-panel.model';
import { SidePanelService } from '../../side-panel.service';

@Component({
  selector: 'pp-side-panel-dashboard',
  templateUrl: './side-panel-dashboard.component.html',
  styleUrls: ['./side-panel-dashboard.component.scss'],
})
export class SidePanelDashboardComponent {
  @Input() ppRouterPath: string;
  @Input() ppActiveWorkspaceId: string;
  @Input() ppDisabled: boolean;

  sidePanel: TSidePanel = this.sidePanelService.getSidePanel();
  EIconPath = EIconPath;

  constructor(
    private router: Router,
    private sidePanelService: SidePanelService,
  ) {}

  goToDashboard(): void {
    if (this.ppActiveWorkspaceId) {
      const routerLink = '/dashboard';

      this.sidePanel.notificationsExpanded = false;
      logEventInGTAG(EGoogleEventSidePanel.SIDE_PANEL_DASHBOARD, {
        event_category: EGoogleEventCategory.SIDE_PANEL,
      });

      this.router.navigate([routerLink]);
    }
  }
}
