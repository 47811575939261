import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TAccountSimpleResponse } from '@project/view-models';
import { cloneDeep } from 'lodash';
import { pipe, Subject, takeUntil, tap } from 'rxjs';
import {
  flattenAccounts,
  TFlattenedAccount,
} from '../../../account-list/account-list/account-list-flatten-account';
import { groupAccountListData } from '../../../account-list/account-list/group-account-list-data';
import { NEW_FOLDER_ID } from '../../../account-list/utils/account-list-constants';
import { DELETE_COLLAPSED_FOLDER } from '../../../account-list/utils/account-list.ui.store';
import { ESidePanelItem } from '../../../account-list/utils/side-panel-item.enum';
import { PreferencesService } from '../../../preferences/preferences-service/preferences.service';
import { SidePanelSitesListService } from '../side-panel-sites-list.service';

@Component({
  selector: 'pp-side-panel-accounts',
  templateUrl: './side-panel-accounts.component.html',
  styleUrl: './side-panel-accounts.component.scss',
})
export class SidePanelAccountsComponent implements OnChanges, OnInit, OnDestroy {
  @Input() ppKeyword: string;
  @ViewChild('sitesList') sitesListElement: ElementRef<HTMLElement>;
  @ViewChild(CdkVirtualScrollViewport) viewport!: CdkVirtualScrollViewport;

  private readonly destroy$ = new Subject<void>();

  private accounts: TAccountSimpleResponse[] = [];
  private filteredAccounts: TAccountSimpleResponse[];
  flattenedAccounts: TFlattenedAccount[];
  ESidePanelItem = ESidePanelItem;
  itemHeights: number[] = [];
  dragged: boolean;

  constructor(
    private sidePanelSitesListService: SidePanelSitesListService,
    private preferencesService: PreferencesService,
  ) {
    this.sidePanelSitesListService.accountsChange$
      .pipe(
        takeUntil(this.destroy$),
        pipe(
          tap((response) => {
            this.accounts = response;

            this.filterAccounts();
          }),
        ),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  ngOnChanges(): void {
    this.filterAccounts();
  }

  ngOnInit(): void {
    this.sidePanelSitesListService
      .fetchWorkspaces()
      .pipe(
        tap((accounts) => {
          this.accounts = accounts;

          this.filterAccounts();
          this.scrollToActiveWorkspace();
        }),
      )
      .subscribe();
  }

  drop(event: CdkDragDrop<TFlattenedAccount[], any, any>): void {
    this.fixFirefoxClickEvent();
    const droppedElementHTML = document.elementFromPoint(event.dropPoint.x, event.dropPoint.y);

    const droppedElementItem = droppedElementHTML.closest('.cdk-drag');
    const movedWorkspace = {
      accountId: event.item.data.accountId,
      workspaceId: event.item.data.workspace.workspaceId,
      folderId: event.item.data.folderId,
    };

    if (!droppedElementItem) {
      this.sidePanelSitesListService.removeSiteFromFolder(
        movedWorkspace.accountId,
        movedWorkspace.workspaceId,
        movedWorkspace.folderId,
      );

      return;
    }

    if (movedWorkspace.accountId !== droppedElementItem.getAttribute('accountId')) {
      if (movedWorkspace.folderId) {
        this.sidePanelSitesListService.removeSiteFromFolder(
          movedWorkspace.accountId,
          movedWorkspace.workspaceId,
          movedWorkspace.folderId,
        );
      }

      return;
    }

    const newFolderId = droppedElementItem.getAttribute('folderId');
    const oldFolderId = movedWorkspace.folderId;

    if (newFolderId === oldFolderId) {
      return;
    }

    if (!newFolderId && !movedWorkspace.folderId) {
      return;
    }

    if (newFolderId) {
      this.sidePanelSitesListService.addSiteToFolder(
        movedWorkspace.accountId,
        movedWorkspace.workspaceId,
        newFolderId,
      );
    } else {
      this.sidePanelSitesListService.removeSiteFromFolder(
        movedWorkspace.accountId,
        movedWorkspace.workspaceId,
        movedWorkspace.folderId,
      );
    }
  }

  private fixFirefoxClickEvent(): void {
    // known issue in Firefox where a click event is fired after a drop event when using Angular CDK Drag & Drop
    // We need to stop this click event or it will trigger click on a workspace and open it
    this.dragged = true;

    setTimeout(() => {
      this.dragged = false;
    }, 100);
  }

  updateList(): void {
    this.filterAccounts();
  }

  trackByFunction(index: number, item: TFlattenedAccount): string {
    return item.id;
  }

  private filterAccounts(): void {
    this.filteredAccounts = cloneDeep(this.accounts).filter((account) => {
      const accountNameMatch =
        account.name.toLowerCase().indexOf(this.ppKeyword.toLowerCase()) !== -1;

      if (accountNameMatch) {
        return true;
      }

      account.workspaces = account.workspaces.filter((workspace) => {
        const folderName =
          account.accountFolders.find(
            (folder) => folder.workspaceIds?.indexOf(workspace.workspaceId) !== -1,
          )?.name || '';
        const workspaceName = workspace.name;

        return (
          folderName.toLowerCase().indexOf(this.ppKeyword.toLowerCase()) !== -1 ||
          workspaceName.toLowerCase().indexOf(this.ppKeyword.toLowerCase()) !== -1
        );
      });

      account.accountFolders = account.accountFolders.filter((folder) => {
        const folderName = folder.name;
        const workspaceNames = folder.workspaceIds
          ? folder.workspaceIds.map(
              (workspaceId) =>
                account.workspaces.find((workspace) => workspace.workspaceId === workspaceId)
                  ?.name || '',
            )
          : [];

        const containsWorkspaceWithKeyword = workspaceNames.some(
          (workspaceName) =>
            workspaceName.toLowerCase().indexOf(this.ppKeyword.toLowerCase()) !== -1,
        );

        const folderMatches =
          folderName.toLowerCase().indexOf(this.ppKeyword.toLowerCase()) !== -1 ||
          containsWorkspaceWithKeyword;

        if (containsWorkspaceWithKeyword) {
          DELETE_COLLAPSED_FOLDER(account.id, folder.id);
        }

        return folderMatches || folder.id === NEW_FOLDER_ID;
      });

      return account.workspaces.length || account.accountFolders.length;
    });

    const groupedAccounts = groupAccountListData(this.filteredAccounts);
    this.flattenedAccounts = flattenAccounts(
      groupedAccounts,
      this.preferencesService.getPreferences().favouriteWorkspaceIds,
    );
    this.generateItemHeights();

    this.fixWonkyScroll();
  }

  private fixWonkyScroll(): void {
    if (!this.viewport) {
      return;
    }

    this.viewport.checkViewportSize(); // Recalculate size

    setTimeout(() => {
      this.viewport.checkViewportSize(); // Call it again after a slight delay
    }, 0);
  }

  private generateItemHeights(): void {
    this.itemHeights = this.flattenedAccounts.map((item) => {
      switch (item.type) {
        case ESidePanelItem.ACCOUNT:
          return 43;
        case ESidePanelItem.WORKSPACE:
          if (window.location.href.includes(item.workspace.workspaceId)) {
            return 34;
          }

          return 26;
        case ESidePanelItem.NEW_SITE_BUTTON:
          return 28;
        case ESidePanelItem.FOLDER:
          return 26;
        case ESidePanelItem.END_LINE:
          return 34;
        case ESidePanelItem.FOLDER_EMPTY:
          return 26;
        case ESidePanelItem.FAVOURITES:
          return 34;
        default:
          return 0;
      }
    });
  }

  private scrollToActiveWorkspace(): void {
    if (this.flattenedAccounts.length === 0) {
      return;
    }

    for (const [index, item] of this.flattenedAccounts.entries()) {
      if (item.type === ESidePanelItem.WORKSPACE && window.location.href.includes(item.id)) {
        const checkIfLoadedInterval = setInterval(() => {
          if (this.sitesListElement?.nativeElement.children[index]) {
            this.sitesListElement.nativeElement.children[index].scrollIntoView();

            clearInterval(checkIfLoadedInterval);
          }
        }, 100);

        break;
      }
    }
  }
}
