<div class="sidePanelLogo__wrap">
  <img
    class="sidePanelLogo__img"
    [src]="ppMobile ? EIconPath.GALLERY_CLOSE : EIconPath.LOGO_SMALL"
    (click)="ppMobile ? collapsePanel() : ''"
    *ngIf="!whiteLabel?.data?.logoDark"
  />

  <img
    class="sidePanelLogo__img sidePanelLogo__img--custom"
    [ppSecureSrc]="'api/v1/images/' + whiteLabel?.data?.logoDark + '/file/size/bounded/400'"
    *ngIf="whiteLabel?.data?.logoDark"
  />
</div>
