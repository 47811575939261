import { Component, EventEmitter, Output } from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import {
  GET_FAVOURITES_COLLAPSED,
  SET_FAVOURITES_COLLAPSED,
} from '../../../account-list/utils/account-list.ui.store';
import { PreferencesService } from '../../../preferences/preferences-service/preferences.service';
import { WorkspaceService } from '../../../workspace/workspace.service';

@Component({
  selector: 'pp-side-panel-favourites-row',
  templateUrl: './side-panel-favourites-row.component.html',
  styleUrl: './side-panel-favourites-row.component.scss',
})
export class SidePanelFavouritesRowComponent {
  @Output() ppUpdate: EventEmitter<void> = new EventEmitter<void>();

  isCollapsed: boolean;
  EIconPath = EIconPath;

  constructor(
    private preferencesService: PreferencesService,
    private workspaceService: WorkspaceService,
  ) {}

  ngOnInit(): void {
    this.isCollapsed = GET_FAVOURITES_COLLAPSED();
  }

  toggleCollapsed(): void {
    this.isCollapsed = !this.isCollapsed;
    SET_FAVOURITES_COLLAPSED(this.isCollapsed);
    this.ppUpdate.emit();
  }

  countFavorites(): number {
    let favouritesCount = 0;
    const favouriteWorkspaces = this.preferencesService.getPreferences().favouriteWorkspaceIds;

    favouriteWorkspaces.forEach((workspaceId) => {
      const workspace = this.workspaceService.getWorkspace(workspaceId);

      if (workspace) {
        favouritesCount += 1;
      }
    });

    return favouritesCount;
  }
}
