import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { TTranslationKey } from 'src/app/project/features/translate/types';
import { CustomFieldsService } from 'src/app/project/modules/custom-fields/custom-fields.service';
import { getCustomFieldIcon } from 'src/app/project/modules/site/site-options/utils/get-field-icon';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EFormulaPieceType, TFormulaPiece } from '../utils/formula-input.type';
import { FORMULA_MAX_NESTING_LEVEL } from '../utils/formula.consts';
import { EConditionalFormulaOperator } from '../utils/formula-operators.enum';

@Component({
  selector: 'pp-formula-piece',
  templateUrl: './formula-piece.component.html',
  styleUrl: './formula-piece.component.scss',
})
export class FormulaPieceComponent implements OnChanges {
  @Input() ppFormulaPiece: TFormulaPiece;
  @Input() ppWorkspaceId: string;
  @Input() ppCanDelete: boolean;
  @Input() ppDisabled: boolean;
  @Input() ppClickable: boolean;
  @Input() ppExampleFormulaPiece: string = '';
  @Output() ppDelete: EventEmitter<void> = new EventEmitter<void>();
  @Output() ppAction = new EventEmitter<TFormulaPiece>();

  EIconPath = EIconPath;
  pieceName: string;
  EFormulaPieceType = EFormulaPieceType;
  icon: EIconPath;
  disabledFormula: boolean = false;
  tooltipKey: TTranslationKey;

  constructor(private customFieldsService: CustomFieldsService) {}

  ngOnChanges(): void {
    this.setDisplayData();
    this.setTooltip();
  }

  deleteFormulaPiece(): void {
    this.ppDelete.emit();
  }

  triggerAction(): void {
    if (this.disabledFormula || this.ppDisabled) {
      return;
    }

    this.ppAction.emit(this.ppFormulaPiece);
  }

  private setDisplayData(): void {
    switch (this.ppFormulaPiece.type) {
      case EFormulaPieceType.OPERATOR:
        this.pieceName = this.ppFormulaPiece.value;
        this.icon = null;
        this.disabledFormula = false;
        break;
      case EFormulaPieceType.CUSTOM_FIELD:
        const customField = this.customFieldsService.getWorkspaceCustomField(
          this.ppWorkspaceId,
          this.ppFormulaPiece.value,
        );

        this.pieceName = customField.label;

        this.icon = getCustomFieldIcon(this.ppFormulaPiece.value);
        this.disabledFormula = customField.nestingLevel > FORMULA_MAX_NESTING_LEVEL;
        break;
    }
  }

  private setTooltip(): void {
    if (this.disabledFormula) {
      this.tooltipKey = 'formula_nesting_level_error';
    } else if (this.ppDisabled) {
      this.tooltipKey = 'max_formula_custom_fields_limit_reached';
    } else if (
      Object.values(EConditionalFormulaOperator).includes(
        this.ppFormulaPiece.value as EConditionalFormulaOperator,
      )
    ) {
      switch (this.ppFormulaPiece.value) {
        case EConditionalFormulaOperator.EQUALS:
          this.tooltipKey = 'equals_to';
          break;
        case EConditionalFormulaOperator.NOT_EQUALS:
          this.tooltipKey = 'not_equals_to';
          break;
        case EConditionalFormulaOperator.GREATER_THAN:
          this.tooltipKey = 'greater_than';
          break;
        case EConditionalFormulaOperator.LESS_THAN:
          this.tooltipKey = 'less_than';
          break;
        case EConditionalFormulaOperator.GREATER_THAN_OR_EQUAL_TO:
          this.tooltipKey = 'greater_than_or_equal';
          break;
        case EConditionalFormulaOperator.LESS_THAN_OR_EQUAL_TO:
          this.tooltipKey = 'less_than_or_equal';
          break;
      }
    } else {
      this.tooltipKey = null;
    }
  }
}
