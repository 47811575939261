<button
  #menuButton
  class="sidePanelMenuButton"
  (click)="action()"
  [class.sidePanelMenuButton--active]="ppActive"
  [class.sidePanelMenuButton--disabled]="ppDisabled"
>
  <div class="sidePanelMenuButton__image">
    <pp-icon
      class="sidePanelMenuButton__icon"
      [ppSrc]="ppIconSrc"
      ppClass="setSize24"
      ppColor="white"
    ></pp-icon>

    <!-- Red dot for reminders, there was no other way to preserve color -->
    <pp-icon
      class="sidePanelMenuButton__indicator"
      ppClass="setSize24"
      [ppSrc]="EIconPath.SIDE_PANEL_REMINDER_NOTIFICATION"
      *ngIf="ppShowIndicator"
    ></pp-icon>
  </div>

  <span class="sidePanelMenuButton__text">
    {{ ppText }}
  </span>
</button>
