<div class="formulaOperatorsContainer">
  <div>{{ 'operators' | ppTranslate }}:</div>
  <div class="formulaOperators">
    <pp-formula-piece
      class="formulaOperator"
      *ngFor="let operator of operators"
      [ppFormulaPiece]="operator"
      (click)="addOperator(operator)"
    ></pp-formula-piece>
  </div>
</div>
