import { Inject, Injectable } from '@angular/core';

import isString from 'lodash/isString';
import { Subject } from 'rxjs';

import { KEY_ESC, clickOutside, pressKey } from '@core/helpers';

import { TDropdown } from '../dropdown.consts';

import { ComponentType } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import { WindowService } from '@core/services';
import { clearDropdown } from './dropdown-service-utils';
import { TDropdownParams } from './dropdown-service.consts';
import { showDropdown } from './show-dropdown';

@Injectable({
  providedIn: 'root',
})
export class DropdownService {
  private readonly _dropdownDataChanged$ = new Subject<void>();
  readonly dropdownDataChanged$ = this._dropdownDataChanged$.asObservable();

  private readonly dropdown: TDropdown;
  private window = this.windowService.getGlobalObject();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private windowService: WindowService,
  ) {
    this.dropdown = {
      data: null,
      dropdownHost: null,
      component: null,
      visible: false,
      // popper positioning issue
      visibilityHidden: false,
      buttonId: null,
      buttonElement: null,
      dropdownElement: null,
      changeObserver: null,
      popper: {
        instance: null,
        placement: 'bottom-start',
        modifiers: [
          {
            name: 'computeStyles',
            options: {
              gpuAcceleration: false,
            },
          },
        ],
        positionFixed: false,
      },
      callback: null,
      onEsc: (event: KeyboardEvent): void =>
        pressKey(event, KEY_ESC, () => {
          this.hideDropdown();
        }),
      onOutsideClick: (event): void => {
        const element = event.target as HTMLElement;
        const activeDropdownClicked =
          element.closest('.dropdownItem') ||
          element.closest('.dropdown--secondary') ||
          element.classList.contains('dropdown');

        if (activeDropdownClicked) {
          return;
        }

        clickOutside(event, [this.dropdown.buttonElement, this.dropdown.dropdownElement], () => {
          this.hideDropdown();
        });
      },
      onScroll: (): void => {
        if (isString(this.dropdown.buttonId)) {
          this.dropdown.buttonElement = this.document.getElementById(this.dropdown.buttonId);
        } else {
          this.dropdown.buttonElement = this.dropdown.buttonId;
        }

        if (!this.dropdown.buttonElement) {
          this.hideDropdown();
        }
      },
      tagDropdownScrollCallback: (): void => {
        if (isString(this.dropdown.buttonId)) {
          this.dropdown.buttonElement = this.document.getElementById(this.dropdown.buttonId);
        } else {
          this.dropdown.buttonElement = this.dropdown.buttonId;
        }

        if (this.dropdown.buttonElement?.getBoundingClientRect()?.top < 260) {
          this.hideDropdown();
        }
      },
      onClose: null,

      contentElement: null,
      long: false,
    };
  }

  getDropdown(): TDropdown {
    return this.dropdown;
  }

  setData<T = any>(data: T): TDropdown {
    this.dropdown.data = <T>data;

    this._dropdownDataChanged$.next();

    return this.getDropdown();
  }

  clearDropdown(): void {
    clearDropdown(this.dropdown);
  }

  showDropdown(
    buttonId: string,
    component: ComponentType<unknown>,
    {
      secondary = false,
      callback = undefined,
      onClose = undefined,
      popper = undefined,
      modifiers = null,
      suppressScrollbar = false,
      width = null,
      long = false,
      addTagDropdownScrollCallback = false,
      addScrollCallback = false,
      isModalDropdown = false,
    }: TDropdownParams = {},
  ): void {
    return showDropdown(this.dropdown, buttonId, component, {
      callback,
      onClose,
      popper,
      modifiers,
      suppressScrollbar,
      width,
      long,
      addTagDropdownScrollCallback,
      addScrollCallback,
      secondary,
      isModalDropdown,
    });
  }

  hideDropdown(): void {
    this.window.removeEventListener('keydown', this.dropdown.onEsc, true);
    this.window.removeEventListener('click', this.dropdown.onOutsideClick, true);
    this.window.removeEventListener('scroll', this.dropdown.onScroll, true);
    this.window.removeEventListener('scroll', this.dropdown.tagDropdownScrollCallback, true);

    if (this.dropdown.onClose) {
      this.dropdown.onClose();
    }

    this.clearDropdown();
  }

  updateDropdown(): void {
    if (this.dropdown.popper.instance) {
      this.dropdown.popper.instance.forceUpdate();
      this.dropdown.popper.instance.update();
    }
  }

  setDropdownElement(element: HTMLElement): void {
    this.dropdown.dropdownElement = element;
  }
}
