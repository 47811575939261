import { Component, OnDestroy, OnInit } from '@angular/core';

import { cloneDeep } from 'lodash';
import { Observable, Subject, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { LimitExceededService } from 'src/app/project/components/change-limit-modal/limit-exceeded.service';
import { Modal, ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { AccountSharesService } from '../../../share/account-shares.service';
import { TShare } from '../../../share/share.model';
import { SelectedSharesService } from '../../../user-management/services/selected-shares.service';
import { UserManagementBulkChangesBarService } from '../../../user-management/user-management-bulk-changes-bar/user-management-bulk-changes-bar.service';
import { TAccountUser } from '../../../users/account.user.model';
import { AccountService } from '../../account-service/account.service';
import { TAccount } from '../../account.model';
import { PrepareShareService } from './account-user-modal-shares-list/account-user-modal-shares-list-share/prepare-share.service';
import { EditAccountUserModalDataService } from './edit-account-user-modal-data.service';
import {
  EEditAccountUserStep,
  TAccountEditedShare,
  TEditAccountUserModalParams,
} from './edit-account-user-modal.consts';
import { EditAccountUserModalService } from './edit-account-user-modal.service';
import { checkDifferentAccessLevelsPerSite } from './utils/check-different-access-levels-per-site';

@Component({
  selector: 'pp-edit-account-user-modal',
  templateUrl: './edit-account-user-modal.component.html',
  styleUrls: ['./edit-account-user-modal.component.scss'],
})
export class EditAccountUserModalComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  modal: Modal;
  processing: boolean;
  savingShare: boolean;
  accountId: string;
  account: TAccount;
  step: EEditAccountUserStep = EEditAccountUserStep.SHARES_LIST;
  EEditAccountUserStep = EEditAccountUserStep;
  workspaceIds: string[];
  customFieldsWorkspaceId: string = null;
  editedShares: TAccountEditedShare[] = [];
  sharesBackup: TShare[] = [];
  bulkEditingCustomFields = false;
  confirmText: string;
  differentAccessLevelsSelected = false;
  showingMultipleUsersWarning = false;
  selectedShares: TShare[];
  editedShare: TShare;

  constructor(
    private promptService: PromptService,
    private modalService: ModalService,
    private translationPipe: TranslationPipe,
    private accountService: AccountService,
    private editAccountUserModalService: EditAccountUserModalService,
    private accountSharesService: AccountSharesService,
    private editAccountUserModalDataService: EditAccountUserModalDataService,
    private selectedSharesService: SelectedSharesService,
    private prepareShareService: PrepareShareService,
    private userManagementBulkChangesBarService: UserManagementBulkChangesBarService,
    private limitExceededService: LimitExceededService,
  ) {}

  ngOnInit() {
    this.modal = this.modalService.getModal();
    this.setModalData();
    this.processing = true;
    this.editAccountUserModalDataService.clearData();

    this.account = this.accountService.getAccount(this.accountId);
    this.fetchAccountShares();
    this.setConfirmText();
    this.setEmailList();
  }

  ngOnDestroy(): void {
    this.editAccountUserModalDataService.clearData();
    this.destroy$.next();
  }

  hideModal(): void {
    this.modalService.hideModal();
  }

  saveShares(): void {
    this.savingShare = true;

    this.processRequest();
  }

  private processRequest(isFromChangeLimitsModal: boolean = false): Promise<boolean> {
    if (this.showingMultipleUsersWarning) {
      return this.processMultipleUsersRequest(isFromChangeLimitsModal);
    }

    return new Promise((resolve) => {
      const newShares = this.editAccountUserModalDataService.getSharesMap();

      this.editAccountUserModalService
        .editShares(newShares, this.editedShares)
        .pipe(
          tap(() => {
            this.handleDataSaved(isFromChangeLimitsModal);
            resolve(true);
          }),
          catchError((error) => this.handleDataSaveError(error)),
        )
        .subscribe();
    });
  }

  private processMultipleUsersRequest(isFromChangeLimitsModal: boolean): Promise<boolean> {
    return new Promise((resolve) => {
      const newShares = this.editAccountUserModalDataService.getIndividualShares();

      this.editAccountUserModalService
        .editIndividualShares(newShares, this.editedShares)
        .pipe(
          tap(() => {
            this.handleDataSaved(isFromChangeLimitsModal);
            resolve(true);
          }),
          catchError((error) => this.handleDataSaveError(error)),
        )
        .subscribe();
    });
  }

  private handleDataSaveError(error: any): Observable<null> {
    this.savingShare = false;

    if (error.status === EStatusCode.BAD_REQUEST) {
      const promptText = this.translationPipe.transform('prompt_share_error_already_shared');

      this.promptService.showWarning(promptText);
    } else if (error.status === EStatusCode.UPGRADE_REQUIRED) {
      this.limitExceededService.showLimitsExceededModal();
    } else {
      const promptText = this.translationPipe.transform('prompt_share_error');

      this.promptService.showError(promptText);
    }

    return of(null);
  }

  private handleDataSaved(isFromChangeLimitsModal: boolean): void {
    const prompt = this.translationPipe.transform('prompt_user_shares_edited');
    this.promptService.showSuccess(prompt);

    // checking if the method has been called from the other component
    if (!isFromChangeLimitsModal) {
      this.modalService.hideModal(false);
    }
  }

  private fetchAccountShares(): void {
    this.processing = true;

    this.accountSharesService
      .fetchAccountShares(this.account.accountId)
      .pipe(
        tap((accountUsers) => {
          this.generateDataFromSelectedUsers(accountUsers);
          this.processing = false;
        }),
      )
      .subscribe();
  }

  private generateDataFromSelectedUsers(accountUsers: TAccountUser[]): void {
    const selectedSharesIds = this.selectedSharesService.getSelectedSharesIds();
    const selectedShares: TShare[] = this.generateSelectedShares(selectedSharesIds, accountUsers);
    const shareList: TAccountEditedShare[] = this.generateShareList(
      selectedSharesIds,
      accountUsers,
    );

    this.editedShares = shareList;
    this.selectedShares = selectedShares;
    this.editAccountUserModalDataService.setIndividualShares(selectedShares);
    this.workspaceIds = this.editedShares.map((share) => share.workspaceId);
    this.editAccountUserModalDataService.updateWorkspacesData(this.workspaceIds);

    const selectedUsers = this.userManagementBulkChangesBarService.getFilteredUsers();

    if (selectedUsers.length === 1) {
      this.setSingleUserShares(accountUsers, selectedUsers, selectedSharesIds);
    } else {
      this.showingMultipleUsersWarning = true;
    }

    this.setDifferentAccessLevelsSelected(selectedShares);
  }

  private generateShareData(
    share: TShare,
    shareId: string,
    shareList: TAccountEditedShare[],
    userEmail: string,
  ): TAccountEditedShare[] {
    if (share.shareId === shareId) {
      const existingShare = this.findExistingShare(shareList, share);

      if (existingShare) {
        existingShare.shares.push({
          shareId: shareId,
          email: userEmail,
        });
      } else {
        shareList.push({
          workspaceId: share.workspaceId,
          shares: [
            {
              shareId: shareId,
              email: userEmail,
            },
          ],
        });
      }
    }

    return shareList;
  }

  private findExistingShare(shareList: TAccountEditedShare[], share: TShare): TAccountEditedShare {
    return shareList.find((shareListItem) => shareListItem.workspaceId === share.workspaceId);
  }

  private setModalData(): void {
    const modalData: TEditAccountUserModalParams = this.modal.data;

    this.accountId = modalData.accountId;
  }

  nextStep(): void {
    switch (this.step) {
      case EEditAccountUserStep.SHARES_LIST:
        this.saveShares();
        break;
      case EEditAccountUserStep.CUSTOM_FIELDS_EDIT:
        this.step = EEditAccountUserStep.SHARES_LIST;
        this.customFieldsWorkspaceId = null;
        break;
    }

    this.setConfirmText();
  }

  previousStep(): void {
    switch (this.step) {
      case EEditAccountUserStep.SHARES_LIST:
        this.hideModal();
        break;
      case EEditAccountUserStep.CUSTOM_FIELDS_EDIT:
        this.step = EEditAccountUserStep.SHARES_LIST;

        this.selectedShares = cloneDeep(this.sharesBackup);

        this.selectedShares.forEach((share) => {
          this.editAccountUserModalDataService.setShare(share.workspaceId, share);
        });
        this.editAccountUserModalDataService.emitSharesChange();

        this.customFieldsWorkspaceId = null;
        break;
    }

    this.setConfirmText();
  }

  showCustomFieldsTable(workspaceId: string): void {
    this.bulkEditingCustomFields = false;
    this.step = EEditAccountUserStep.CUSTOM_FIELDS_EDIT;
    this.customFieldsWorkspaceId = workspaceId;
    this.sharesBackup = cloneDeep(this.selectedShares);

    this.setConfirmText();
  }

  showIndividualCustomFieldsTable(share: TShare): void {
    this.bulkEditingCustomFields = false;
    this.step = EEditAccountUserStep.CUSTOM_FIELDS_EDIT;
    this.customFieldsWorkspaceId = share.workspaceId;
    this.sharesBackup = cloneDeep(this.selectedShares);

    this.editedShare = share;

    this.setConfirmText();
  }

  showBulkCustomFieldsTable(): void {
    this.bulkEditingCustomFields = true;
    this.step = EEditAccountUserStep.CUSTOM_FIELDS_EDIT;
    this.customFieldsWorkspaceId = this.selectedShares[0].workspaceId;
    this.sharesBackup = cloneDeep(this.selectedShares);
    this.setConfirmText();
  }

  private setConfirmText(): void {
    this.confirmText = this.translationPipe.transform(
      this.step === EEditAccountUserStep.SHARES_LIST ? 'edit_users' : 'save_site_permissions',
    );
  }

  private setEmailList(): void {
    const selectedUsers = this.userManagementBulkChangesBarService.getFilteredUsers();
    const emailList = selectedUsers.map((user) => user.email);

    this.editAccountUserModalDataService.setEmailList(emailList);
  }

  private setDifferentAccessLevelsSelected(shares: TShare[]): void {
    this.differentAccessLevelsSelected = checkDifferentAccessLevelsPerSite(shares);
  }

  private generateShareList(
    selectedSharesIds: string[],
    accountUsers: TAccountUser[],
  ): TAccountEditedShare[] {
    let shareList: TAccountEditedShare[] = [];

    selectedSharesIds.forEach((shareId) => {
      for (const accountUser of accountUsers) {
        for (const share of accountUser.shares) {
          shareList = this.generateShareData(share, shareId, shareList, accountUser.email);
        }
      }
    });

    return shareList;
  }

  private generateSelectedShares(
    selectedSharesIds: string[],
    accountUsers: TAccountUser[],
  ): TShare[] {
    const selectedShares: TShare[] = [];

    selectedSharesIds.forEach((shareId) => {
      for (const accountUser of accountUsers) {
        for (const share of accountUser.shares) {
          if (share.shareId === shareId) {
            selectedShares.push(share);
          }
        }
      }
    });

    return selectedShares;
  }

  private setSingleUserShares(
    accountUsers: TAccountUser[],
    selectedUsers: TAccountUser[],
    selectedSharesIds: string[],
  ): void {
    const accountUser = accountUsers.find((user) => user.email === selectedUsers[0].email);
    const currentShares = accountUser.shares.filter((share) =>
      selectedSharesIds.includes(share.shareId),
    );

    currentShares.forEach((share) => {
      this.editAccountUserModalDataService.setShare(share.workspaceId, share);
    });
  }
}
