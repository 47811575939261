import { translate } from 'src/app/project/features/translate/translate';
import { TAccount } from 'src/app/project/modules/account/account.model';
import { TWorkspace } from 'src/app/project/modules/workspace/workspace.model';
import {
  TAccountSidePanel,
  TAccountSimpleResponse,
  TWorkspaceSimpleResponse,
} from 'src/app/project/view-models/account-simple-response.model';
import { NEW_FOLDER_ID } from '../utils/account-list-constants';
import { GET_COLLAPSED_ACCOUNTS, GET_FAVOURITES_COLLAPSED } from '../utils/account-list.ui.store';
import { ESidePanelItem } from '../utils/side-panel-item.enum';
import { checkAccountAdminPermissions } from './account-list-check-admin-permission';

export type TFlattenedAccount = {
  account?: TAccount;
  type: string;
  draggable?: boolean;
  folderId?: string;
  workspace?: TWorkspace;
  accountId?: string;
  id?: string;
  name?: string;
  workspaces?: TWorkspaceSimpleResponse[];
  workspaceId?: string;
  accountType?: string;
};

export function flattenAccounts(
  accounts: TAccountSimpleResponse[],
  favouriteWorkspaces: string[],
): TFlattenedAccount[] {
  const favourites: TFlattenedAccount[] = [
    {
      type: ESidePanelItem.FAVOURITES,
      id: 'favouritesTab',
    },
  ];

  const favouritesCollapsed = GET_FAVOURITES_COLLAPSED();

  if (!favouritesCollapsed) {
    const favouriteWorkspacesList: TFlattenedAccount[] = [];

    favouriteWorkspaces.forEach((workspaceId) => {
      const account = accounts.find((account) => {
        return account.workspaces.some((workspace) => workspace.workspaceId === workspaceId);
      });

      if (!account) {
        return;
      }

      favouriteWorkspacesList.push({
        type: ESidePanelItem.WORKSPACE,
        workspace: account.workspaces.find(
          (workspace) => workspace.workspaceId === workspaceId,
        ) as any as TWorkspace,
        draggable: false,
        id: workspaceId,
        accountId: account.id,
        folderId: ESidePanelItem.FAVOURITES,
      });
    });

    if (favouriteWorkspacesList.length > 0) {
      favourites.push(...favouriteWorkspacesList);
    } else {
      favourites.push({
        type: ESidePanelItem.FAVOURITES_EMPTY,
        id: ESidePanelItem.FAVOURITES + 'empty',
      });
    }
  }

  const accountsFlattened = accounts.flatMap<any, any>((simpleAccount) => {
    const _account = simpleAccount as TAccountSidePanel;

    const accountAdminPermission = checkAccountAdminPermissions(_account);

    const account = {
      type: ESidePanelItem.ACCOUNT,
      account: _account,
      id: _account.id,
      accountId: _account.id,
    };

    const endLine = {
      type: ESidePanelItem.END_LINE,
      id: _account.id + 'endLine',
      accountI: _account.id,
    };

    if (GET_COLLAPSED_ACCOUNTS().has(_account.id)) {
      if (Array.isArray(_account.accountFolders)) {
        let newFolder = false;

        _account.accountFolders.forEach((_folder) => {
          if (_folder.id === NEW_FOLDER_ID) {
            newFolder = true;
          }
        });

        if (newFolder) {
          const folder = {
            type: ESidePanelItem.FOLDER,
            name: '',
            id: NEW_FOLDER_ID,
            workspaces: _account.workspaces,
            accountId: _account.id,
          };

          return [account, folder, endLine];
        }
      }

      return [account, endLine];
    }

    const newSiteButton: TFlattenedAccount = {
      type: ESidePanelItem.NEW_SITE_BUTTON,
      accountId: _account.id,
      name: translate('new_site'),
      id: _account.id + 'newSite',
    };

    if (accountAdminPermission) {
      return [account, newSiteButton, ..._account.elements, endLine];
    } else {
      return [account, ..._account.elements, endLine];
    }
  }) as TFlattenedAccount[];

  return [...favourites, ...accountsFlattened];
}
