import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SiteNewService {
  private newSiteFolderId: string;

  setNewSiteFolderId(id: string): void {
    this.newSiteFolderId = id;
  }

  getNewSiteFolderId(): string {
    return this.newSiteFolderId;
  }
}
