import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { LimitExceededService } from 'src/app/project/components/change-limit-modal/limit-exceeded.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { logErrorInSentry } from 'src/app/project/modules/errors/response-error';
import {
  setChangedWorkspace,
  setChangedWorkspaces,
} from 'src/app/project/modules/workspace/workspace';
import { TWorkspace } from 'src/app/project/modules/workspace/workspace.model';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSettings,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { ModalService } from '../../../components/modal/modal.service';
import { SiteSettingsUsersService } from '../../site/site-settings/site-settings-users/site-settings-users.service';
import { WorkspaceService } from '../../workspace/workspace.service';
import { ShareBulkService } from '../share-bulk.service';
import { TCreateShareDTO, TNewUser } from '../share.model';
import { SharesService } from '../shares.service';

@Injectable({
  providedIn: 'root',
})
export class ShareModalService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private readonly shouldCloseModal = new Subject<boolean>();

  constructor(
    private sharesService: SharesService,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private modalService: ModalService,
    private siteSettingsUsersService: SiteSettingsUsersService,
    private shareBulkService: ShareBulkService,
    private workspaceService: WorkspaceService,
    private limitExceededService: LimitExceededService,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  saveShareFromMultipleEmails(
    share: TCreateShareDTO,
    emails: string[],
    workspaceId: string,
  ): Observable<TNewUser[]> {
    const sharesToSend: TCreateShareDTO[] = [];
    const workspace = this.workspaceService.getWorkspace(workspaceId);

    emails.forEach((email) => {
      const newShare = { ...share };
      newShare.userEmail = email;
      sharesToSend.push(newShare);
    });

    return this.createMultipleShares(sharesToSend, workspace, workspaceId);
  }

  private createMultipleShares(
    sharesToSend: TCreateShareDTO[],
    workspace: TWorkspace,
    workspaceId: string,
  ): Observable<TNewUser[]> {
    return this.shareBulkService.bulkCreateShare(sharesToSend).pipe(
      tap((response) => {
        this.handleBulkSharesCreateSuccess(response, workspace, workspaceId);
      }),
      catchError((error) => this.handleBulkSharesCreateError(error)),
    );
  }

  private handleBulkSharesCreateError(error: { status: number }): Observable<never> {
    logErrorInSentry(error);
    this.shouldCloseModal.next(false);

    switch (error.status) {
      case EStatusCode.BAD_REQUEST:
        {
          const promptText = this.translationPipe.transform('prompt_share_error_already_shared');

          this.promptService.showWarning(promptText);
        }

        break;
      case EStatusCode.UPGRADE_REQUIRED:
        this.limitExceededService.showLimitsExceededModal();

        break;
      default:
        {
          const promptText = this.translationPipe.transform('prompt_share_error');

          this.promptService.showError(promptText);
        }
        break;
    }

    return throwError(error);
  }

  private handleBulkSharesCreateSuccess(
    response: TNewUser[],
    workspace: TWorkspace,
    workspaceId: string,
  ): void {
    logEventInGTAG(EGoogleEventSettings.SETTINGS__USER__NEW, {
      event_category: EGoogleEventCategory.SETTINGS,
    });

    this.addWorkspaceShares(response);
    this.siteSettingsUsersService.newShareCloseTriggerEvent();
    this.setCreatedUsers(response, workspace, workspaceId);
    this.modalService.hideModal();
    this.showBulkSharesCreatePrompt();
    setChangedWorkspaces(true);
    setChangedWorkspace(workspaceId);
  }

  private setCreatedUsers(response: TNewUser[], workspace: TWorkspace, workspaceId: string): void {
    response.forEach((user) => {
      this.sharesService.setCreatedUser(workspace, user, workspaceId);
    });
  }

  private addWorkspaceShares(response: TNewUser[]): void {
    const shares = response.map((shareResponse) => shareResponse.share);

    this.sharesService.addWorkspaceShares(shares);
  }

  private showBulkSharesCreatePrompt(): void {
    const promptText = this.translationPipe.transform('prompt_site_shared');
    this.promptService.showSuccess(promptText);
  }
}
