import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TAccountSimpleResponse } from '@project/view-models';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TFlattenedAccount } from '../../../account-list/account-list/account-list-flatten-account';
import {
  ADD_COLLAPSED_ACCOUNT,
  DELETE_COLLAPSED_ACCOUNT,
  GET_COLLAPSED_ACCOUNTS,
} from '../../../account-list/utils/account-list.ui.store';
import { AccountSettingsDropdownComponent } from '../../../account/account-settings-dropdown/account-settings-dropdown.component';
import { EUserRole } from '../../../share/share-utils/user-roles';
import { UserService } from '../../../user/user.service';

@Component({
  selector: 'pp-side-panel-account-row',
  templateUrl: './side-panel-account-row.component.html',
  styleUrl: './side-panel-account-row.component.scss',
})
export class SidePanelAccountRowComponent {
  @Input() ppAccount: TFlattenedAccount;
  @Output() ppUpdate = new EventEmitter<void>();

  cogVisible: boolean;
  account: TAccountSimpleResponse;
  EIconPath = EIconPath;
  cogButtonId: string;
  collapsed: boolean;
  private dropdown: TDropdown = this.dropdownService.getDropdown();

  constructor(
    private userService: UserService,
    private dropdownService: DropdownService,
  ) {}

  ngOnChanges(): void {
    this.account = this.ppAccount.account as any as TAccountSimpleResponse;
    this.setCogVisibility();
    this.collapsed = GET_COLLAPSED_ACCOUNTS().has(this.account.id);

    this.cogButtonId = this.account.id + 'sidePanelSettingsCog';
  }

  setCogVisibility(): void {
    const user = this.userService.getUser();

    let adminUser = false;
    let accountAdminUser = false;

    for (
      let workspaceIndex = 0;
      workspaceIndex < this.account.workspaces.length;
      workspaceIndex++
    ) {
      if (this.account.workspaces[workspaceIndex].shareOption === EUserRole.ACCOUNT_ADMIN) {
        accountAdminUser = true;

        break;
      }

      if (this.account.workspaces[workspaceIndex].shareOption === EUserRole.SITE_ADMIN) {
        adminUser = true;

        break;
      }
    }

    this.cogVisible =
      (user.accountId && user.accountId === this.account.id) ||
      user.isSuperUser ||
      adminUser ||
      accountAdminUser;
  }

  toggleAccount(): void {
    if (this.collapsed) {
      DELETE_COLLAPSED_ACCOUNT(this.account.id);
    } else {
      ADD_COLLAPSED_ACCOUNT(this.account.id);
    }

    this.ppUpdate.emit();
  }

  toggleAccountOptionsDropdown(): void {
    if (this.dropdown.visible && this.dropdown.buttonId === this.cogButtonId) {
      this.dropdownService.hideDropdown();
    } else {
      this.dropdownService.setData({
        accountId: this.account.id,
      });

      this.dropdownService.showDropdown(this.cogButtonId, AccountSettingsDropdownComponent, {
        addScrollCallback: true,
      });
    }
  }
}
