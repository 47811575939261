const collapsedAccounts: Set<string> = new Set();
const collapsedFolders: {
  [accountId: string]: string[];
} = {};
const visibleEmptyFolders: {
  [accountId: string]: string[];
} = {};
let favouritesCollapsed: boolean = false;
// GETTERS

export const GET_FAVOURITES_COLLAPSED = (): boolean => favouritesCollapsed;

export const GET_COLLAPSED_ACCOUNTS = (): Set<string> => collapsedAccounts;

export const GET_COLLAPSED_FOLDERS = (): {
  [accountId: string]: string[];
} => collapsedFolders;

export const GET_VISIBLE_EMPTY_FOLDERS = (): {
  [accountId: string]: string[];
} => visibleEmptyFolders;

// SETTERS

export const ADD_VISIBLE_EMPTY_FOLDER = (_accountId: string, _folderName: string): void => {
  if (!visibleEmptyFolders[_accountId]) {
    visibleEmptyFolders[_accountId] = [_folderName];
  } else if (!visibleEmptyFolders[_accountId].includes(_folderName)) {
    visibleEmptyFolders[_accountId].push(_folderName);
  }
};

export const SET_FAVOURITES_COLLAPSED = (_collapsed: boolean): void => {
  favouritesCollapsed = _collapsed;
};

export const ADD_COLLAPSED_ACCOUNT = (_accountId: string): void => {
  collapsedAccounts.add(_accountId);
};

export const ADD_COLLAPSED_FOLDER = (_accountId: string, _folderId: string): void => {
  if (!collapsedFolders[_accountId]) {
    collapsedFolders[_accountId] = [_folderId];
  } else {
    collapsedFolders[_accountId].push(_folderId);
  }
};

export const DELETE_COLLAPSED_ACCOUNT = (_accountId: string): void => {
  collapsedAccounts.delete(_accountId);
};

export const DELETE_COLLAPSED_FOLDER = (_accountId: string, _folderId: string): void => {
  if (!collapsedFolders[_accountId]) {
    return;
  }

  const index = collapsedFolders[_accountId].indexOf(_folderId);

  if (index === -1) {
    return;
  }

  collapsedFolders[_accountId].splice(index, 1);
};

export const CLEAR_COLLAPSED_FOLDERS = (): void => {
  Object.keys(collapsedFolders).forEach((accountId: string) => {
    collapsedFolders[accountId] = [];
  });
};

export const CLEAR_COLLAPSED_ACCOUNTS = (): void => {
  collapsedAccounts.clear();
};

export const CLEAR_FAVOURITES_COLLAPSED = (): void => {
  favouritesCollapsed = false;
};
