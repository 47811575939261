import { ApiService } from '@core/http';
import { getSvg } from 'src/app/core/helpers/get-svg';
import {
  deconstructConditionalFormula,
  deconstructFormula,
} from 'src/app/project/components/formula-builder/utils/deconstruct-formula';
import {
  EFormulaPieceType,
  TConditionalFormula,
  TFormulaPiece,
} from 'src/app/project/components/formula-builder/utils/formula-input.type';
import { translate } from 'src/app/project/features/translate/translate';
import { getCustomFieldIcon } from '../../site/site-options/utils/get-field-icon';
import { TWorkspaceCustomFields } from '../custom-fields.model';

export function setFormulaTooltipTitle(
  customFieldId: string,
  customFields: TWorkspaceCustomFields,
  apiService: ApiService,
): Promise<string> {
  if (customFields[customFieldId].formula.startsWith('if')) {
    return createConditionalFormulaTooltip(
      customFields,
      apiService,
      deconstructConditionalFormula(customFields[customFieldId].formula),
    );
  } else {
    return createFormulaTooltip(
      customFields,
      apiService,
      deconstructFormula(customFields[customFieldId].formula),
    );
  }
}

function createFormulaTooltip(
  customFields: TWorkspaceCustomFields,
  apiService: ApiService,
  formulaPieces: TFormulaPiece[],
  isConditional: boolean = false,
): Promise<string> {
  return new Promise((resolve) => {
    const formulaHidden = formulaPieces.some(
      (piece) => piece.type === EFormulaPieceType.CUSTOM_FIELD && !customFields?.[piece.value],
    );

    if (formulaHidden) {
      resolve(translate('formula_result'));
      return;
    }

    const operatorsList = formulaPieces.map((piece) =>
      getPieceHTML(piece, customFields, apiService),
    );

    Promise.all(operatorsList).then((operatorsListResponse) => {
      const tooltip = `<div class="tooltipFormula">
    ${!isConditional ? '<div class="tooltipFormula__letterF">f</div>' : ''}
    ${operatorsListResponse.join('')}
  </div>`;

      resolve(tooltip);
    });
  });
}

function createConditionalFormulaTooltip(
  customFields: TWorkspaceCustomFields,
  apiService: ApiService,
  formulaPieces: TConditionalFormula,
): Promise<string> {
  const { condition, truthy, falsey } = formulaPieces; // Extract formulas

  return Promise.all([
    createFormulaTooltip(customFields, apiService, condition, true),
    createFormulaTooltip(customFields, apiService, truthy, true),
    createFormulaTooltip(customFields, apiService, falsey, true),
  ]).then(([tooltip1, tooltip2, tooltip3]) => {
    return `<div class="formulaTooltip-container">
        <span>${translate('conditional_formula_condition_label')}</span>
        ${tooltip1}
        <span>${translate('conditional_formula_truthy_label')}</span>
        ${tooltip2}
        <span>${translate('conditional_formula_falsy_label')}</span>
        ${tooltip3}
      </div>`;
  });
}

function getPieceHTML(
  piece: TFormulaPiece,
  customFields: TWorkspaceCustomFields,
  apiService: ApiService,
): Promise<string> {
  switch (piece.type) {
    case EFormulaPieceType.OPERATOR:
      return Promise.resolve(`<div class="tooltipFormula__operator">${piece.value}</div>`);
    case EFormulaPieceType.CUSTOM_FIELD: {
      return new Promise((resolve) => {
        const customField = customFields?.[piece.value];
        const fieldIcon = getCustomFieldIcon(piece.value);

        getSvg(apiService, fieldIcon).then((svg) => {
          resolve(`<div class="tooltipFormula__customField">
            ${svg}
            <span>${customField.label}</span>
          </div>`);
        });
      });
    }
    default:
      return Promise.resolve('');
  }
}
