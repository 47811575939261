<pp-side-panel-menu-button
  [ppText]="'notifications' | ppTranslate"
  [ppDataTarget]="'Notifications button'"
  [ppDataTest]="'notificationsMenuItem'"
  [ppIconSrc]="EIconPath.NOTIFICATION_BELL"
  [ppShowIndicator]="ppUser.unreadNotifications && !sidePanel.notificationsExpanded"
  [ppActive]="sidePanel.notificationsExpanded"
  [ppDisabled]="!hasWorkspaces || !notificationsAvailable.fetched || ppDisabled"
  (ppAction)="toggleNotifications()"
></pp-side-panel-menu-button>
