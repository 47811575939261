<div class="sidePanelBackButton__wrapper">
  <button
    #menuButton
    class="sidePanelBackButton"
    (click)="switchSite()"
    data-m-target="'Back to site button'"
    dataTest="backMenuButton"
  >
    <div class="sidePanelBackButton__image">
      <pp-icon
        class="sidePanelBackButton__icon"
        [ppSrc]="EIconPath.SIDE_PANEL_BACK_ARROW"
        ppClass="setSize24"
        ppColor="white"
      ></pp-icon>
    </div>

    <span class="sidePanelBackButton__text">
      {{ 'back' | ppTranslate }}
    </span>
  </button>
</div>
