import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { TUser } from 'src/app/project/modules/user/user.model';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { TUISettings } from '../../ui/ui.model';

import { DashboardSettingsDropdownComponent } from 'src/app/project/modules/dashboard/dashboard-settings-dropdown/dashboard-settings-dropdown.component';
import { SiteSettingsDropdownComponent } from '../../site/site-settings-dropdown/site-settings-dropdown.component';

import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';

import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import { TSiteSettingsDropdownData } from 'src/app/project/modules/site/site-settings-dropdown/site-settings-dropdown.model';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { EIconPath } from '../../../shared/enums/icons.enum';
import { EFleetManagementRoutesSegments } from '../../fleet-management/fleet-management.routes';
import { TopBarService } from './top-bar.service';

@Component({
  selector: 'pp-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() ppFromSite: boolean;
  @Input() ppFleetLoaded: boolean;
  @ViewChild('topBar') topBarElement: ElementRef;

  private readonly destroy$ = new Subject<void>();
  private dropdown: TDropdown = this.dropdownService.getDropdown();

  EUserRole = EUserRole;
  user$: Observable<TUser>;
  user: TUser;
  workspaces$: Observable<TWorkspacesById>;
  workspaces: TWorkspacesById;
  ui$: Observable<TUISettings>;
  ui: TUISettings;
  routerPath = this.router.url;
  EIconPath = EIconPath;

  paramId: string;
  cogActive = false;

  showMobileSiteName = false;
  showAccountName = false;
  showSiteName = false;
  showFleetOptions = false;

  constructor(
    private store: Store<{
      user: TUser;
      workspaces: TWorkspacesById;
      ui: TUISettings;
    }>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dropdownService: DropdownService,
    private topBarService: TopBarService,
  ) {
    this.user$ = this.store.pipe(select(EStore.USER));
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
    this.ui$ = this.store.pipe(select(EStore.UI));

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.routerPath = event.url;

        this.checkRouterParams();
      }
    });

    this.checkRouterParams();
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.paramId = params.get('id');
    });

    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
    });

    this.ui$.pipe(takeUntil(this.destroy$)).subscribe((ui) => {
      this.ui = ui;
    });

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      this.workspaces = workspaces;
    });
  }

  ngAfterViewInit(): void {
    this.topBarService.setTopBarElement(this.topBarElement.nativeElement);
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  toggleSiteSettingsDropdown(buttonId: string, workspaceId: string): void {
    if (this.dropdown.visible && this.dropdown.buttonId === buttonId) {
      this.dropdownService.hideDropdown();
    } else {
      this.dropdownService.setData<TSiteSettingsDropdownData>({
        workspaceId,
      });

      this.dropdownService.showDropdown(buttonId, SiteSettingsDropdownComponent);
    }
  }

  toggleDashboardSettingsDropdown(buttonId: string): void {
    if (this.dropdown.visible && this.dropdown.buttonId === buttonId) {
      this.dropdownService.hideDropdown();
    } else {
      this.dropdownService.showDropdown(buttonId, DashboardSettingsDropdownComponent);
    }
  }

  settingsClicked(): void {
    this.cogActive = true;
  }

  settingsUnclicked(): void {
    this.cogActive = false;
  }

  checkRouterParams(): void {
    this.showMobileSiteName =
      this.routerPath === '/settings/user' ||
      this.routerPath === '/dashboard' ||
      this.routerPath.startsWith('/reminders') ||
      this.routerPath.startsWith('/settings/site') ||
      this.routerPath === 'settings.user' ||
      this.routerPath.startsWith('/site/overview') ||
      this.routerPath.startsWith('/settings/account') ||
      this.routerPath.startsWith('/site/timeline') ||
      this.routerPath.startsWith('/fleet-management');

    this.showAccountName =
      this.routerPath !== '/dashboard' &&
      !this.routerPath.startsWith('/reminders') &&
      this.routerPath !== '/settings/user' &&
      !this.routerPath.startsWith('/settings/account') &&
      !this.routerPath.startsWith('/settings/site') &&
      !this.routerPath.startsWith('/site/overview') &&
      !this.routerPath.startsWith('/site/timeline') &&
      !this.routerPath.startsWith('/fleet-management');

    this.showSiteName =
      this.routerPath !== '/dashboard' &&
      this.routerPath !== '/reminders' &&
      !this.routerPath.startsWith('/site/overview') &&
      !this.routerPath.startsWith('/site/timeline') &&
      this.routerPath !== '/settings/user' &&
      !this.routerPath.startsWith('/settings/account') &&
      !this.routerPath.startsWith('/settings/site') &&
      !this.routerPath.startsWith('/fleet-management');

    this.showFleetOptions =
      this.routerPath.startsWith(`/${EFleetManagementRoutesSegments.FLEET_MANAGEMENT}`) &&
      !this.routerPath.includes(EFleetManagementRoutesSegments.ASSET);
  }
}
