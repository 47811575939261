<div class="sitesList__list">
  <cdk-virtual-scroll-viewport
    sidePanelAccountsScrollerStrategyDirective
    [itemHeights]="itemHeights"
    class="cdkScroller"
    minBufferPx="1200"
    maxBufferPx="1200"
  >
    <div
      cdkDropList
      [cdkDropListData]="flattenedAccounts"
      cdkDropListOrientation="mixed"
      (cdkDropListDropped)="drop($event)"
      #sitesList
    >
      <ng-container
        *cdkVirtualFor="let item of flattenedAccounts; let index = index; trackBy: trackByFunction"
      >
        <ng-container [ngSwitch]="item.type">
          <pp-side-panel-account-row
            cdkDrag
            [cdkDragDisabled]="true"
            [ppAccount]="item"
            (ppUpdate)="updateList()"
            [attr.index]="index"
            [attr.folderId]="item.folderId"
            [attr.workspaceId]="item.workspaceId"
            [attr.accountId]="item.accountId"
            *ngSwitchCase="ESidePanelItem.ACCOUNT"
          ></pp-side-panel-account-row>
          <pp-side-panel-end-line-row
            cdkDrag
            [cdkDragDisabled]="true"
            *ngSwitchCase="ESidePanelItem.END_LINE"
            [attr.index]="index"
            [attr.folderId]="item.folderId"
            [attr.workspaceId]="item.workspaceId"
            [attr.accountId]="item.accountId"
          ></pp-side-panel-end-line-row>
          <pp-side-panel-folder-row
            cdkDrag
            [cdkDragDisabled]="true"
            *ngSwitchCase="ESidePanelItem.FOLDER"
            [attr.index]="index"
            [attr.folderId]="item.folderId"
            [attr.workspaceId]="item.workspaceId"
            [attr.accountId]="item.accountId"
            [ppFolder]="item"
            (ppUpdate)="updateList()"
          ></pp-side-panel-folder-row>
          <pp-side-panel-empty-folder-message
            cdkDrag
            [cdkDragDisabled]="true"
            *ngSwitchCase="ESidePanelItem.FOLDER_EMPTY"
            [attr.index]="index"
            [attr.folderId]="item.folderId"
            [attr.workspaceId]="item.workspaceId"
            [attr.accountId]="item.accountId"
          ></pp-side-panel-empty-folder-message>
          <pp-side-panel-workspace-row
            cdkDrag
            [ppClickable]="!dragged"
            [cdkDragDisabled]="!item.draggable"
            [cdkDragData]="item"
            *ngSwitchCase="ESidePanelItem.WORKSPACE"
            [attr.index]="index"
            [attr.folderId]="item.folderId"
            [attr.workspaceId]="item.workspaceId"
            [attr.accountId]="item.accountId"
            [ppWorkspace]="item"
            (ppUpdate)="updateList()"
          ></pp-side-panel-workspace-row>
          <pp-side-panel-new-site-button
            cdkDrag
            [cdkDragDisabled]="true"
            *ngSwitchCase="ESidePanelItem.NEW_SITE_BUTTON"
            [attr.index]="index"
            [attr.folderId]="item.folderId"
            [attr.workspaceId]="item.workspaceId"
            [attr.accountId]="item.accountId"
            [ppItem]="item"
          ></pp-side-panel-new-site-button>
          <pp-side-panel-favourites-row
            cdkDrag
            [cdkDragDisabled]="true"
            *ngSwitchCase="ESidePanelItem.FAVOURITES"
            [attr.index]="index"
            [attr.folderId]="item.folderId"
            [attr.workspaceId]="item.workspaceId"
            [attr.accountId]="item.accountId"
            (ppUpdate)="updateList()"
          ></pp-side-panel-favourites-row>
          <pp-side-panel-favourites-empty-row
            cdkDrag
            [cdkDragDisabled]="true"
            *ngSwitchCase="ESidePanelItem.FAVOURITES_EMPTY"
            [attr.index]="index"
            [attr.folderId]="item.folderId"
            [attr.workspaceId]="item.workspaceId"
            [attr.accountId]="item.accountId"
          ></pp-side-panel-favourites-empty-row>
        </ng-container>
      </ng-container>
    </div>
  </cdk-virtual-scroll-viewport>
</div>
