import { cloneDeep } from 'lodash';

import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';

import { TPoint, TPointsByWorkspace } from 'src/app/project/modules/points/points.model';
import Timeline from '../site-timeline/Timeline';

import { CLEAR_COLLAPSED_GROUPS } from '../site-table/table.ui.store';
import { CALCULATE_COLUMNS } from '../site-timeline/timeframes/timeline-columns';
import { GET_TIMELINE } from '../site-timeline/timeline.ui.store';

import { TPointUpdate } from '@project/view-models';
import { SitePointFilterService } from 'src/app/project/modules/filters/site-point-filter.service';
import { PointsUpdateService } from 'src/app/project/modules/points/points-update.service';
import { UserService } from 'src/app/project/modules/user/user.service';
import { UsersService } from 'src/app/project/modules/users/users.service';
import { ERowType } from 'src/app/project/shared/enums/row-type.enum';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { getTimeAsUtcMidday } from '../../../../core/helpers/date/date';
import { PointAttachmentsService } from '../../points/point-modal/point-attachments/point-attachments.service';
import { PointFieldsService } from '../../points/point-modal/point-fields/point-fields.service';
import { WorkspaceService } from '../../workspace/workspace.service';
import { SiteOptionsService } from '../site-options/site-options.service';
import { SiteTableEventsService } from '../site-table/site-table-events.service';

@Component({
  selector: 'pp-site-table-timeline',
  templateUrl: './site-table-timeline.component.html',
  styleUrls: ['./site-table-timeline.component.scss'],
})
export class SiteTableTimelineComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('tableWrapper', { static: false }) tableWrapperElement: ElementRef;
  @ViewChild('table', { static: true }) tableElement: ElementRef;

  private readonly destroy$ = new Subject<void>();

  private points$: Observable<TPointsByWorkspace>;
  private points: TPoint[] = [];

  constructor(
    private store: Store<{ points: TPointsByWorkspace }>,
    private siteTableEventsService: SiteTableEventsService,
    private workspaceService: WorkspaceService,
    private usersService: UsersService,
    private userService: UserService,
    private sitePointFilterService: SitePointFilterService,
    private pointFieldsService: PointFieldsService,
    private pointsUpdateService: PointsUpdateService,
    private router: Router,
    private pointAttachmentsService: PointAttachmentsService,
    private siteOptionsService: SiteOptionsService,
  ) {
    this.points$ = this.store.pipe(select(EStore.POINTS));
  }

  ngOnInit() {
    let firstSort = true;

    this.points$.pipe(takeUntil(this.destroy$)).subscribe((pointsStore) => {
      const points = cloneDeep(pointsStore);

      this.points = [];

      Object.keys(points).forEach((workspaceId) => {
        this.points.push(...points[workspaceId].entities);
      });

      if (this.points && this.points.length > 0) {
        if (firstSort) {
          firstSort = false;
        }
      }
    });
  }

  ngAfterViewInit() {
    this.userService
      .fetchUser()
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => {
          const timeline = this.createTimelineInstance();

          this.tableWrapperElement.nativeElement.replaceChild(
            timeline.element,
            this.tableElement.nativeElement,
          );

          timeline.updateColumns();
          timeline.updateWidth();
          timeline.updatePoints(this.points);
          timeline.load();

          this.sitePointFilterService.filterPoints();

          return forkJoin([
            this.workspaceService.fetchWorkspaces().pipe(
              tap(() => {
                timeline.updateVisibleColumnIndexes();
                timeline.updateVisibleColumnPositions();
                this.processPreferencesResponse();
              }),
            ),
            this.usersService.fetchAllUsers({ refetch: true }).pipe(
              tap((response) => {
                timeline.loadAvatars(response);
              }),
            ),
          ]);
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();

    const timeline = GET_TIMELINE();
    timeline.clear();
  }

  openPoint(point: TPoint): void {
    const attachmentUploading = this.pointAttachmentsService.getAttachmentUploading();

    if (!attachmentUploading) {
      this.siteTableEventsService.openPoint(point, { isOverview: false, isTimeline: true });
    }
  }

  processPreferencesResponse(): void {
    const timeline = GET_TIMELINE();
    const groupButton = this.siteOptionsService.getGroupButton();

    CALCULATE_COLUMNS();

    timeline.updateColumns();

    CLEAR_COLLAPSED_GROUPS();

    timeline.setKeyword('');
    timeline.load();
    timeline.sortPoints();

    if (groupButton) {
      groupButton.update();
    }
  }

  isTimelinePage(): boolean {
    return this.router.url === '/site/timeline';
  }

  private createTimelineInstance(): Timeline {
    const timeline = new Timeline({
      workspaceId: null,
      siteOptionsService: this.siteOptionsService,
      updatePointCallback: (_data): void => {
        const { pointIndex, field, startDate, endDate } = _data;
        const point = timeline.points[pointIndex];
        const pointId = point._id;
        const customFieldId = field.customFieldTemplateId;

        let value = point.customFieldsMap[customFieldId].value.split('~');

        if (startDate) {
          value[0] = getTimeAsUtcMidday(new Date(startDate)).toString();
        }

        if (endDate) {
          value[1] = getTimeAsUtcMidday(new Date(endDate)).toString();
        }

        if (parseFloat(value[0]) > parseFloat(value[1])) {
          value = value.reverse();
        }

        value = value.join('~');

        const body: TPointUpdate = {
          customFieldsList: [
            {
              customFieldTemplateId: customFieldId,
              value: value,
            },
          ],
        };

        this.pointsUpdateService
          .updatePointField(pointId, body)
          .pipe(
            tap(() => {
              this.sitePointFilterService.filterPoints({ _keepScrollPosition: true });

              const pointItem = timeline.items.findIndex((_item) => {
                if (_item.type === ERowType.POINT) {
                  return timeline.points[_item.index]._id === pointId;
                }

                return false;
              });
              timeline.timelineBody.virtualScroller.scrollTo(pointItem);
            }),
            catchError((error) => {
              this.pointFieldsService.showUpdatePointFieldError(error);

              timeline.load(true);

              return of();
            }),
          )
          .subscribe();
      },
      openPointCallback: (_point: TPoint): void => {
        this.openPoint(_point);
      },
    });

    return timeline;
  }
}
