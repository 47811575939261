<div
  [@openCloseInput]="!ppCollapsed ? 'open' : 'closed'"
  class="formulaBuilder__input"
  [ngClass]="{ formulaBuilder__inputClosed: ppCollapsed }"
  [class.formulaBuilder__input--empty]="ppFormula.length === 0"
>
  <div
    class="formulaBuilder__inputPlaceholder"
    [class.formulaBuilder__inputPlaceholderCollapsed]="ppCollapsed"
    *ngIf="ppFormula.length === 0"
  >
    <span *ngIf="ppCollapsed; else defaultPlaceholder">{{ ppPlaceholder }}</span>
    <ng-template #defaultPlaceholder>{{ 'formula_builder_placeholder' | ppTranslate }}</ng-template>
  </div>

  <div
    cdkDropList
    cdkDropListOrientation="mixed"
    class="formulaList"
    (cdkDropListDropped)="drop($event)"
  >
    <pp-formula-piece
      class="draggableFormulaPiece"
      *ngFor="let formulaPiece of ppFormula; let index = index"
      [ppFormulaPiece]="formulaPiece"
      [ppWorkspaceId]="ppWorkspaceId"
      [ppCanDelete]="true"
      (ppDelete)="deleteFormulaPiece(index)"
      cdkDrag
    ></pp-formula-piece>
  </div>
</div>
