import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { KEY_ESC, TAnyFunction, clickOutside, pressKey, uuid } from '@core/helpers';

import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';

import { SelectDropdownComponent } from './select-dropdown/select-dropdown.component';

import { WindowService } from '@core/services';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { DropdownService } from '../../dropdown/dropdown-service/dropdown.service';
import { TSelectDropdownData } from './select-dropdown/select-dropdown.model';
import { TSelectOption } from './select.model';
import { arraysEqual } from './utils/arrays-equal.utils';
import { getWidthNumber } from './utils/get-width-number';

@Component({
  selector: 'pp-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements AfterViewInit, OnChanges {
  @ViewChild('selectComponent', { static: true }) element: ElementRef;
  @ViewChild('selectContent', { static: true }) contentElement: ElementRef;

  @Input() ppItems: TSelectOption[];
  @Input() ppSelectedItem: TSelectOption;
  @Input() ppDisabled = false;
  @Input() ppNoSearchBar = false;
  @Input() ppIsModal = false;

  @Output() ppSelectItem = new EventEmitter();
  @Output() ppClearAll = new EventEmitter();

  buttonId = uuid();
  dropdown: TDropdown = this.dropdownService.getDropdown();
  private closeOnEscRef: TAnyFunction;
  private clickOutsideRef: TAnyFunction;
  focused = false;

  EIconPath = EIconPath;

  private window = this.windowService.getGlobalObject();
  width: string;

  constructor(
    private windowService: WindowService,
    private dropdownService: DropdownService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const dropdownVisible = this.dropdown.visible && this.dropdown.buttonId === this.buttonId;
    const itemsChanged =
      changes.ppItems && !arraysEqual(changes.ppItems.currentValue, changes.ppItems.previousValue);

    if (!dropdownVisible) {
      return;
    }

    if (changes.ppItems && (itemsChanged || changes.ppErrorMessage)) {
      this.setDropdownData();
    }

    if (this.ppDisabled) {
      this.dropdownService.hideDropdown();
    }
  }

  ngAfterViewInit(): void {
    this.closeOnEscRef = (event: KeyboardEvent): void =>
      pressKey(event, KEY_ESC, () => {
        this.onDropdownHide();
      });

    this.clickOutsideRef = (event: MouseEvent): void =>
      clickOutside(event, this.element.nativeElement, () => {
        event.stopImmediatePropagation();

        this.onDropdownHide();
      });
  }

  onDropdownHide(): void {
    this.dropdown.visible = false;
    this.focused = false;

    this.window.removeEventListener('keydown', this.closeOnEscRef, true);
    this.window.removeEventListener('click', this.clickOutsideRef, true);
  }

  selectItem(option: TSelectOption): void {
    if (!option) {
      this.clearAll();
    } else {
      this.ppSelectItem.emit(option);
    }

    this.onDropdownHide();
  }

  clearAll(): void {
    this.ppClearAll.emit();
  }

  toggleDropdown(): void {
    if (this.ppDisabled) {
      return;
    }

    if (this.dropdown.visible && this.dropdown.buttonId === this.buttonId) {
      this.dropdownService.hideDropdown();
    } else {
      this.setDropdownData();

      this.focused = true;

      this.dropdownService.showDropdown(this.buttonId, SelectDropdownComponent, {
        callback: (option) => this.selectItem(option),
        onClose: () => this.onDropdownHide(),
        popper: {
          positionFixed: true,
          placement: 'bottom-start',
        },
        suppressScrollbar: false,
        addScrollCallback: false,
        isModalDropdown: this.ppIsModal,
        width: this.width,
      });
    }
  }

  private setDropdownData(): void {
    const dropdownData: TSelectDropdownData = {
      noSearchBar: this.ppNoSearchBar,
      items: this.ppItems,
      selectedItem: this.ppSelectedItem,
      width: getWidthNumber(null, this.element.nativeElement.offsetWidth),
    };

    this.width = getWidthNumber(null, this.element.nativeElement.offsetWidth);

    this.dropdownService.setData(dropdownData);
  }
}
