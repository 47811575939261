<div class="sidePanelFavourites" (click)="toggleCollapsed()">
  <div class="sidePanelFavourites__inner">
    <pp-icon
      [ppSrc]="isCollapsed ? EIconPath.ICON_NAV_ARROW_RIGHT_18 : EIconPath.ICON_NAV_ARROW_DOWN_18"
      ppColor="inherit"
      ppClass="setSize16"
    ></pp-icon>

    <span class="sidePanelFavourites__text">
      {{ 'favourites' | ppTranslate }}
    </span>
  </div>

  <div class="sidePanelFavourites__count">
    {{ countFavorites() }}
  </div>
</div>
