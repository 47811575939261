import { Component, EventEmitter, Input, Output } from '@angular/core';
import { cloneDeep, isEqual } from 'lodash';
import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import { TShare } from 'src/app/project/modules/share/share.model';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EditAccountUserModalDataService } from '../../edit-account-user-modal-data.service';

@Component({
  selector: 'pp-account-user-modal-shares-list-bulk-share',
  templateUrl: './account-user-modal-shares-list-bulk-share.component.html',
  styleUrl: './account-user-modal-shares-list-bulk-share.component.scss',
})
export class AccountUserModalSharesListBulkShareComponent {
  @Input() ppShowBulkAccessibleTags: boolean;
  @Input() ppWorkspaceId: string;

  @Output() ppShowCustomFieldsTable = new EventEmitter<void>();

  bulkShare: TShare;
  EUserRole = EUserRole;
  EIconPath = EIconPath;
  customFieldsCount: number;

  constructor(
    private editAccountUserModalDataService: EditAccountUserModalDataService,
    private workspaceService: WorkspaceService,
  ) {
    this.editAccountUserModalDataService.sharesBulkChange$.subscribe(() => {
      if (!this.bulkShare) {
        return;
      }

      this.bulkShare = this.checkIfSharesMatch(this.bulkShare);
    });
  }

  ngOnInit(): void {
    const share = this.setInitialData();

    this.bulkShare = this.checkIfSharesMatch(share);
    this.countCustomFields();
  }

  updateShareRole(): void {
    this.editAccountUserModalDataService.bulkChangeRole(this.bulkShare);
  }

  updateShareTags(): void {
    this.editAccountUserModalDataService.bulkChangeTags(this.bulkShare);
  }

  updateShareAccessibleTags(): void {
    this.editAccountUserModalDataService.bulkChangeAccessibleTags(this.bulkShare);
  }

  updateShareActivity(): void {
    this.editAccountUserModalDataService.bulkChangeActivity(this.bulkShare);
  }

  updateShareExports(): void {
    this.editAccountUserModalDataService.bulkChangeExports(this.bulkShare);
  }

  updateShareComments(): void {
    this.editAccountUserModalDataService.bulkChangeComments(this.bulkShare);
  }

  showCustomFieldsTable(): void {
    this.ppShowCustomFieldsTable.emit();
  }

  setRole(shares: TShare[], share: TShare): TShare {
    const roles = shares.map((share) => share.shareOption);
    const role = roles.every((r) => r === roles[0]) ? roles[0] : null;

    return {
      ...share,
      shareOption: role,
    };
  }

  setTags(shares: TShare[], share: TShare): TShare {
    const tags = shares.map((share) => share.advancedAccessLevels.tags.permission);

    const tag = tags.every((t) => isEqual(t, tags[0]))
      ? cloneDeep(tags[0])
      : {
          read: null,
          edit: null,
        };

    share.advancedAccessLevels.tags.permission = { ...tag };

    return share;
  }

  setActivity(shares: TShare[], share: TShare): TShare {
    const activities = shares.map((share) => share.advancedAccessLevels.timeline.permission);
    const activity = activities.every((a) => isEqual(a, activities[0]))
      ? cloneDeep(activities[0])
      : {
          read: null,
        };

    share.advancedAccessLevels.timeline.permission = { ...activity };

    return share;
  }

  setExports(shares: TShare[], share: TShare): TShare {
    const exports = shares.map((share) => share.advancedAccessLevels.exports.permission);
    const exportPermission = exports.every((e) => isEqual(e, exports[0]))
      ? cloneDeep(exports[0])
      : {
          read: null,
        };

    share.advancedAccessLevels.exports.permission = { ...exportPermission };

    return share;
  }

  setComments(shares: TShare[], share: TShare): TShare {
    const comments = shares.map((share) => share.advancedAccessLevels.timeline.comments.permission);
    const comment = comments.every((c) => isEqual(c, comments[0]))
      ? cloneDeep(comments[0])
      : {
          read: null,
          edit: null,
        };

    share.advancedAccessLevels.timeline.comments.permission = { ...comment };

    return share;
  }

  setAvailableTags(shares: TShare[], share: TShare): TShare {
    const tagLists = shares.map((share) => share.defectTags);
    const availableTags = tagLists.every((list) => isEqual(list, tagLists[0]))
      ? cloneDeep(tagLists[0])
      : [];

    share.defectTags = availableTags;

    return share;
  }

  private checkIfSharesMatch(share: TShare): TShare {
    const sharesMap = this.editAccountUserModalDataService.getSharesMap();
    let shares = this.editAccountUserModalDataService.getIndividualShares();

    if (shares.length === 0) {
      shares = Object.values(sharesMap);

      if (shares.length === 0) {
        return share;
      }
    }

    share = this.setRole(shares, share);
    share = this.setTags(shares, share);
    share = this.setActivity(shares, share);
    share = this.setExports(shares, share);
    share = this.setComments(shares, share);
    share = this.setAvailableTags(shares, share);

    return share;
  }

  private setInitialData(): TShare {
    return {
      shareOption: null,
      tagLimited: false,
      defectTags: [],
      advancedAccessLevels: {
        tags: {
          permission: {
            read: null,
            edit: null,
          },
        },
        timeline: {
          comments: {
            permission: {
              read: null,
              edit: null,
            },
          },
          permission: {
            read: null,
          },
        },
        sitePlan: {
          permission: {
            read: null,
          },
        },
        exports: {
          permission: {
            read: null,
          },
        },
        customFields: [],
      },
    } as Partial<TShare> as TShare;
  }

  private countCustomFields(): void {
    if (!this.ppWorkspaceId) {
      this.customFieldsCount = 0;
      return;
    }

    this.customFieldsCount = this.workspaceService.findWorkspace(
      this.ppWorkspaceId,
    ).customFields.length;
  }
}
