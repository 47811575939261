<pp-account-subscription-placeholder
  *ngIf="!subscriptionData"
></pp-account-subscription-placeholder>

<div class="tabContent" [class.hidden]="!subscriptionData">
  <div class="settingsGrid">
    <pp-grid
      [ppOptions]="{ bodyPadding: true, skipHeader: true, bodyClass: 'accountSubscription__body' }"
    >
      <ng-container body>
        <ng-container *ngIf="subscriptionData">
          <div class="limitInfo">
            <img class="limitInfo__icon" [src]="EIconPath.MISC_INFO" />
            <div class="limitInfo__text">
              {{ limitInfo }}
            </div>
          </div>

          <pp-button
            class="subscription__contact-us"
            (ppAction)="contactUs()"
            ppStyle="secondary"
            *ngIf="ppReadOnly"
          >
            {{ 'contact_us' | ppTranslate }}
          </pp-button>

          <div class="limitUsers" [class.limitUsers__limited]="ppReadOnly">
            <div class="limitHeader">{{ 'users' | ppTranslate }}</div>

            <pp-limit-bar
              [ppCurrentValue]="subscriptionData.usages.SHARES_ACCOUNT_ADMIN"
              [ppMaxValue]="subscriptionData.limits?.SHARES_ACCOUNT_ADMIN"
              ppTitleKey="account_admin_users"
              [ppUnlimited]="unlimitedPlan"
            ></pp-limit-bar>

            <pp-limit-bar
              [ppCurrentValue]="subscriptionData.usages.SHARES_ADMIN"
              [ppMaxValue]="subscriptionData.limits?.SHARES_ADMIN"
              ppTitleKey="admin_users"
              [ppUnlimited]="unlimitedPlan"
            ></pp-limit-bar>

            <pp-limit-bar
              [ppCurrentValue]="subscriptionData.usages.SHARES_NORMAL"
              [ppMaxValue]="subscriptionData.limits?.SHARES_NORMAL"
              ppTitleKey="normal_users"
              [ppUnlimited]="unlimitedPlan"
            ></pp-limit-bar>

            <pp-limit-bar
              [ppCurrentValue]="subscriptionData.usages.SHARES_GUEST"
              [ppMaxValue]="subscriptionData.limits?.SHARES_GUEST"
              ppTitleKey="guest_users"
              [ppUnlimited]="unlimitedPlan"
            ></pp-limit-bar>
          </div>

          <div class="limitSites">
            <div class="limitHeader">{{ 'sites' | ppTranslate }}</div>

            <pp-limit-bar
              [ppCurrentValue]="subscriptionData.usages.SITES"
              [ppMaxValue]="subscriptionData.limits?.SITES"
              ppTitleKey="sites"
              [ppUnlimited]="unlimitedPlan"
            ></pp-limit-bar>
          </div>

          <div class="sw-update-button updateSubscriptionButton" *ngIf="!ppReadOnly">
            <pp-button
              ppId="changeSubscriptionButton"
              ppStyle="primary"
              (ppAction)="changeSubscription()"
            >
              <span>
                {{ 'change_subscription' | ppTranslate }}
              </span>
            </pp-button>
          </div>
        </ng-container>

        <ng-container *ngIf="!subscriptionData">
          <app-spinner></app-spinner>
        </ng-container>
      </ng-container>
    </pp-grid>
  </div>
</div>
