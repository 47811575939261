<div
  class="sidePanelWorkspace"
  [class.sidePanelWorkspace--active]="active"
  [class.sidePanelWorkspace--inFolder]="ppWorkspace.folderId"
  (click)="openSite()"
>
  <div
    class="sidePanelWorkspace__inner"
    ppTooltip
    [ppTitle]="ppWorkspace.workspace.name"
    [ppTrimTooltip]="true"
  >
    {{ ppWorkspace.workspace.name }}
  </div>

  <button class="favouriteIcon" (click)="toggleFavourite($event)">
    <pp-icon
      [ppSrc]="favourite ? EIconPath.FAVOURITE_ACTIVE_16 : EIconPath.FAVOURITE_INACTIVE_16"
      ppColor="inherit"
      ppClass="setSize16"
      ppTooltip
      [ppTitle]="
        (favourite ? 'remove_site_from_favourites' : 'add_site_to_favourites') | ppTranslate
      "
    ></pp-icon>
  </button>
</div>
