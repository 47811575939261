import { isEqual } from 'lodash';

import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TAccount } from 'src/app/project/modules/account/account.model';
import { TPreferences } from 'src/app/project/modules/preferences/preferences.model';
import { TUser } from 'src/app/project/modules/user/user.model';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { TUISettings } from '../../../ui/ui.model';

import { SidePanelService } from '../side-panel.service';

import { ScreenService } from '@core/services';
import { getCrossbrowserEventPath } from 'src/app/core/helpers/compose-event-path';
import { PreviousPageService } from 'src/app/core/services/previous-page.service';
import { GET_SHARES } from 'src/app/project/modules/share/shares.store';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { isModalClicked } from 'src/app/project/shared/is-modal-clicked';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { FleetService } from '../../../fleet-management/fleet-service/fleet.service';
import { PointAttachmentsService } from '../../../points/point-modal/point-attachments/point-attachments.service';
import { TSidePanel } from '../side-panel.model';
import { SidePanelUtilsService } from './side-panel-utils.service';

@Component({
  selector: 'pp-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss'],
})
export class SidePanelComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('sidePanelComponent', { static: false }) sidePanelElement: ElementRef;

  private readonly destroy$ = new Subject<void>();

  sidePanel: TSidePanel = this.sidePanelService.getSidePanel();

  offline$: Observable<boolean>;
  offline: boolean;
  user$: Observable<TUser>;
  preferences$: Observable<TPreferences>;
  accounts$: Observable<TAccount[]>;
  workspaces$: Observable<TWorkspacesById>;
  workspaces: TWorkspacesById;
  ui$: Observable<TUISettings>;
  ui: TUISettings;
  actionBar = false;
  user: TUser;
  accounts: TAccount[] = [];

  paramId: string = null;
  routerPath = this.router.url;
  isMobile = false;
  url = '';
  timelineEnabled = false;
  remindersEnabled = false;
  EIconPath = EIconPath;
  disabled = false;
  fleetManagementEnabled = false;
  showBackArrow: boolean = false;

  constructor(
    @Inject('HOSTNAME') private host: string,
    private store: Store<{
      offline: boolean;
      user: TUser;
      preferences: TPreferences;
      accounts: TAccount[];
      workspaces: TWorkspacesById;
      ui: TUISettings;
    }>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sidePanelService: SidePanelService,
    private screenService: ScreenService,
    private sidePanelUtilsService: SidePanelUtilsService,
    private fleetService: FleetService,
    private pointAttachmentsService: PointAttachmentsService,
    private previousPageService: PreviousPageService,
  ) {
    this.offline$ = this.store.pipe(select(EStore.OFFLINE));
    this.preferences$ = this.store.pipe(select(EStore.PREFERENCES));
    this.user$ = this.store.pipe(select(EStore.USER));
    this.accounts$ = this.store.pipe(select(EStore.ACCOUNTS));
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
    this.ui$ = this.store.pipe(select(EStore.UI));

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.routerPath = event.url;
      }
    });
  }

  @HostListener('document:keydown.esc')
  onEscPress(): void {
    if (this.sidePanel.expanded) {
      this.collapsePanel();
    }
  }

  ngOnInit() {
    this.screenService.isMobile$.pipe(takeUntil(this.destroy$)).subscribe((isMobile) => {
      if (this.isMobile !== isMobile) {
        this.collapsePanel();
      }

      this.isMobile = isMobile;
      this.sidePanel.hidden = isMobile;
    });

    this.activatedRoute.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.url = this.router.url;
      this.paramId = params.get('id');
      this.showBackArrow = this.shouldShowBackArrow();

      this.sidePanelService.setParams(this.url, this.paramId);
    });

    this.offline$.pipe(takeUntil(this.destroy$)).subscribe((offline) => {
      this.offline = offline;
    });

    this.ui$.pipe(takeUntil(this.destroy$)).subscribe((ui) => {
      this.actionBar = ui.actionBar;
    });

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      this.workspaces = workspaces;

      this.checkUserPermissions(this.accounts);
    });

    this.accounts$.pipe(takeUntil(this.destroy$)).subscribe((accounts) => {
      if (Array.isArray(accounts)) {
        this.checkUserPermissions(accounts);

        this.accounts = accounts;
      }
    });

    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
    });

    this.pointAttachmentsService.fileUploading$
      .pipe(takeUntil(this.destroy$))
      .subscribe((uploading) => {
        this.disabled = uploading;
      });
  }

  ngAfterViewInit() {
    const clickOutsideHandler = this.sidePanelService.createClickOutsideHandler(
      this.sidePanelElement.nativeElement,
      this.destroy$,
    );

    clickOutsideHandler.caught$.subscribe((event) => {
      this.onClickOutside(event);
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  expandPanel(): void {
    this.sidePanelUtilsService.expandPanel();
  }

  collapsePanel(): void {
    this.sidePanelUtilsService.collapsePanel();
  }

  togglePanel(): void {
    if (isEqual(GET_SHARES(), {})) {
      return;
    }

    if (this.sidePanel.expanded) {
      this.collapsePanel();
    } else {
      this.expandPanel();
    }
  }

  toggleNotifications(): void {
    this.sidePanelUtilsService.toggleNotifications();
  }

  closePanel(): void {
    if (this.sidePanel.notificationsExpanded) {
      this.toggleNotifications();
    } else {
      this.collapsePanel();
    }
  }

  private onClickOutside(event: MouseEvent): void {
    const path = getCrossbrowserEventPath(event);

    if (isModalClicked(path)) {
      return;
    }

    this.collapsePanel();
  }

  private shouldShowBackArrow(): boolean {
    return (
      (this.previousPageService.getPreviousUrl() || '').startsWith('/fleet-management') ||
      (((!this.routerPath.startsWith('workspace') && !this.routerPath.startsWith('/site')) ||
        this.routerPath.startsWith('/site/overview') ||
        this.routerPath.startsWith('/site/timeline')) &&
        !this.routerPath.startsWith('/fleet-management') &&
        !this.isMobile)
    );
  }

  private checkUserPermissions(accounts: TAccount[]): void {
    let timelineEnabled = false;
    let remindersEnabled = false;

    accounts.forEach((account) => {
      if (account.accountFeatures.timeline) {
        timelineEnabled = true;
      }

      if (account.accountFeatures.reminders) {
        remindersEnabled = true;
      }
    });

    this.timelineEnabled = timelineEnabled;
    this.remindersEnabled = remindersEnabled;
    this.fleetManagementEnabled = this.fleetService.getFleetAccess();
  }
}
