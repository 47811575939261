import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { TranslationPipe } from '../../features/translate/translation.pipe';
import { AccountSettingsSubscriptionUpdateModalComponent } from '../../modules/account/account-settings/account-settings-pages/account-settings-subscription/account-settings-subscription-update-modal/account-settings-subscription-update-modal.component';
import { TSubscriptionUpdateModalData } from '../../modules/account/account-settings/account-settings-pages/account-settings-subscription/account-settings-subscription-update-modal/subscription-update-modal.consts';
import { AccountSubscriptionService } from '../../modules/account/account-subscription.service';
import {
  EAccountSubscriptionType,
  TAccount,
  TAccountSubscription,
} from '../../modules/account/account.model';
import { logEventInGTAG } from '../../services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventUserSettings,
} from '../../services/analytics/google-analytics.consts';
import { EIconPath } from '../../shared/enums/icons.enum';

@Component({
  selector: 'pp-account-subscription',
  templateUrl: './account-subscription.component.html',
  styleUrls: ['./account-subscription.component.scss'],
})
export class AccountSubscriptionComponent implements OnInit, OnDestroy {
  @Input() ppAccountId: string;
  @Input() ppReadOnly = false;

  subscriptionData: TAccountSubscription;

  private accounts$: Observable<TAccount[]>;
  private readonly destroy$ = new Subject<void>();
  private account: TAccount;
  unlimitedPlan: boolean;
  EIconPath = EIconPath;
  limitInfo: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private store: Store<{
      accounts: TAccount[];
    }>,

    private modalService: ModalService,
    private accountSubscriptionService: AccountSubscriptionService,
    private translationPipe: TranslationPipe,
  ) {}

  ngOnInit(): void {
    this.accounts$ = this.store.pipe(select(EStore.ACCOUNTS));

    this.accounts$.pipe(takeUntil(this.destroy$)).subscribe((accounts) => {
      if (accounts.length > 0) {
        this.account = cloneDeep(
          accounts.find((account) => account.accountId === this.ppAccountId),
        );

        if (this.account) {
          this.accountSubscriptionService
            .fetchSubscription(this.ppAccountId)
            .pipe(
              takeUntil(this.destroy$),
              tap((subscriptionData) => {
                this.subscriptionData = subscriptionData;

                this.unlimitedPlan =
                  this.subscriptionData.subscriptionType === EAccountSubscriptionType.UNLIMITED;

                this.limitInfo = this.getLimitInfo();
              }),
            )
            .subscribe();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  changeSubscription(): void {
    const modalData: TSubscriptionUpdateModalData = {
      subscription: this.subscriptionData,
      accountId: this.ppAccountId,
    };

    this.modalService.setData(modalData);
    this.modalService.showModal(AccountSettingsSubscriptionUpdateModalComponent, {
      callback: () => {
        this.subscriptionData = this.accountSubscriptionService.getSubscriptionData();

        this.unlimitedPlan =
          this.subscriptionData.subscriptionType === EAccountSubscriptionType.UNLIMITED;
      },
    });
  }

  contactUs() {
    logEventInGTAG(EGoogleEventUserSettings.SUBSCRIPTION_CONTACT, {
      event_category: EGoogleEventCategory.SETTINGS,
    });

    this.document.location.href = 'mailto:support@pinpointworks.com';
  }

  getLimitInfo(): string {
    if (this.ppReadOnly) {
      if (this.unlimitedPlan) {
        return this.translationPipe.transform('subscription_unlimited_info');
      } else {
        return this.translationPipe.transform('subscription_limit_info__limited');
      }
    }

    if (this.unlimitedPlan) {
      return this.translationPipe.transform('subscription_unlimited_info');
    }

    return this.translationPipe.transform('subscription_limit_info', {
      planName: this.subscriptionData.subscriptionType,
    });
  }
}
