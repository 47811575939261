import { Component, Input } from '@angular/core';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { PointAttachmentsService } from 'src/app/project/modules/points/point-modal/point-attachments/point-attachments.service';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSidePanel,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TUser } from '../../../../user/user.model';
import { UserMenuDropdownComponent } from '../../../user-menu-dropdown/user-menu-dropdown.component';
import { TSidePanel } from '../../side-panel.model';
import { SidePanelService } from '../../side-panel.service';

@Component({
  selector: 'pp-side-panel-user',
  templateUrl: './side-panel-user.component.html',
  styleUrls: ['./side-panel-user.component.scss'],
})
export class SidePanelUserComponent {
  @Input() ppUser: TUser;
  @Input() ppDisabled: boolean;

  sidePanel: TSidePanel = this.sidePanelService.getSidePanel();
  private dropdown: TDropdown = this.dropdownService.getDropdown();
  EIconPath = EIconPath;

  constructor(
    private sidePanelService: SidePanelService,
    private dropdownService: DropdownService,
    private pointAttachmentsService: PointAttachmentsService,
  ) {}

  toggleUserDropdown(buttonId: string): void {
    if (this.dropdown.visible && this.dropdown.buttonId === buttonId) {
      this.dropdownService.hideDropdown();
    } else {
      if (this.pointAttachmentsService.getAttachmentUploading()) {
        return;
      }

      this.sidePanel.notificationsExpanded = false;

      logEventInGTAG(EGoogleEventSidePanel.SIDE_PANEL_USER_DROPDOWN, {
        event_category: EGoogleEventCategory.SIDE_PANEL,
      });

      this.dropdownService.showDropdown(buttonId, UserMenuDropdownComponent, {
        modifiers: [
          {
            name: 'computeStyles',
            options: {
              gpuAcceleration: false,
            },
          },
          {
            name: 'offset',
            options: {
              offset: [60, -40],
            },
          },
        ],
        long: true,
      });
    }
  }
}
