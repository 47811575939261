<div class="sidePanelAccountRow">
  <div class="sidePanelAccountRow__name" (click)="toggleAccount()">
    <pp-icon
      [ppSrc]="collapsed ? EIconPath.ICON_NAV_ARROW_RIGHT_18 : EIconPath.ICON_NAV_ARROW_DOWN_18"
      ppClass="setSize18"
      ppColor="inherit"
    ></pp-icon>

    <span
      class="sidePanelAccountRow__text"
      ppTooltip
      [ppTrimTooltip]="true"
      [ppTitle]="account.name"
    >
      {{ account.name }}
    </span>
  </div>

  <button
    *ngIf="cogVisible"
    (click)="toggleAccountOptionsDropdown()"
    ppTooltip
    [ppTitle]="'account_settings' | ppTranslate"
    class="sidePanelAccountCogButton"
    [id]="cogButtonId"
  >
    <pp-icon [ppSrc]="EIconPath.ICON_NAV_COG_18" ppClass="setSize18" ppColor="inherit"></pp-icon>
  </button>
</div>
