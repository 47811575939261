import { Component, Input } from '@angular/core';

import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { RemoveWorkspaceUser } from 'src/app/project/modules/workspace/workspace.actions';

import { TShare } from 'src/app/project/modules/share/share.model';

import { ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { SharesService } from 'src/app/project/modules/share/shares.service';

import { uuid } from '@core/helpers';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { LimitExceededService } from 'src/app/project/components/change-limit-modal/limit-exceeded.service';
import {
  ConfirmModalComponent,
  TConfirmModalParams,
} from 'src/app/project/components/confirm-modal/confirm-modal.component';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSettings,
} from 'src/app/project/services/analytics/google-analytics.consts';

@Component({
  selector: 'pp-delete-share-button',
  templateUrl: './delete-share-button.component.html',
  styleUrls: ['./delete-share-button.component.scss'],
})
export class DeleteShareButtonComponent {
  @Input() ppShare: TShare;
  @Input() ppDisabled: boolean;

  buttonId = uuid();

  constructor(
    private store: Store,
    private sharesService: SharesService,
    private promptService: PromptService,
    private modalService: ModalService,
    private translationPipe: TranslationPipe,
    private limitExceededService: LimitExceededService,
  ) {}

  showDeleteShareModal(): void {
    this.setDeleteShareModalData();

    this.modalService.showModal(ConfirmModalComponent, {
      closeWarning: true,
      callback: () => {
        this.deleteShare();
      },
    });
  }

  private setDeleteShareModalData(): void {
    this.modalService.setData<TConfirmModalParams>({
      message: this.translationPipe.transform('confirm_remove_users'),
      heading: this.translationPipe.transform('remove_users_from_sites'),
      redButton: true,
      confirmText: this.translationPipe.transform('remove_users_button'),
    });
  }

  private deleteShare(): void {
    this.sharesService
      .deleteShare(this.ppShare.shareId)
      .pipe(
        tap(() => {
          this.handleDeleteShareSuccess();
        }),
        catchError((error) => {
          if (error.status === EStatusCode.UPGRADE_REQUIRED) {
            this.limitExceededService.showLimitsExceededModal();
          } else {
            this.promptService.showError(
              this.translationPipe.transform('prompt_share_delete_error'),
            );
          }

          return of(null);
        }),
      )
      .subscribe();
  }

  private handleDeleteShareSuccess(): void {
    this.removeUserFromStore();
    this.logShareDeletion();
    this.promptService.showSuccess(this.translationPipe.transform('prompt_user_site_deleted'));
  }

  private removeUserFromStore(): void {
    this.sharesService.removeWorkspaceShare(this.ppShare.workspaceId, this.ppShare.shareId);

    this.store.dispatch(
      new RemoveWorkspaceUser({
        workspaceId: this.ppShare.workspaceId,
        user: this.ppShare.userId,
      }),
    );
  }

  private logShareDeletion(): void {
    logEventInGTAG(EGoogleEventSettings.SETTINGS__USER__DELETE, {
      event_category: EGoogleEventCategory.SETTINGS,
    });
  }
}
