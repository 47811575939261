import { cloneDeep } from 'lodash';

import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { Observable, Subject, of } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { UpdatePointReminder } from '../points.actions';

import { TUISettings } from 'src/app/project/modules/ui/ui.model';
import { TUser } from 'src/app/project/modules/user/user.model';
import { TWorkspace, TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { TSync } from '../../offline/sync.model';
import { TPoint, TPointsByWorkspace } from '../points.model';

import { UnsavedFilesModalComponent } from '../unsaved-files-modal/unsaved-files-modal.component';

import { ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { SiteFilterDataService } from 'src/app/project/modules/filters/site-filter-data-service/site-filter-data.service';
import { SitePointFilterService } from 'src/app/project/modules/filters/site-point-filter.service';
import { PlanPointService } from 'src/app/project/modules/plan/plan-point/plan-point.service';
import { RemindersUtilityService } from 'src/app/project/modules/reminders/reminders/reminders-utility.service';
import { PermissionsService } from 'src/app/project/modules/share/permissions.service';
import { SiteTablePointsService } from '../../site/site-table/site-table-points.service';
import {
  PointAttachmentsService,
  TFilesToUpload,
} from '../point-modal/point-attachments/point-attachments.service';
import { PointsDetailsService } from '../points-details.service';
import { PointsFetchingService } from '../points-fetching.service';
import { PointsService } from '../points.service';
import { PointHalfModalService, TPointHalfModal } from './point-half-modal.service';

import { DOCUMENT } from '@angular/common';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { GET_ACTIVE_PLAN } from 'src/app/project/modules/plan/plan.store';
import {
  canDeletePoint,
  canEditPoint,
} from 'src/app/project/modules/share/share-utils/share-permissions';
import Table from 'src/app/project/modules/site/site-table/custom-table/table/Table';
import Timeline from 'src/app/project/modules/site/site-timeline/Timeline';
import { GET_TIMELINE } from 'src/app/project/modules/site/site-timeline/timeline.ui.store';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { EWorkspaces } from 'src/app/project/modules/workspace/workspaces.enum';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { EBasicField } from 'src/app/project/shared/enums/basic-fields-enums';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { TPointDetails } from 'src/app/project/view-models/points-details-response.model';
import { SiteRedirectService } from '../../auth/site-redirect.service';
import { CustomTableService } from '../../site/site-table/custom-table/custom-table.service';
import { SiteTableService } from '../../site/site-table/site-table.service';
import { PointsGenerateService } from '../point-generate/points-generate.service';
import { PointModalHeaderService } from '../point-modal-header/point-modal-header.service';
import { PointArrowRedirectService } from '../point-modal/point-arrow-redirect.service';
import { PointModalService } from '../point-modal/point-modal.service';
import { PointActivityService } from '../point-modal/point-timeline/point-activity.service';

@Component({
  selector: 'pp-point-half-modal',
  templateUrl: './point-half-modal.component.html',
  styleUrls: ['./point-half-modal.component.scss'],
})
export class PointHalfModalComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private readonly modalOpen$ = new Subject<void>();
  modal: TPointHalfModal = this.pointHalfModalService.getModal();
  private filesToUpload: TFilesToUpload = this.pointAttachmentsService.getFilesToUpload();

  offline$: Observable<boolean>;
  offline: boolean;
  workspaces$: Observable<TWorkspacesById>;
  private sync$: Observable<TSync>;
  private points$: Observable<TPointsByWorkspace>;
  user$: Observable<TUser>;
  user: TUser;
  ui$: Observable<TUISettings>;
  ui: TUISettings;
  canEditPoint = false;
  canDeletePoint = false;

  point: TPoint;
  workspaceId: string;
  hasWorkspaces = false;
  scrollPosition = 0;
  activePlan = GET_ACTIVE_PLAN();
  pointDetails: TPointDetails = null;
  isReminders = false;
  isTimeline = false;
  isOverview = false;
  nextPointExists = false;
  detailsFetched = false;
  freshPointFetched = false;

  _id: string;
  commentsEditable = false;
  attachmentUploading = false;
  planWidth: number;

  private workspace: TWorkspace;
  private workspaces: TWorkspacesById;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private store: Store<{
      workspaces: TWorkspacesById;
      sync: TSync;
      points: TPointsByWorkspace;
      user: TUser;
      offline: boolean;
      ui: TUISettings;
    }>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private pointHalfModalService: PointHalfModalService,
    private pointAttachmentsService: PointAttachmentsService,
    private pointsService: PointsService,
    private siteTablePointsService: SiteTablePointsService,
    private permissionsService: PermissionsService,
    private promptService: PromptService,
    private pointsFetchingService: PointsFetchingService,
    private planPointService: PlanPointService,
    private translationPipe: TranslationPipe,
    private pointsDetailsService: PointsDetailsService,
    private pointModalHeaderService: PointModalHeaderService,
    private sitePointFilterService: SitePointFilterService,
    private remindersUtilityService: RemindersUtilityService,
    private pointModalService: PointModalService,
    private pointActivityService: PointActivityService,
    private siteFilterDataService: SiteFilterDataService,
    private workspaceService: WorkspaceService,
    private activeService: ActiveService,
    private customTableService: CustomTableService,
    private pointsGenerateService: PointsGenerateService,
    private siteTableService: SiteTableService,
    private siteRedirectService: SiteRedirectService,
    private pointArrowRedirectService: PointArrowRedirectService,
  ) {
    this.offline$ = this.store.pipe(select(EStore.OFFLINE));
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
    this.sync$ = this.store.pipe(select(EStore.SYNC));
    this.points$ = this.store.pipe(select(EStore.POINTS));
    this.user$ = this.store.pipe(select(EStore.USER));
    this.ui$ = this.store.pipe(select(EStore.UI));
  }

  @HostListener('window:keyup', ['$event'])
  keyUpEvent(event: KeyboardEvent): void {
    this.pointArrowRedirectService.tryRedirectPointOnKeyup({
      event,
      attachmentUploading: this.attachmentUploading,
      point: this.point,
      id: this._id,
      isReminders: this.isReminders,
      isTimeline: this.isTimeline,
      isOverview: this.isOverview,
      isFull: false,
    });
  }

  @HostListener('window:keydown', ['$event'])
  keyDownEvent(event: KeyboardEvent): void {
    if (
      !this.document.activeElement.classList.contains('ql-editor') &&
      !this.document.activeElement.classList.contains('customFieldCost__input') &&
      (event.key === 'ArrowDown' || event.key === 'ArrowUp')
    ) {
      event.preventDefault();
    }
  }

  ngOnInit() {
    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => this.userHandler(user));
    this.activatedRoute.paramMap
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => this.paramsHandler(params));
    this.offline$
      .pipe(takeUntil(this.destroy$))
      .subscribe((offline) => this.offlineHandler(offline));
    this.workspaces$
      .pipe(takeUntil(this.destroy$))
      .subscribe((workspaces) => this.workspacesHandler(workspaces));
    this.points$.pipe(takeUntil(this.destroy$)).subscribe((points) => this.pointsHandler(points));
    this.pointAttachmentsService.fileUploading$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isUploading) => this.fileUploadingHandler(isUploading));
    this.ui$.pipe(takeUntil(this.destroy$)).subscribe((ui) => this.uiHandler(ui));
    this.pointsDetailsService.reactionsChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.reactionsHandler());

    this.activatedRoute.paramMap.subscribe(() => {
      if (this.point && this.point.sequenceNumber) {
        this.siteTablePointsService.dehighlightPoint();
      }
    });
  }

  ngOnDestroy() {
    if (this.modal.visible) {
      this.hide(false);
    }

    if (this.point) {
      this.siteTablePointsService.dehighlightPoint();
    }

    this.destroy$.next();
    this.updateSitePlan();
  }

  userHandler(user: TUser): void {
    this.user = user;
  }

  paramsHandler(params: ParamMap): void {
    this.isReminders = this.router.url.search(EWorkspaces.REMINDERS) !== -1;
    this.isTimeline = this.router.url.search(EWorkspaces.TIMELINE) !== -1;
    this.isOverview = this.router.url.search(EWorkspaces.OVERVIEW) !== -1;

    if (this.point && this.point.sequenceNumber) {
      this.siteTablePointsService.dehighlightPoint();
    }

    this._id = params['params']._id;
    this.generatePoint(this.pointsService.findPoint(this._id));
    this.pointDetails = null;

    this.prepareWorkspace()
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          if (this.isOverview || this.isTimeline || this.isReminders) {
            this.fetchPoint();
          } else if (this.siteTableService.getTableLoaded()) {
            this.fetchPoint();
          } else {
            this.siteTableService.tableLoadChange$
              .pipe(
                takeUntil(this.destroy$),
                tap((tableLoaded) => {
                  if (tableLoaded) {
                    this.fetchPoint();
                  }
                }),
              )
              .subscribe();
          }
        }),
      )
      .subscribe();

    if (this.point) {
      this.workspaceId = this.point.workspaceRef.id;

      this.workspacesHandler(this.workspaces);
      this.activateTablePoint();
      this.showModal();
    } else {
      this.sync$.pipe(takeUntil(this.modalOpen$)).subscribe((sync) => {
        if (sync.basicFetched) {
          this.pointsFetchingService
            .fetchPoint(this._id)
            .pipe(
              tap((point) => {
                if (point) {
                  this.generatePoint(this.pointsService.findPoint(this._id));

                  this.workspaceId = this.point.workspaceRef.id;

                  this.workspacesHandler(this.workspaces);

                  if (!this.isTimeline) {
                    // modal can load before the table if someone opens a point from URL or there is no table because reminders or timeline
                    this.activateTablePoint();
                  }

                  this.showModal();
                  this.modalOpen$.next();
                } else {
                  this.onPointLoadError();
                }
              }),
              catchError((error) => {
                this.onPointLoadError();

                throw error;
              }),
            )
            .subscribe();
        }
      });
    }

    this.planWidth = this.ui?.sitePlanWidth?.[this.workspaceId];
  }

  private onPointLoadError(): void {
    const prompt = this.translationPipe.transform('prompt_point_moved_deleted');

    this.promptService.showWarning(prompt);
    this.pointHalfModalService.hideModal(true, true);
  }

  offlineHandler(offline: boolean): void {
    this.offline = offline;
    this.attachmentUploading = false;
  }

  workspacesHandler(workspaces: TWorkspacesById): void {
    this.workspaces = workspaces;
    this.hasWorkspaces = !!workspaces;

    if (this.hasWorkspaces) {
      this.workspace = this.workspaces[this.workspaceId];
    }

    this.checkCommentsPermissions();
  }

  pointsHandler(points: TPointsByWorkspace): void {
    if (Object.keys(points).length > 0) {
      const point = this.pointsService.findPoint(this._id);

      if (point) {
        this.generatePoint(cloneDeep(point));

        if (this.isReminders) {
          this.nextPointExists =
            this.remindersUtilityService.getIdOfNextReminderPoint(this._id) !== this._id;
        } else if (this.isTimeline) {
          const timeline: Timeline = GET_TIMELINE();

          this.nextPointExists = timeline?.points.length > 1;
        } else {
          const table: Table = this.customTableService.getTable();

          this.nextPointExists = table?.points.length > 1;
        }
      }
    }
  }

  fetchPoint(): void {
    this.detailsFetched = false;
    this.freshPointFetched = false;

    this.pointsFetchingService
      .fetchPoint(this._id)
      .pipe(
        takeUntil(this.destroy$),
        tap((point) => {
          this.freshPointFetched = true;
          this.siteTablePointsService.updateTablePoint(point);
          this.updateSitePlan();

          this.pointsDetailsService
            .fetchPointDetails(this._id)
            .pipe(
              takeUntil(this.destroy$),
              tap(() => {
                this.pointDetails = this.pointsDetailsService.getPointDetails(this._id || null);
                this.detailsFetched = true;
              }),
            )
            .subscribe();
        }),
        catchError((error) => {
          if (error.status === EStatusCode.FORBIDDEN) {
            this.siteRedirectService.redirectToSites();
          }

          return of();
        }),
      )
      .subscribe();
  }

  fileUploadingHandler(isUploading: boolean): void {
    this.attachmentUploading = isUploading;
  }

  uiHandler(ui: TUISettings): void {
    this.ui = ui;
    this.planWidth = this.ui?.sitePlanWidth?.[this.workspaceId];
  }

  reactionsHandler(): void {
    this.pointDetails = this.pointsDetailsService.getPointDetails(this._id);

    if (this.pointDetails.reminders[0]) {
      this.store.dispatch(
        new UpdatePointReminder({
          workspaceId: this.workspaceId,
          _id: this._id,
          value: this.pointDetails.reminders[0].timestampEpochMillis.toString(),
        }),
      );
    } else {
      this.store.dispatch(
        new UpdatePointReminder({
          workspaceId: this.workspaceId,
          _id: this._id,
          value: null,
        }),
      );
    }

    if (!this.isTimeline) {
      this.customTableService.updatePoint({
        _id: this._id,
        field: EBasicField.REMINDER,
        newValue: this.pointDetails?.reminders.length > 0,
      });
    }

    this.sitePointFilterService.filterPoints({ _keepScrollPosition: true });
  }

  checkCommentsPermissions(): void {
    if (this.workspace && this.workspaceId) {
      this.canEditPoint = canEditPoint(this.workspace.share.shareOption, this.user);
      this.canDeletePoint = canDeletePoint(this.workspace.share.shareOption, this.user);

      const commentPermissions = this.permissionsService.getCommentPermissions(
        this.workspace.workspaceId,
      );

      this.commentsEditable = commentPermissions.edit;
    }
  }

  updateScrollPosition(event: number): void {
    this.scrollPosition = event;
  }

  updateSitePlan(): void {
    this.planPointService.updateFeature(this._id, this.workspaceId, true);
    this.sitePointFilterService.filterPoints({ _keepScrollPosition: true });
  }

  prevPoint(): void {
    this.pointModalHeaderService.prevPoint({
      attachmentUploading: this.attachmentUploading,
      point: this.point,
      _id: this._id,
      isReminders: this.isReminders,
      isTimeline: this.isTimeline,
      isOverview: this.isOverview,
      isFull: false,
    });
  }

  nextPoint(): void {
    this.pointModalHeaderService.nextPoint({
      attachmentUploading: this.attachmentUploading,
      point: this.point,
      _id: this._id,
      isReminders: this.isReminders,
      isTimeline: this.isTimeline,
      isOverview: this.isOverview,
      isFull: false,
    });
  }

  expandModal(): void {
    if (!this.attachmentUploading && !this.activePlan.active) {
      this.pointModalHeaderService.expandModal({
        attachmentUploading: this.attachmentUploading,
        _id: this._id,
        workspaceId: this.workspaceId,
        isReminders: this.isReminders,
        isTimeline: this.isTimeline,
        isOverview: this.isOverview,
      });
    }
  }

  hide(returnToSite: boolean = true): void {
    if (!this.attachmentUploading && !this.activePlan.active) {
      if (this.filesToUpload.data.length > 0) {
        for (let index = 0; index < this.filesToUpload.data.length; index += 1) {
          const file = this.filesToUpload.data[index];

          if (!file.uploaded) {
            this.modalService.showModal(UnsavedFilesModalComponent);
            break;
          } else {
            this.pointHalfModalService.hideModal(returnToSite);
          }
        }
      } else {
        this.pointHalfModalService.hideModal(returnToSite);
      }
    }
  }

  setInitialScroll(): void {
    const filters = this.siteFilterDataService.getFilters();
    const pointContainsKeyword =
      filters?.pointsWithKeyword?.length && filters.pointsWithKeyword.includes(this.point._id);

    if (pointContainsKeyword) {
      const newScrollPosition = this.pointActivityService.getTimelineHeadingTop();

      if (newScrollPosition) {
        this.pointActivityService.scrollToTimelineHeading({ behavior: 'smooth' });
        this.pointModalService.setScrollPosition(newScrollPosition);
        this.updateScrollPosition(newScrollPosition);
      }
    }
  }

  private showModal(): void {
    this.pointHalfModalService.showModal(
      this.workspaceId,
      this._id,
      this.isReminders || this.isOverview,
      this.isTimeline,
    );
    this.siteTablePointsService.highlightPoint(this.point.sequenceNumber.toString());
  }

  private prepareWorkspace(): Observable<TWorkspace> {
    if (this.point && this.workspaces?.[this.point.workspaceRef.id]) {
      return of(null);
    }

    const workspaceId = this.point
      ? this.point.workspaceRef.id
      : this.activeService.getActiveWorkspaceId();

    return this.workspaceService.generateWorkspace(workspaceId);
  }

  private activateTablePoint(): void {
    const table: Table = this.customTableService.getTable();

    if (table) {
      const point = table.points.find((_point) => _point._id === this._id);

      if (table.tableBody && point) {
        table.activatePoint(point.sequenceNumber);
      }
    }
  }

  generatePoint(point: TPoint): void {
    if (!point) {
      this.hide();

      return;
    }

    this.point = this.pointsGenerateService.correctPoint(point);
  }
}
