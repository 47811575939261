import { Injectable } from '@angular/core';
import { ModalService } from '../modal/modal.service';
import { ChangeLimitModalComponent } from './change-limit-modal.component';
import { TChangeLimitsModalData } from './change-limit-modal.model';

@Injectable({
  providedIn: 'root',
})
export class LimitExceededService {
  constructor(private modalService: ModalService) {}

  showSitesLimitExceededModal(): void {
    const modalData: TChangeLimitsModalData = this.setSitesLimitModalData();

    this.showChangeLimitModal(modalData);
  }

  showLimitsExceededModal(): void {
    const modalData: TChangeLimitsModalData = this.setModalData();

    this.showChangeLimitModal(modalData);
  }

  private showChangeLimitModal(modalData: TChangeLimitsModalData): void {
    this.modalService.hideModal().then(() => {
      this.modalService.setData<TChangeLimitsModalData>(modalData);

      this.modalService.showModal(ChangeLimitModalComponent);
    });
  }

  private setSitesLimitModalData(): TChangeLimitsModalData {
    return {
      firstMessageKey: 'sites_limit_reached_description_1',
      secondMessageKey: 'sites_limit_reached_description_2',
      confirmKey: 'ok',
      header: 'sites_limit_reached',
    };
  }

  private setModalData(): TChangeLimitsModalData {
    return {
      firstMessageKey: 'users_limit_reached_description_1',
      secondMessageKey: 'users_limit_reached_description_2',
      confirmKey: 'ok',
      header: 'users_limit_reached',
    };
  }
}
