<div
  class="search-box"
  [class.searchBox--focus]="focused || searchBox.length > 0"
  [class.search-box--wide]="ppSize === 'wide'"
>
  <pp-icon
    [ppSrc]="EIconPath.ICON_NAV_SEARCH_14"
    ppClass="setSize14"
    ppColor="grey-600"
    *ngIf="!focused && searchBox.length === 0"
  />

  <input
    #input
    (ppOnRendered)="onInputRendered($event)"
    id="searchBoxKeywords"
    class="searchInput input"
    name="filter-text"
    type="text"
    [placeholder]="ppPlaceholder"
    [(ngModel)]="searchBox"
    (focus)="onInputFocus()"
    (focusout)="onInputBlur()"
    (keyup)="filterItems()"
    (keydown.enter)="onEnter()"
    [style.color]="ppColor"
  />

  <div class="closeIcon__wrapper">
    <img
      id="searchInputKeywordsClear"
      class="closeIcon"
      *ngIf="searchBox.length > 0"
      [src]="EIconPath.INTERACTION_DELETE_TEXT"
      (click)="clear()"
    />
  </div>
</div>
