import { EFormulaPieceType, TConditionalFormula, TFormulaPiece } from './formula-input.type';
import { EConditionalFormulaOperator } from './formula-operators.enum';
import { BackendFormulaOperatorMapping } from './formula.consts';

export function buildFormula(formula: TFormulaPiece[] | TConditionalFormula): string {
  if (Array.isArray(formula)) return arrayToStringFormula(formula);
  else {
    let result = 'if(';

    result += arrayToStringFormula(formula.condition) + ',';
    result += arrayToStringFormula(formula.truthy) + ',';
    result += arrayToStringFormula(formula.falsey) + ')';

    return result;
  }
}

function arrayToStringFormula(formula: TFormulaPiece[]): string {
  return formula
    .map((piece) => {
      if (piece.type === EFormulaPieceType.OPERATOR) {
        const key = Object.keys(EConditionalFormulaOperator).find(
          (key) => EConditionalFormulaOperator[key] === piece.value,
        );
        if (key) {
          return BackendFormulaOperatorMapping[key];
        } else {
          return piece.value;
        }
      } else if (piece.type === EFormulaPieceType.CUSTOM_FIELD) {
        return `{${piece.value}}`;
      }
      return '';
    })
    .join('');
}
