<div class="formulaTypeButtons">
  <div class="formulaTypeButton" (click)="toggleIsConditional(false)">
    <pp-radio [ppChecked]="!isConditional"></pp-radio>

    {{ 'standard' | ppTranslate }}
  </div>

  <div class="formulaTypeButton" (click)="toggleIsConditional(true)">
    <pp-radio [ppChecked]="!!isConditional"></pp-radio>

    {{ 'conditional' | ppTranslate }}
  </div>

  <div
    class="formulaBuilder__exampleIfStatement"
    ppTooltip
    [ppSanitize]="false"
    ppTitle="{{ tooltip }}"
  >
    {{ tooltipPreviewText }}
  </div>
</div>

<!-- ============Standard Formula============ -->

<div *ngIf="!isConditional">
  <pp-formula-builder-field
    [ppWorkspaceId]="ppWorkspaceId"
    [(ppFormula)]="formula"
    [ppFieldId]="ppFieldId"
    (ppFormulaChange)="updateFormula($event)"
  ></pp-formula-builder-field>
</div>

<!-- ============Conditional Formula============ -->

<div class="conditionalFormulaContainer" *ngIf="isConditional">
  <!--Condition-->
  <span class="conditionalFieldLabels">{{
    'conditional_formula_condition_label' | ppTranslate
  }}</span>

  <pp-formula-builder-field
    class="formulaBuilderField"
    [ppWorkspaceId]="ppWorkspaceId"
    [(ppFormula)]="conditionalFormula.condition"
    [ppFieldId]="ppFieldId"
    [ppIsConditional]="true"
    (ppExpand)="expandConditionalFormulaField('conditionalFormulaField1')"
    (ppFormulaChange)="updateConditionFormula($event)"
    [expandable]="true"
    [ppPlaceholder]="conditionalFormulaConditionPlaceholder"
    [isOpen]="conditionalFormulaOpen === 'conditionalFormulaField1'"
  ></pp-formula-builder-field>

  <!--Truthy case-->
  <span class="conditionalFieldLabels">{{ 'conditional_formula_truthy_label' | ppTranslate }}</span>
  <pp-formula-builder-field
    class="formulaBuilderField"
    [ppWorkspaceId]="ppWorkspaceId"
    [(ppFormula)]="conditionalFormula.truthy"
    [isOpen]="conditionalFormulaOpen === 'conditionalFormulaField2'"
    [ppFieldId]="ppFieldId"
    (ppFormulaChange)="updateTruthyFormula($event)"
    (ppExpand)="expandConditionalFormulaField('conditionalFormulaField2')"
    [expandable]="true"
    [ppPlaceholder]="conditionalFormulaTruthyPlaceholder"
  ></pp-formula-builder-field>

  <!--Falsy case-->
  <span class="conditionalFieldLabels">{{ 'conditional_formula_falsy_label' | ppTranslate }}</span>
  <pp-formula-builder-field
    class="formulaBuilderField"
    [ppWorkspaceId]="ppWorkspaceId"
    [(ppFormula)]="conditionalFormula.falsey"
    [ppFieldId]="ppFieldId"
    [isOpen]="conditionalFormulaOpen === 'conditionalFormulaField3'"
    (ppExpand)="expandConditionalFormulaField('conditionalFormulaField3')"
    (ppFormulaChange)="updateFalseyFormula($event)"
    [expandable]="true"
    [ppPlaceholder]="conditionalFormulaFalsyPlaceholder"
  ></pp-formula-builder-field>
</div>
