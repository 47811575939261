import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ShareBulkService } from '../../../share/share-bulk.service';
import { TCreateShareDTO, TNewUser, TShare, TUpdateShareDTO } from '../../../share/share.model';
import { TAccountUser } from '../../../users/account.user.model';
import { EditAccountUserModalDataService } from './edit-account-user-modal-data.service';
import { TAccountEditedShare, TSharesByWorkspace } from './edit-account-user-modal.consts';

@Injectable({
  providedIn: 'root',
})
export class EditAccountUserModalService {
  constructor(
    private shareBulkService: ShareBulkService,
    private editAccountUserModalDataService: EditAccountUserModalDataService,
  ) {}

  editIndividualShares(
    newShareTemplates: TShare[],
    selectedShares: TAccountEditedShare[],
  ): Observable<TNewUser[]> {
    const sharesToSend: TUpdateShareDTO[] = [];

    selectedShares.forEach((selectedShare) => {
      selectedShare.shares.forEach((share) => {
        const shareToSend: TUpdateShareDTO = {
          ...newShareTemplates.find((newShare) => share.shareId === newShare.shareId),
          shareId: share.shareId,
          userEmail: share.email,
        };

        sharesToSend.push(shareToSend);
      });
    });

    return this.shareBulkService.bulkEditShares(sharesToSend);
  }
  editShares(
    newShareTemplates: TSharesByWorkspace,
    selectedShares: TAccountEditedShare[],
  ): Observable<TNewUser[]> {
    const sharesToSend: TUpdateShareDTO[] = [];
    const workspaceIds = Object.keys(newShareTemplates);

    if (workspaceIds.length > 0) {
      workspaceIds.forEach((newShareWorkspaceId) => {
        const newShare = newShareTemplates[newShareWorkspaceId];

        selectedShares.forEach((selectedShare) => {
          if (selectedShare.workspaceId !== newShareWorkspaceId) {
            return;
          }

          selectedShare.shares.forEach((share) => {
            const shareToSend: TUpdateShareDTO = {
              ...newShare,
              shareId: share.shareId,
              userEmail: share.email,
            };

            sharesToSend.push(shareToSend);
          });
        });
      });
    }

    return this.shareBulkService.bulkEditShares(sharesToSend);
  }

  createShares(newShares: TSharesByWorkspace, emails: string[]): Observable<TNewUser[]> {
    const sharesToSend = this.prepareSharesToSend(newShares, emails);

    if (Object.keys(newShares).length > 0) {
      return this.shareBulkService.bulkCreateShare(sharesToSend);
    }
  }

  downgradeAccountAdmin(accountShares: TAccountUser[]): Observable<TNewUser[]> {
    const emails = this.editAccountUserModalDataService.getEmailList();
    const newShares = this.addShareIdsToNewShares(emails, accountShares);

    if (Object.keys(newShares).length > 0) {
      return this.shareBulkService.downgradeAccountAdmin(newShares);
    }
  }

  private addShareIdsToNewShares(
    emails: string[],
    accountShares: TAccountUser[],
  ): TCreateShareDTO[] {
    const sharesToSend: TCreateShareDTO[] = [];
    const newShares = this.editAccountUserModalDataService.getSharesMap();
    const accountAdminEmail = emails[0];

    const accountAdminShares = accountShares.find(
      (accountShare) => accountShare.email === accountAdminEmail,
    );

    Object.keys(newShares).forEach((workspaceId) => {
      const newShare = newShares[workspaceId];
      const accountAdminShare = accountAdminShares.shares.find(
        (share) => share.workspaceId === workspaceId,
      );

      newShare.shareId = accountAdminShare.shareId;
      newShare.userEmail = accountAdminEmail;
      sharesToSend.push(newShare);
    });

    return sharesToSend;
  }

  private prepareSharesToSend(newShares: TSharesByWorkspace, emails: string[]): TCreateShareDTO[] {
    const sharesToSend: TCreateShareDTO[] = [];
    const workspaceIds = Object.keys(newShares);

    if (workspaceIds.length > 0) {
      workspaceIds.forEach((workspaceId) => {
        const newShare = newShares[workspaceId];

        emails.forEach((email) => {
          const shareToSend: TCreateShareDTO = { ...newShare, userEmail: email };

          sharesToSend.push(shareToSend);
        });
      });
    }

    return sharesToSend;
  }
}
