<div class="accountEditShare accountEditShare__bulk" *ngIf="bulkShare">
  <div class="sharesHeaders__site">
    {{ 'edit_multiple' | ppTranslate }}
    <pp-icon
      [ppSrc]="EIconPath.ICON_BADGE_INFO"
      ppColor="inherit"
      ppClass="setSize14"
      ppTooltip
      [ppTitle]="'edit_multiple_shares_tooltip' | ppTranslate"
    ></pp-icon>
  </div>

  <pp-account-user-modal-shares-list-share-access
    [(ppShare)]="bulkShare"
    (ppShareChange)="updateShareRole()"
    [ppHideAsterisk]="true"
  ></pp-account-user-modal-shares-list-share-access>

  <pp-account-user-modal-shares-list-share-accessible-tags
    [(ppShare)]="bulkShare"
    [ppWorkspaceId]="ppWorkspaceId"
    (ppShareChange)="updateShareAccessibleTags()"
    [ppDisabled]="
      bulkShare.shareOption === EUserRole.SITE_ADMIN ||
      bulkShare.shareOption === EUserRole.ACCOUNT_ADMIN
    "
    *ngIf="ppShowBulkAccessibleTags"
  ></pp-account-user-modal-shares-list-share-accessible-tags>

  <div class="sharesHeaders__accessibleTags" *ngIf="!ppShowBulkAccessibleTags"></div>
  <pp-account-user-modal-shares-list-share-tags
    [(ppShare)]="bulkShare"
    (ppShareChange)="updateShareTags()"
    [ppDisabled]="
      bulkShare.shareOption === EUserRole.SITE_ADMIN ||
      bulkShare.shareOption === EUserRole.ACCOUNT_ADMIN
    "
    [ppShareOption]="bulkShare.shareOption"
  ></pp-account-user-modal-shares-list-share-tags>
  <pp-account-user-modal-shares-list-share-activity
    [(ppShare)]="bulkShare"
    [ppShareOption]="bulkShare.shareOption"
    (ppShareChange)="updateShareActivity()"
    [ppDisabled]="
      bulkShare.shareOption === EUserRole.SITE_ADMIN ||
      bulkShare.shareOption === EUserRole.ACCOUNT_ADMIN
    "
  ></pp-account-user-modal-shares-list-share-activity>
  <pp-account-user-modal-shares-list-share-exports
    [(ppShare)]="bulkShare"
    (ppShareChange)="updateShareExports()"
    [ppDisabled]="
      bulkShare.shareOption === EUserRole.SITE_ADMIN ||
      bulkShare.shareOption === EUserRole.ACCOUNT_ADMIN
    "
  ></pp-account-user-modal-shares-list-share-exports>
  <pp-account-user-modal-shares-list-share-comments
    [(ppShare)]="bulkShare"
    (ppShareChange)="updateShareComments()"
    [ppDisabled]="
      bulkShare.shareOption === EUserRole.SITE_ADMIN ||
      bulkShare.shareOption === EUserRole.ACCOUNT_ADMIN
    "
  ></pp-account-user-modal-shares-list-share-comments>

  <pp-account-user-modal-shares-list-share-custom-fields
    (ppEditCustomFields)="showCustomFieldsTable()"
    *ngIf="ppShowBulkAccessibleTags"
    [ppUserRole]="bulkShare.shareOption"
    [ppDisabledByCustomFields]="customFieldsCount === 0"
  ></pp-account-user-modal-shares-list-share-custom-fields>
</div>
