<div class="accountEditShare">
  <pp-account-user-modal-shares-list-share-site
    [ppWorkspaceId]="share.workspaceId"
    [ppHasError]="ppHasError"
  ></pp-account-user-modal-shares-list-share-site>
  <pp-account-user-modal-shares-list-share-access
    [(ppShare)]="share"
    (ppShareChange)="updateShare()"
  ></pp-account-user-modal-shares-list-share-access>
  <pp-account-user-modal-shares-list-share-accessible-tags
    [ppWorkspaceId]="ppWorkspaceId"
    [(ppShare)]="share"
    (ppShareChange)="updateShare()"
    [ppDisabled]="
      share.shareOption === EUserRole.SITE_ADMIN || share.shareOption === EUserRole.ACCOUNT_ADMIN
    "
  ></pp-account-user-modal-shares-list-share-accessible-tags>
  <pp-account-user-modal-shares-list-share-tags
    [(ppShare)]="share"
    (ppShareChange)="updateShare()"
    [ppDisabled]="
      share.shareOption === EUserRole.SITE_ADMIN || share.shareOption === EUserRole.ACCOUNT_ADMIN
    "
    [ppShareOption]="share.shareOption"
  ></pp-account-user-modal-shares-list-share-tags>
  <pp-account-user-modal-shares-list-share-activity
    [(ppShare)]="share"
    [ppShareOption]="share.shareOption"
    (ppShareChange)="updateShare()"
    [ppDisabled]="
      share.shareOption === EUserRole.SITE_ADMIN || share.shareOption === EUserRole.ACCOUNT_ADMIN
    "
  ></pp-account-user-modal-shares-list-share-activity>
  <pp-account-user-modal-shares-list-share-exports
    [(ppShare)]="share"
    (ppShareChange)="updateShare()"
    [ppDisabled]="
      share.shareOption === EUserRole.SITE_ADMIN || share.shareOption === EUserRole.ACCOUNT_ADMIN
    "
  ></pp-account-user-modal-shares-list-share-exports>
  <pp-account-user-modal-shares-list-share-comments
    [(ppShare)]="share"
    (ppShareChange)="updateShare()"
    [ppDisabled]="
      share.shareOption === EUserRole.SITE_ADMIN || share.shareOption === EUserRole.ACCOUNT_ADMIN
    "
  ></pp-account-user-modal-shares-list-share-comments>
  <pp-account-user-modal-shares-list-share-custom-fields
    (ppEditCustomFields)="showCustomFieldsTable()"
    [ppUserRole]="share.shareOption"
    [ppDisabledByCustomFields]="share.advancedAccessLevels.customFields.length === 0"
  ></pp-account-user-modal-shares-list-share-custom-fields>
</div>
