import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { AccountService } from '../../../account-service/account.service';
import { TAccount } from '../../../account.model';
import { checkIfHasRole } from '../../../check-if-has-role';

@Component({
  selector: 'pp-account-settings-subscription',
  templateUrl: './account-settings-subscription.component.html',
  styleUrls: ['./account-settings-subscription.component.scss'],
})
export class AccountSettingsSubscriptionComponent implements OnInit {
  private accounts$: Observable<TAccount[]>;
  private readonly destroy$ = new Subject<void>();
  accountId: string;
  isAccountAdmin: boolean;

  constructor(
    private store: Store<{
      accounts: TAccount[];
    }>,
    private activeService: ActiveService,
    private accountService: AccountService,
    private workspaceService: WorkspaceService,
  ) {}

  ngOnInit(): void {
    this.accounts$ = this.store.pipe(select(EStore.ACCOUNTS));

    this.accounts$.pipe(takeUntil(this.destroy$)).subscribe((accounts) => {
      if (accounts.length > 0) {
        this.accountId = this.activeService.getActiveAccountId();
        this.checkIfAccountAdmin();
      }
    });
  }

  private checkIfAccountAdmin(): void {
    const account = this.accountService.getAccount(this.accountId);

    this.isAccountAdmin = checkIfHasRole(
      EUserRole.ACCOUNT_ADMIN,
      account?.workspaces,
      this.workspaceService.getWorkspaces(),
    );
  }
}
