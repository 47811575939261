import { ETimelineTimeframe } from 'src/app/project/modules/site/site-timeline/site-timeline-utils/timeframes-enums';

import { TPreferences } from '../preferences.model';

export function processPreferencesResponse(
  response: TPreferences,
  oldPreferences: TPreferences,
): TPreferences {
  const fetchedPreferences = { ...response };

  const timeline = {
    group: fetchedPreferences.timeline?.group ? fetchedPreferences.timeline.group : null,
    timeframe: fetchedPreferences.timeline?.timeframe
      ? fetchedPreferences.timeline.timeframe
      : ETimelineTimeframe.MONTH,
  };

  const preferences: TPreferences = {
    workspaces: oldPreferences.workspaces,
    dashlets: fetchedPreferences.dashlets,
    commentOnEnter: fetchedPreferences.commentOnEnter,
    dateFormat: fetchedPreferences.dateFormat,
    mapZoomFactor: fetchedPreferences.mapZoomFactor,
    overviewColumns: fetchedPreferences.overviewColumns,
    exports: fetchedPreferences.exports,
    favouriteWorkspaceIds: fetchedPreferences.favouriteWorkspaceIds || [],
    timeline,
  };

  return preferences;
}
