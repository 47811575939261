import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TFormulaPiece } from '../utils/formula-input.type';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'pp-formula-input',
  templateUrl: './formula-input.component.html',
  styleUrl: './formula-input.component.scss',
  animations: [
    trigger('openCloseInput', [
      state(
        'open',
        style({
          height: '*',
        }),
      ),
      state(
        'closed',
        style({
          height: '32px',
        }),
      ),
      transition('open => closed', [animate('0.5s')]),
      transition('closed => open', [animate('0.5s')]),
    ]),
  ],
})
export class FormulaInputComponent {
  @Input() ppFormula: TFormulaPiece[];
  @Output() ppFormulaChange: EventEmitter<TFormulaPiece[]> = new EventEmitter<TFormulaPiece[]>();
  @Input() ppWorkspaceId: string;
  @Input() ppCollapsed: boolean = false;
  @Input() ppPlaceholder: string = '';

  deleteFormulaPiece(index: number): void {
    this.ppFormula.splice(index, 1);
    this.ppFormulaChange.emit(this.ppFormula);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.ppFormula, event.previousIndex, event.currentIndex);

    this.ppFormulaChange.emit(this.ppFormula);
  }
}
