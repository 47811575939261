export enum ESidePanelItem {
  NEW_SITE_BUTTON = 'NEW_SITE_BUTTON',
  ACCOUNT = 'ACCOUNT',
  WORKSPACE = 'WORKSPACE',
  FOLDER = 'FOLDER',
  FOLDER_EMPTY = 'FOLDER_EMPTY',
  END_LINE = 'END_LINE',
  FAVOURITES = 'FAVOURITES',
  FAVOURITES_EMPTY = 'FAVOURITES_EMPTY',
}
