import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { DeviceService } from '../../../core/services/device.service';
import { InputFocusService } from '../../../core/services/input-focus.service';
import { EIconPath } from '../../shared/enums/icons.enum';
import { TSearchBoxSize } from './search-box.component.consts';

@Component({
  selector: 'pp-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
})
export class SearchBoxComponent {
  @ViewChild('input', { static: false }) inputElement: ElementRef;

  @Input() ppPlaceholder: string;
  @Input() ppFocusOnInit = false;
  @Input() ppSize: TSearchBoxSize = 'default';
  @Input() ppColor: string = '#ffffff';
  @Output() ppAction = new EventEmitter<string>();

  isMobile = false;
  isTablet = false;

  searchBox = '';
  focused = false;
  EIconPath = EIconPath;

  constructor(
    private deviceService: DeviceService,
    private inputFocusService: InputFocusService,
  ) {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
  }

  onInputRendered(element: HTMLElement): void {
    if (this.ppFocusOnInit && !this.isMobile && !this.isTablet) {
      this.inputFocusService.immediateFocus(element);
    }
  }

  onInputFocus(): void {
    this.focused = true;
  }

  onInputBlur(): void {
    this.focused = false;
  }

  clear(): void {
    this.searchBox = '';

    this.filterItems();
  }

  filterItems(): void {
    this.ppAction.emit(this.searchBox);
  }

  onEnter(): void {
    this.inputElement.nativeElement.blur();
  }
}
