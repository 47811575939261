import { Component, OnDestroy, OnInit } from '@angular/core';

import { Observable, of, Subject } from 'rxjs';

import { TAllCustomFields, TCustomField, TCustomFieldList } from '../custom-fields.model';

import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { Modal, ModalService } from '../../../components/modal/modal.service';
import { PromptService } from '../../../components/prompt/prompt.service';
import { CustomFieldsService, ECustomFieldsEventType } from '../custom-fields.service';

import { cloneDeep } from 'lodash';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSettings,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { getIsBasicFieldName } from '../add-custom-field-modal/utils/get-is-basic-field-name';
import { ECustomFieldType } from '../custom-field-types-enums';
import { getIsContainsSlash } from '../custom-fields-service.utils';
import { customFieldTypesData, TCustomFieldTypeData } from '../custom-fields.data';
import { TEditCustomFieldModalData } from './edit-custom-field-modal.model';
import { getErrorMessage } from 'src/app/project/helpers/database/get-error-message';
import { EStatusCode } from 'src/app/core/helpers/error-codes';

@Component({
  selector: 'app-edit-custom-field-modal',
  templateUrl: './edit-custom-field-modal.component.html',
  styleUrls: [
    './edit-custom-field-modal.component.scss',
    '../add-custom-field-modal/add-custom-field-modal.component.scss',
  ],
})
export class EditCustomFieldModalComponent implements OnInit, OnDestroy {
  modal: Modal<TEditCustomFieldModalData> = this.modalService.getModal();
  customFields: TAllCustomFields = this.customFieldsService.getCustomFields();
  previousName: string;
  workspaceId: string;

  customField: TCustomFieldTypeData = null;
  editedCustomField: TCustomField;

  customFieldTypesData = customFieldTypesData;
  ECustomFieldType = ECustomFieldType;

  processing = false;
  listCFError = false;
  focused = false;

  sorted: {
    sorted: boolean;
  } = {
    sorted: false,
  };

  private readonly destroy$ = new Subject<void>();

  constructor(
    private customFieldsService: CustomFieldsService,
    private promptService: PromptService,
    private modalService: ModalService,
    private workspaceService: WorkspaceService,
    private translationPipe: TranslationPipe,
  ) {}

  ngOnInit() {
    this.workspaceId = this.modal.data.workspaceId;
    this.editedCustomField = cloneDeep(this.modal.data.customField);

    this.previousName = this.editedCustomField.label;
    this.customField = this.customFieldTypesData.find(
      (cf) => cf.value === this.editedCustomField.type,
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  save(): void {
    if (this.listCFError) {
      const promptText = this.translationPipe.transform(
        'prompt_invalid_character_slash_custom_field',
      );

      this.promptService.showWarning(promptText);

      return;
    }

    if (this.processing) {
      return;
    }

    const workspaces = this.workspaceService.getWorkspaces();

    const index = workspaces[this.workspaceId].customFields.findIndex(
      (fieldId) =>
        this.customFields[this.workspaceId][fieldId].label.toLowerCase() ===
        this.editedCustomField.label.toLowerCase(),
    );

    const basicFieldName = getIsBasicFieldName(this.editedCustomField.label);
    const emptyListLabel = false;

    this.editedCustomField.label = this.editedCustomField.label.trim();

    if (
      this.editedCustomField.type === ECustomFieldType.LIST ||
      this.editedCustomField.type === ECustomFieldType.MULTI_LIST
    ) {
      const containsEmptyListLabel = this.checkEmptyListLabel(this.editedCustomField);
      const containsSlash = this.checkSlash(this.editedCustomField.subList);

      if (containsEmptyListLabel || containsSlash) {
        this.processing = false;

        return;
      }
    }

    if (
      (this.editedCustomField.type === ECustomFieldType.NUMBERS ||
        this.editedCustomField.type === ECustomFieldType.PERCENTAGE) &&
      !this.editedCustomField.decimalPlaces
    ) {
      this.editedCustomField.decimalPlaces = 0;
    }

    if (
      this.editedCustomField.type === ECustomFieldType.FORMULA &&
      this.editedCustomField.outputType === ECustomFieldType.COST
    ) {
      this.editedCustomField.decimalPlaces = 2;
    }

    if (
      (index === -1 ||
        this.customFields[this.workspaceId][workspaces[this.workspaceId].customFields[index]].id ===
          this.editedCustomField.id) &&
      !basicFieldName &&
      !emptyListLabel
    ) {
      this.processing = true;

      this.updateCustomField()
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => {
            this.processing = false;
          }),
        )
        .subscribe();
    } else {
      const promptText = this.translationPipe.transform('prompt_new_custom_field_taken');

      this.promptService.showWarning(promptText);
    }
  }

  hideModal(): void {
    this.modalService.hideModal();
  }

  updateListError(error: boolean): void {
    this.listCFError = error;
  }

  checkSaveButtonDisabled(): boolean {
    const isMissingLabel = !this.editedCustomField.label;
    const isMissingFormula =
      this.editedCustomField.type === ECustomFieldType.FORMULA && !this.editedCustomField.formula;
    const isCostField = this.editedCustomField.type === ECustomFieldType.COST;
    const isMissingFormulaOutput =
      this.editedCustomField.type === ECustomFieldType.FORMULA &&
      !this.editedCustomField.outputType;
    const isFormulaCostOutput =
      this.editedCustomField.type === ECustomFieldType.FORMULA &&
      this.editedCustomField.outputType === ECustomFieldType.COST;
    const isMissingCost =
      (isCostField || isFormulaCostOutput) && !this.editedCustomField.currencyCode;

    return (
      isMissingLabel ||
      this.processing ||
      this.listCFError ||
      isMissingCost ||
      isMissingFormulaOutput ||
      isMissingFormula
    );
  }

  private updateCustomField(): Observable<TCustomField[]> {
    const customFieldDTO = this.customFieldsService.generateCustomFieldRequestData(
      this.editedCustomField,
    ) as TCustomField;

    customFieldDTO.id = this.editedCustomField.id;

    return this.customFieldsService.updateCustomField(this.workspaceId, customFieldDTO).pipe(
      tap((response) => {
        const promptText = this.translationPipe.transform('prompt_field_updated');

        response.forEach((cf) => {
          this.customFieldsService.updateWorkspaceCustomField(this.workspaceId, cf);
        });

        logEventInGTAG(EGoogleEventSettings.SETTINGS__CF__EDIT, {
          event_category: EGoogleEventCategory.SETTINGS,
        });

        this.promptService.showSuccess(promptText);
        this.modalService.hideModal(false);
      }),
      catchError((error) => {
        if (this.editedCustomField.type && error.status === EStatusCode.BAD_REQUEST) {
          getErrorMessage(error).then((message) => {
            this.promptService.showError(message);
          });
        } else {
          const promptText = this.translationPipe.transform('prompt_field_updated_error');

          this.promptService.showError(promptText);
        }

        return of(null);
      }),
    );
  }

  private checkSlash(customFieldSubList: TCustomFieldList[] | undefined): boolean {
    const slashFound = getIsContainsSlash(customFieldSubList);

    if (slashFound) {
      const promptText = this.translationPipe.transform(
        'prompt_invalid_character_slash_custom_field',
      );

      this.promptService.showWarning(promptText);
    }

    return slashFound;
  }

  private checkEmptyListLabel(customField: TCustomField): boolean {
    const hasEmptyLabel = JSON.stringify(customField).includes('"label":""');

    if (hasEmptyLabel) {
      const promptText = this.translationPipe.transform('prompt_fill_all_warning');

      this.promptService.showWarning(promptText);
      this.customFieldsService.emit(ECustomFieldsEventType.SAVE_CUSTOM_FIELD_CLICKED);
    }

    return hasEmptyLabel;
  }
}
