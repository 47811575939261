<ng-container *ngIf="dropdown?.data">
  <a
    id="newSiteInFolderButton"
    data-m-target="New site button"
    class="dropdownItem"
    (click)="goToNewSitePage()"
  >
    {{ 'new_site' | ppTranslate }}
  </a>

  <a
    id="renameFolderButton"
    data-m-target="Rename folder button"
    class="dropdownItem"
    (click)="renameFolder()"
  >
    {{ 'rename_folder' | ppTranslate }}
  </a>

  <a
    id="deleteFolderButton"
    data-m-target="Delete folder dropdown button"
    class="dropdownItem"
    (click)="deleteFolder()"
  >
    {{ 'delete_folder' | ppTranslate }}
  </a>
</ng-container>
