import { FormulaPieceComponent } from '../formula-piece/formula-piece.component';
import { EFormulaPieceType, TConditionalFormula, TFormulaPiece } from './formula-input.type';
import { EConditionalFormulaOperator, EFormulaOperator } from './formula-operators.enum';
import { BackendFormulaOperatorMapping } from './formula.consts';

export function deconstructConditionalFormula(value: string): TConditionalFormula {
  const trimmedFormula = value.slice(3, -1);
  const splitFormula = trimmedFormula.split(',');
  const conditionalSection = deconstructFormula(splitFormula[0]);
  const truthySection = deconstructFormula(splitFormula[1]);
  const falseySection = deconstructFormula(splitFormula[2]);

  const returnValue: TConditionalFormula = {
    condition: conditionalSection,
    truthy: truthySection,
    falsey: falseySection,
  };

  return returnValue;
}

export function deconstructFormula(value: string): TFormulaPiece[] {
  const result: TFormulaPiece[] = [];
  if (!value) return result;

  let i = 0;
  while (i < value.length) {
    if (Object.values(EFormulaOperator).includes(value[i] as EFormulaOperator)) {
      result.push({ type: EFormulaPieceType.OPERATOR, value: value[i] });

      i++;
    }

    if (value[i] === '{') {
      // Handle 'field' case by detecting the value inside {}
      const endBracket = value.indexOf('}', i);

      if (endBracket !== -1) {
        const fieldValue = value.slice(i + 1, endBracket);
        result.push({ type: EFormulaPieceType.CUSTOM_FIELD, value: fieldValue });
        i = endBracket + 1; // Move past the closing bracket
      }
    } else {
      let found = false;
      for (const [key, operator] of Object.entries(BackendFormulaOperatorMapping)) {
        if (value.startsWith(operator, i)) {
          result.push({
            type: EFormulaPieceType.OPERATOR,
            value: EConditionalFormulaOperator[key],
          });
          i += operator.length;
          found = true;

          break;
        }
      }
      if (value[i] && !found) {
        result.push({ type: EFormulaPieceType.OPERATOR, value: value[i] });
        i++;
      }
    }
  }
  return result;
}
