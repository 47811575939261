import { Injectable } from '@angular/core';
import {
  TAccountSimpleResponse,
  TRequestFolder,
  TWorkspaceSimpleResponse,
} from '@project/view-models';
import { cloneDeep } from 'lodash';
import { forkJoin, map, Observable, of, Subject, tap } from 'rxjs';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { TFlattenedAccount } from '../../account-list/account-list/account-list-flatten-account';
import { NEW_FOLDER_ID } from '../../account-list/utils/account-list-constants';
import {
  ADD_VISIBLE_EMPTY_FOLDER,
  GET_VISIBLE_EMPTY_FOLDERS,
} from '../../account-list/utils/account-list.ui.store';
import { setChangedAccounts } from '../../account/account';
import { AccountService } from '../../account/account-service/account.service';
import { generateShares } from '../../share/share.utils';
import { SharesService } from '../../share/shares.service';
import { SET_SHARES } from '../../share/shares.store';
import { UserService } from '../../user/user.service';
import { BasicWorkspaceService } from '../../workspace/basic-workspace.service';
import { TWorkspace, TWorkspacesById } from '../../workspace/workspace.model';

@Injectable({
  providedIn: 'root',
})
export class SidePanelSitesListService {
  private accounts: TAccountSimpleResponse[];

  private _accountsChange$ = new Subject<TAccountSimpleResponse[]>();
  public accountsChange$ = this._accountsChange$.asObservable();

  private markedForRefresh = false;

  constructor(
    private basicWorkspaceService: BasicWorkspaceService,
    private sharesService: SharesService,
    private promptService: PromptService,
    private accountService: AccountService,
    private translationPipe: TranslationPipe,
    private userService: UserService,
  ) {}

  fetchWorkspaces(): Observable<TAccountSimpleResponse[]> {
    const fetchWorkspacesRequest = this.basicWorkspaceService.fetchBasicWorkspaces(
      !this.userService.getUser().isSuperUser,
    );
    const fetchSharesRequest = this.sharesService.fetchShares();

    if (this.accounts && !this.markedForRefresh) {
      return of(this.accounts);
    }

    this.markedForRefresh = false;

    return forkJoin([fetchWorkspacesRequest, fetchSharesRequest]).pipe(
      map(([workspacesResponse, sharesResponse]) => {
        const workspaces: TWorkspacesById = {};

        workspacesResponse.forEach((account) => {
          account.workspaces.forEach((workspace) => {
            workspace.accountId = account.id;
            workspaces[workspace.workspaceId] = workspace as Partial<TWorkspace> as TWorkspace;
          });
        });

        const accounts = cloneDeep(workspacesResponse);
        const shares = generateShares(sharesResponse, workspaces);
        SET_SHARES(cloneDeep(shares));

        this.accounts = accounts;

        return accounts;
      }),
    );
  }

  clearAccounts(): void {
    this.accounts = null;
  }

  updateSiteName(workspaceId: string, name: string): void {
    if (!this.accounts) {
      return;
    }

    const site = this.findSite(workspaceId);

    site.name = name;

    this.sendUpdateEvent();
  }

  updateFolders(accountId: string, newAccount: TAccountSimpleResponse): void {
    const account = this.accounts.find((account) => account.id === accountId);
    account.accountFolders = newAccount.folders;

    this.sendUpdateEvent();
  }

  createNewFolder(accountId: string) {
    const accountIndex = this.accounts.findIndex((_account) => _account.id === accountId);

    this.accounts[accountIndex].accountFolders.push({
      id: NEW_FOLDER_ID,
      name: '',
    });

    this.sendUpdateEvent();
  }

  removeNewFolder(accountId: string): void {
    const accountIndex = this.accounts.findIndex((_account) => _account.id === accountId);

    this.accounts[accountIndex].accountFolders = this.accounts[accountIndex].accountFolders.filter(
      (folder) => folder.id !== NEW_FOLDER_ID,
    );

    this.sendUpdateEvent();
  }

  saveNewFolder(folder: TFlattenedAccount): void {
    const account = this.accounts.find((_account) => _account.id === folder.accountId);

    if (account.accountFolders) {
      const existingFolder = account.accountFolders.find((_folder) => _folder.name === folder.name);

      if (existingFolder) {
        const folderAlreadyVisible =
          GET_VISIBLE_EMPTY_FOLDERS()[folder.accountId]?.find(
            (folderId) => folderId === existingFolder.id,
          ) || folder.workspaces.length > 0;

        if (folderAlreadyVisible) {
          const prompt = this.translationPipe.transform('prompt_folder_already_exists');
          this.promptService.showError(prompt);
          this.removeEmptyFolder(account);
          return;
        }

        ADD_VISIBLE_EMPTY_FOLDER(folder.accountId, folder.name);
        const prompt = this.translationPipe.transform('prompt_folder_created');
        this.promptService.showError(prompt);
        this.removeEmptyFolder(account);

        return;
      }

      this.accountService
        .addNewFolder(folder.accountId, folder.name)
        .pipe(
          tap((response) => {
            account.accountFolders = cloneDeep(response.folders);

            ADD_VISIBLE_EMPTY_FOLDER(folder.accountId, folder.name);
            const prompt = this.translationPipe.transform('prompt_folder_created');
            this.promptService.showSuccess(prompt);

            this.sendUpdateEvent();
          }),
        )
        .subscribe();
    }
  }

  private removeEmptyFolder(account: TAccountSimpleResponse): void {
    account.accountFolders = account.accountFolders.filter(
      (_folder) => _folder.id !== NEW_FOLDER_ID,
    );

    this.sendUpdateEvent();
  }

  addSiteToFolder(accountId: string, workspaceId: string, folderId: string): void {
    const account = this.accounts.find((_account) => _account.id === accountId);

    const body: TRequestFolder = {
      workspacesToAdd: [workspaceId],
    };

    this.accountService
      .editFolder(accountId, folderId, body)
      .pipe(
        tap((response) => {
          setChangedAccounts(true);

          account.accountFolders = cloneDeep(response.folders);

          this.sendUpdateEvent();
        }),
      )
      .subscribe();
  }

  removeSiteFromFolder(accountId: string, workspaceId: string, folderId: string): void {
    const account = this.accounts.find((_account) => _account.id === accountId);
    const folder = account.accountFolders.find((_folder) => _folder.id === folderId);

    if (folder) {
      ADD_VISIBLE_EMPTY_FOLDER(accountId, folder.name);
    }

    const body: TRequestFolder = {
      workspacesToDelete: [workspaceId],
    };

    this.accountService
      .editFolder(accountId, folderId, body)
      .pipe(
        tap((response) => {
          setChangedAccounts(true);
          account.accountFolders = cloneDeep(response.folders);
          this.sendUpdateEvent();
        }),
      )
      .subscribe();
  }

  markForRefresh(): void {
    // Actions like hiding a site, deleting a site etc are so rare that we can afford to refresh the whole list
    this.markedForRefresh = true;
  }

  private sendUpdateEvent(): void {
    this._accountsChange$.next(this.accounts);
  }

  private findSite(siteId: string): TWorkspaceSimpleResponse {
    for (let i = 0; i < this.accounts.length; i++) {
      const account = this.accounts[i];

      for (let j = 0; j < account.workspaces.length; j++) {
        const workspace = account.workspaces[j];

        if (workspace.workspaceId === siteId) {
          return workspace;
        }
      }
    }
  }
}
