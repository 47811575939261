<div [@openCloseInput]="isOpen ? 'open' : 'closed'" class="formulaBuilder" (click)="toggleIsOpen()">
  <pp-formula-input
    [(ppFormula)]="ppFormula"
    (ppFormulaChange)="updateFormula($event)"
    [ppWorkspaceId]="ppWorkspaceId"
    [ppCollapsed]="!isOpen"
    [ppPlaceholder]="ppPlaceholder"
  ></pp-formula-input>
  <div class="formulaBuilderBottom">
    <pp-formula-builder-operators
      (ppSelectOperator)="selectOperator($event)"
      [ppIsConditional]="ppIsConditional"
    ></pp-formula-builder-operators>
    <pp-formula-builder-custom-fields
      [ppFieldId]="ppFieldId"
      (ppSelectOperator)="selectOperator($event)"
      [ppWorkspaceId]="ppWorkspaceId"
      [ppFormula]="ppFormula"
    ></pp-formula-builder-custom-fields>
  </div>
</div>
