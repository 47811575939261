<div class="sidePanelFolder" (click)="toggleFolder()">
  <div class="sidePanelFolder__inner">
    <pp-icon
      [ppSrc]="
        collapsed
          ? EIconPath.SIDE_PANEL_SITE_LIST_FOLDER_CLOSED
          : EIconPath.SIDE_PANEL_SITE_LIST_FOLDER_OPENED
      "
      ppColor="inherit"
      ppClass="setSize16"
    ></pp-icon>

    <span
      class="sidePanelFolder__text"
      *ngIf="!editing"
      ppTooltip
      [ppTrimTooltip]="true"
      [ppTitle]="ppFolder.name"
    >
      {{ ppFolder.name }}
    </span>

    <span *ngIf="editing" class="sidePanelFolder__inputWrapper">
      <input
        type="text"
        [(ngModel)]="ppFolder.name"
        class="sidePanelFolder__input"
        (change)="updateFolder()"
        autofocus
      />

      <pp-icon
        [ppSrc]="EIconPath.ICON_MISC_CLOSE_14"
        ppColor="inherit"
        ppClass="setSize14"
        (click)="cancelEditing()"
      ></pp-icon>
    </span>
  </div>

  <button
    class="folderOptionsButton"
    *ngIf="showFolderOptions"
    (click)="toggleFolderSettings($event)"
    [id]="optionsButtonId"
    ppTooltip
    [ppTitle]="'folder_settings' | ppTranslate"
  >
    <pp-icon
      [ppSrc]="EIconPath.ICON_NAV_THREE_DOTS_VERTICAL_18"
      ppColor="inherit"
      ppClass="setSize18"
    ></pp-icon>
  </button>
</div>
