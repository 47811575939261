<div class="previewContainer">
  <div class="logoPreview__wrapper">
    <img class="logoPreview" [ppSecureSrc]="logoSrc" *ngIf="logoSrc" />
    <img class="logoPreview" [src]="logoBuffer" *ngIf="logoBuffer" />
  </div>

  <svg
    width="272"
    height="234"
    viewBox="0 0 272 234"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="252" height="194" transform="translate(20 14)" fill="white" />
    <g filter="url(#filter0_d_594_6851)">
      <rect
        width="40"
        height="194"
        transform="translate(20 14)"
        [attr.fill]="ppWhiteLabel.data.panelBg ? ppWhiteLabel.data.panelBg : '#181D23'"
      />
      <path
        d="M41.4233 41.3439C41.0782 41.0609 40.6652 40.8862 40.2312 40.8397C39.7972 40.7932 39.3597 40.8768 38.9682 41.0809C38.5767 41.2849 38.247 41.6014 38.0166 41.9943C37.7861 42.3872 37.6641 42.8407 37.6644 43.3035C37.6611 43.7046 37.7526 44.1002 37.9306 44.4541C38.2067 45.0295 38.6756 45.6432 38.9879 46.1116C40.8164 48.8022 41.5339 49.5926 40.5315 51.9904C40.3387 52.3612 40.1546 52.7412 39.9979 53.112V53.1213C39.2727 51.3267 37.9164 49.5182 36.7814 47.769C36.2205 46.9112 35.504 45.8477 35.2191 44.8633C35.0758 44.3572 35.002 43.8319 35 43.3035C35.0002 42.5328 35.1591 41.7714 35.4655 41.0726C35.7718 40.3738 36.2183 39.7544 36.7737 39.2576L37.3434 39.2471C38.8871 39.2378 40.4318 39.676 41.3039 41.1289C41.349 41.197 41.389 41.2689 41.4233 41.3439Z"
        fill="#4DA0E5"
        *ngIf="!logoSrc"
      />
      <path
        d="M39.9991 38C41.2366 38.0001 42.4302 38.487 43.3491 39.3665C44.2679 40.2461 44.8468 41.4559 44.9736 42.7619C45.1005 44.068 44.7664 45.3776 44.036 46.4374C43.3055 47.4973 42.2306 48.2322 41.019 48.5001C40.9182 48.2955 40.8163 48.1003 40.6969 47.905C40.2828 47.2076 39.7964 46.5207 39.3373 45.8477L39.1993 45.6339C39.4555 45.7331 39.7263 45.7827 39.9991 45.7803C40.5803 45.7816 41.141 45.5523 41.5711 45.1374C42.0013 44.7227 42.2697 44.1523 42.3238 43.5383C42.4159 42.8072 42.1047 38.7811 37.3434 38.8194C38.1381 38.2869 39.0591 38.0047 39.9991 38.0058"
        fill="#FFD02E"
        *ngIf="!logoSrc"
      />
      <path
        d="M39.9991 41.3148C40.3698 41.3148 40.7322 41.4315 41.0405 41.65C41.3487 41.8685 41.589 42.1791 41.7309 42.5425C41.8728 42.9059 41.9099 43.3057 41.8375 43.6915C41.7652 44.0772 41.5867 44.4316 41.3245 44.7097C41.0624 44.9878 40.7284 45.1772 40.3648 45.254C40.0012 45.3307 39.6242 45.2913 39.2817 45.1408C38.9392 44.9903 38.6464 44.7354 38.4405 44.4084C38.2345 44.0813 38.1246 43.6968 38.1246 43.3035C38.1246 42.7761 38.322 42.2703 38.6736 41.8973C39.0251 41.5244 39.5019 41.3148 39.9991 41.3148Z"
        fill="#E45050"
        *ngIf="!logoSrc"
      />
      <path
        d="M40.9544 52.5855C41.7454 52.7017 42.2877 52.9655 42.2877 53.2585C42.2877 53.6676 41.2678 54 39.9991 54C38.7304 54 37.7104 53.6687 37.7104 53.2596C37.7104 52.9667 38.2582 52.7041 39.0427 52.5867C39.067 52.6133 39.0886 52.6425 39.1073 52.6738C38.6844 52.7517 38.3996 52.8981 38.3996 53.0539C38.3996 53.3177 39.1161 53.5188 39.9991 53.5188C40.8821 53.5188 41.5986 53.3142 41.5986 53.0539C41.5986 52.8981 41.3225 52.7517 40.8909 52.6738C40.9183 52.6447 40.9369 52.6157 40.9555 52.5959L40.9544 52.5855Z"
        fill="white"
        *ngIf="!logoSrc"
      />
      <g>
        <rect
          x="28.5"
          y="80.5"
          width="23"
          height="23"
          rx="3.5"
          [attr.stroke]="ppWhiteLabel.data.panelFont ? ppWhiteLabel.data.panelFont : '#313B48'"
        />
        <circle cx="40" cy="92" r="3" [attr.stroke]="'#FFFFFF'" [attr.fill]="'#FFFFFF'" />
      </g>
      <rect
        x="28"
        y="112"
        width="24"
        height="24"
        rx="4"
        [attr.fill]="
          ppWhiteLabel.data.panelFontHighlight ? ppWhiteLabel.data.panelFontHighlight : '#0084F8'
        "
      />
      <circle cx="40" cy="124" r="3" [attr.fill]="'#FFFFFF'" />
      <g>
        <rect
          x="28.5"
          y="144.5"
          width="23"
          height="23"
          rx="3.5"
          [attr.stroke]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
        />
        <circle
          cx="40"
          cy="156"
          r="3"
          [attr.fill]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
        />
      </g>
      <g>
        <rect
          x="28.5"
          y="176.5"
          width="23"
          height="23"
          rx="3.5"
          [attr.stroke]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
        />
        <circle
          cx="40"
          cy="188"
          r="3"
          [attr.fill]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
        />
      </g>
      <rect
        width="97"
        height="194"
        transform="translate(60 14)"
        [attr.fill]="ppWhiteLabel.data.panelAccent ? ppWhiteLabel.data.panelAccent : '#2D3A40'"
      />
      <rect
        x="65.5"
        y="22"
        width="69"
        height="8"
        rx="4"
        [attr.fill]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
      />
      <rect
        x="66"
        y="40.5"
        width="85"
        height="14"
        rx="3.5"
        [attr.stroke]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
      />
      <rect
        opacity="0.5"
        x="73.5"
        y="46"
        width="3"
        height="3"
        rx="1.5"
        [attr.fill]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
      />
      <rect
        opacity="0.5"
        x="82.5"
        y="46"
        width="61"
        height="3"
        rx="1.5"
        [attr.fill]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
      />
      <g opacity="0.5">
        <path
          d="M65.5 65.3335L68.8333 68.6668L72.1667 65.3335H65.5Z"
          [attr.fill]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
        />
        <rect
          x="78.1666"
          y="65"
          width="46.2222"
          height="4"
          rx="2"
          [attr.fill]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
        />
      </g>
      <rect
        x="65.5"
        y="75"
        width="23"
        height="4"
        rx="2"
        [attr.fill]="ppWhiteLabel.data.primary500 ? ppWhiteLabel.data.primary500 : '#2E9DFF'"
      />
      <rect
        opacity="0.5"
        x="65.5"
        y="85"
        width="52"
        height="4"
        rx="2"
        [attr.fill]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
      />
      <rect
        x="71.5"
        y="93"
        width="52"
        height="4"
        rx="2"
        [attr.fill]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
      />
      <rect
        x="71.5"
        y="103"
        width="52"
        height="4"
        rx="2"
        [attr.fill]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
      />
      <rect
        x="65.5"
        y="111"
        width="86"
        height="12"
        rx="2"
        [attr.fill]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
        fill-opacity="0.1"
      />
      <rect
        x="71.5"
        y="115"
        width="52"
        height="4"
        rx="2"
        [attr.fill]="
          ppWhiteLabel.data.panelTextHover ? ppWhiteLabel.data.panelTextHover : '#629BCE'
        "
      />
      <rect
        opacity="0.5"
        x="65.5"
        y="129"
        width="52"
        height="4"
        rx="2"
        [attr.fill]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
      />
      <rect
        opacity="0.5"
        x="65.5"
        y="139"
        width="52"
        height="4"
        rx="2"
        [attr.fill]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
      />
      <rect
        opacity="0.5"
        x="65.5"
        y="149"
        width="52"
        height="4"
        rx="2"
        [attr.fill]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
      />
      <g opacity="0.5">
        <path
          d="M65.5 169.667L68.8333 166.333L65.5 163V169.667Z"
          [attr.fill]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
        />
        <rect
          x="74.8334"
          y="164.333"
          width="46.2222"
          height="4"
          rx="2"
          [attr.fill]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M65.5 180.333L68.8333 177L65.5 173.666V180.333Z"
          [attr.fill]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
        />
        <rect
          x="74.8334"
          y="175"
          width="46.2222"
          height="4"
          rx="2"
          [attr.fill]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M65.5 191L68.8333 187.667L65.5 184.333V191Z"
          [attr.fill]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
        />
        <rect
          x="74.8334"
          y="185.667"
          width="46.2222"
          height="4"
          rx="2"
          [attr.fill]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
        />
      </g>
    </g>
    <rect
      x="157"
      y="14"
      width="115"
      height="24"
      [attr.fill]="ppWhiteLabel.data.panelAccent ? ppWhiteLabel.data.panelAccent : '#2D3A40'"
    />
    <defs>
      <filter
        id="filter0_d_594_6851"
        x="0"
        y="0"
        width="177"
        height="234"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="6" />
        <feGaussianBlur stdDeviation="10" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_594_6851" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_594_6851"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</div>
