import { Component, Input } from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TSidePanel } from '../../side-panel.model';
import { SidePanelService } from '../../side-panel.service';

@Component({
  selector: 'pp-side-panel-fleet-management',
  templateUrl: './side-panel-fleet-management.component.html',
  styleUrls: ['./side-panel-fleet-management.component.scss'],
})
export class SidePanelFleetManagementComponent {
  @Input() ppRemindersEnabled: boolean;
  @Input() ppRouterPath: string;
  @Input() ppDisabled: boolean;

  EIconPath = EIconPath;
  sidePanel: TSidePanel = this.sidePanelService.getSidePanel();

  constructor(private sidePanelService: SidePanelService) {}

  goToFleetManagement(): void {
    if (this.ppDisabled) {
      return;
    }

    this.sidePanelService.goToFleetManagement();
  }
}
