import { EConditionalFormulaOperator } from './formula-operators.enum';

export const FORMULA_MAX_CUSTOM_FIELDS = 4;
export const FORMULA_MAX_NESTING_LEVEL = 3; // actually it's 4 but we start counting at 0

export const BackendFormulaOperatorMapping: Record<string, string> = {
  ['EMPTY_OR_ZERO']: 'empty0',
  ['EMPTY']: 'empty',
  ['EQUALS']: '==',
  ['NOT_EQUALS']: '!=',
  ['LESS_THAN_OR_EQUAL_TO']: '<=',
  ['GREATER_THAN_OR_EQUAL_TO']: '>=',
  ['LESS_THAN']: '<',
  ['GREATER_THAN']: '>',
};
