import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSidePanel,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TFlattenedAccount } from '../../../account-list/account-list/account-list-flatten-account';
import { SiteNewService } from '../../../site/site-new/site-new.service';
import { SidePanelService } from '../../side-panel/side-panel.service';

@Component({
  selector: 'pp-side-panel-new-site-button',
  templateUrl: './side-panel-new-site-button.component.html',
  styleUrl: './side-panel-new-site-button.component.scss',
})
export class SidePanelNewSiteButtonComponent {
  @Input() ppItem: TFlattenedAccount;

  EIconPath = EIconPath;

  constructor(
    private router: Router,
    private sidePanelService: SidePanelService,
    private siteNewService: SiteNewService,
  ) {}

  goToNewSitePage(): void {
    logEventInGTAG(EGoogleEventSidePanel.SIDE_PANEL_NEW_SITE, {
      event_category: EGoogleEventCategory.SIDE_PANEL,
    });

    this.siteNewService.setNewSiteFolderId(null);
    this.router.navigate(['/settings/site/new', this.ppItem.accountId]);

    this.sidePanelService.collapsePanel();
  }
}
