import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { TRequestFolder } from '@project/view-models';
import { cloneDeep } from 'lodash';
import { Subject, takeUntil, tap } from 'rxjs';
import {
  ConfirmModalComponent,
  TConfirmModalParams,
} from 'src/app/project/components/confirm-modal/confirm-modal.component';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TFlattenedAccount } from '../../../account-list/account-list/account-list-flatten-account';
import { NEW_FOLDER_ID } from '../../../account-list/utils/account-list-constants';
import {
  ADD_COLLAPSED_FOLDER,
  ADD_VISIBLE_EMPTY_FOLDER,
  DELETE_COLLAPSED_FOLDER,
  GET_COLLAPSED_FOLDERS,
} from '../../../account-list/utils/account-list.ui.store';
import { AccountService } from '../../../account/account-service/account.service';
import { FolderSettingsDropdownComponent } from '../../../account/folder-settings-dropdown/folder-settings-dropdown.component';
import { EUserRole } from '../../../share/share-utils/user-roles';
import { UserService } from '../../../user/user.service';
import { SidePanelSitesListService } from '../side-panel-sites-list.service';

@Component({
  selector: 'pp-side-panel-folder-row',
  templateUrl: './side-panel-folder-row.component.html',
  styleUrl: './side-panel-folder-row.component.scss',
})
export class SidePanelFolderRowComponent implements OnChanges, OnDestroy {
  @Input() ppFolder: TFlattenedAccount;
  @Output() ppUpdate = new EventEmitter<void>();

  private readonly destroy$ = new Subject<void>();

  collapsed: boolean;
  EIconPath = EIconPath;
  showFolderOptions: boolean;
  optionsButtonId: string;
  editing: boolean;
  private nameBackup: string;
  private isNew: boolean;

  private dropdown: TDropdown = this.dropdownService.getDropdown();

  constructor(
    private userService: UserService,
    private dropdownService: DropdownService,
    private accountService: AccountService,
    private translationPipe: TranslationPipe,
    private modalService: ModalService,
    private promptService: PromptService,
    private sidePanelSitesListService: SidePanelSitesListService,
  ) {}

  ngOnChanges(): void {
    this.isNew = this.ppFolder.id === NEW_FOLDER_ID;

    if (this.isNew) {
      this.editing = true;
    }

    this.setOptionsButtonVisibility();

    this.optionsButtonId = this.ppFolder.id + 'settingsButton';
    this.collapsed =
      GET_COLLAPSED_FOLDERS()[this.ppFolder.accountId] &&
      GET_COLLAPSED_FOLDERS()[this.ppFolder.accountId].indexOf(this.ppFolder.id) !== -1;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  toggleFolder(): void {
    if (this.collapsed) {
      DELETE_COLLAPSED_FOLDER(this.ppFolder.accountId, this.ppFolder.id);
    } else {
      ADD_COLLAPSED_FOLDER(this.ppFolder.accountId, this.ppFolder.id);
    }

    this.ppUpdate.emit();
  }

  updateFolder(): void {
    if (this.isNew) {
      this.editing = false;
      this.sidePanelSitesListService.saveNewFolder(this.ppFolder);
    } else {
      this.renameFolder();
    }
  }

  cancelEditing(): void {
    this.editing = false;
    this.ppFolder.name = this.nameBackup;

    if (this.isNew) {
      this.sidePanelSitesListService.removeNewFolder(this.ppFolder.accountId);
    }
  }

  toggleFolderSettings(event: Event): void {
    event.stopPropagation();

    if (this.dropdown.visible && this.dropdown.buttonId === this.optionsButtonId) {
      this.dropdownService.hideDropdown();
    } else {
      this.dropdownService.setData({
        folder: this.ppFolder.id,
        account: this.ppFolder.accountId,
        button: this.optionsButtonId,
      });

      this.dropdownService.showDropdown(this.optionsButtonId, FolderSettingsDropdownComponent, {
        addScrollCallback: true,
        callback: (parameter) => {
          if (parameter === 'RENAME') {
            this.nameBackup = this.ppFolder.name;
            this.editing = true;
          } else if (parameter === 'DELETE') {
            this.deleteFolder();
          }
        },
      });
    }
  }

  private renameFolder(): void {
    this.editing = false;

    const body: TRequestFolder = {
      name: this.ppFolder.name,
    };

    this.accountService
      .editFolder(this.ppFolder.accountId, this.ppFolder.id, body)
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          ADD_VISIBLE_EMPTY_FOLDER(this.ppFolder.accountId, this.ppFolder.name);

          this.sidePanelSitesListService.updateFolders(
            this.ppFolder.accountId,
            cloneDeep(response),
          );

          this.promptService.showSuccess(this.translationPipe.transform('prompt_folder_renamed'));
        }),
      )
      .subscribe();
  }

  private deleteFolder(): void {
    const modalData: TConfirmModalParams = {
      message:
        this.translationPipe.transform('delete_folder_message_1') +
        ` <strong>${this.ppFolder.name}</strong> ` +
        this.translationPipe.transform('delete_folder_message_2'),
      heading: this.translationPipe.transform('delete_folder'),
      redButton: true,
      confirmText: this.translationPipe.transform('Delete'),
    };

    this.modalService.setData(modalData);

    this.modalService.showModal(ConfirmModalComponent, {
      callback: () => {
        this.accountService
          .deleteFolder(this.ppFolder.accountId, this.ppFolder.id)
          .pipe(
            takeUntil(this.destroy$),
            tap((response) => {
              const prompt = this.translationPipe.transform('prompt_folder_deleted');

              this.sidePanelSitesListService.updateFolders(
                this.ppFolder.accountId,
                cloneDeep(response),
              );

              this.promptService.showSuccess(prompt);
            }),
          )
          .subscribe();
      },
    });
  }

  private setOptionsButtonVisibility(): void {
    const user = this.userService.getUser();
    const account = this.ppFolder.account;

    let adminUser = false;

    if (this.ppFolder.workspaces.length === 0) {
      adminUser = true;
    } else {
      for (let workspaceIndex = 0; workspaceIndex < account.workspaces.length; workspaceIndex++) {
        if (
          this.ppFolder.workspaces[workspaceIndex] &&
          (this.ppFolder.workspaces[workspaceIndex].shareOption === EUserRole.SITE_ADMIN ||
            this.ppFolder.workspaces[workspaceIndex].shareOption === EUserRole.ACCOUNT_ADMIN)
        ) {
          adminUser = true;

          break;
        }
      }
    }

    this.showFolderOptions =
      (user.accountId && user.accountId === this.ppFolder.accountId) ||
      user.isSuperUser ||
      adminUser;
  }
}
