import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { tap } from 'rxjs';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TFlattenedAccount } from '../../../account-list/account-list/account-list-flatten-account';
import { PreferencesService } from '../../../preferences/preferences-service/preferences.service';
import { TPreferences } from '../../../preferences/preferences.model';
import { SidePanelService } from '../../side-panel/side-panel.service';

@Component({
  selector: 'pp-side-panel-workspace-row',
  templateUrl: './side-panel-workspace-row.component.html',
  styleUrl: './side-panel-workspace-row.component.scss',
})
export class SidePanelWorkspaceRowComponent {
  @Input() ppWorkspace: TFlattenedAccount;
  @Output() ppUpdate: EventEmitter<void> = new EventEmitter<void>();
  @Input() ppClickable: boolean;

  @HostBinding('class') private get hostClass(): string {
    return this.active ? 'activeWorkspace' : '';
  }

  EIconPath = EIconPath;
  cogButtonId: string;
  active: boolean;
  favourite: boolean;
  private preferences: TPreferences;

  constructor(
    private sidePanelService: SidePanelService,
    private preferencesService: PreferencesService,
  ) {}

  ngOnChanges(): void {
    this.preferences = this.preferencesService.getPreferences();

    this.cogButtonId = this.ppWorkspace.workspace.workspaceId + 'sidePanelSettings';
    this.active = window.location.href.includes(this.ppWorkspace.workspace.workspaceId);
    this.favourite = this.preferences.favouriteWorkspaceIds.includes(
      this.ppWorkspace.workspace.workspaceId,
    );
  }

  openSite(): void {
    if (!this.ppClickable) {
      return;
    }

    this.sidePanelService.collapsePanel();
    this.sidePanelService.switchSite(this.ppWorkspace.workspace.workspaceId);
  }

  toggleFavourite(event: Event): void {
    event.preventDefault();
    event.stopImmediatePropagation();

    if (this.favourite) {
      this.preferencesService
        .removeFavouriteWorkspace(this.ppWorkspace.workspace.workspaceId)
        .pipe(
          tap(() => {
            this.favourite = !this.favourite;

            this.ppUpdate.emit();
          }),
        )
        .subscribe();
    } else {
      this.preferencesService
        .addFavouriteWorkspace(this.ppWorkspace.workspace.workspaceId)
        .pipe(
          tap(() => {
            this.favourite = !this.favourite;

            this.ppUpdate.emit();
          }),
        )
        .subscribe();
    }
  }
}
