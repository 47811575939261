import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { RemindersService } from 'src/app/project/modules/reminders/reminders.service';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSidePanel,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TSidePanel } from '../../side-panel.model';
import { SidePanelService } from '../../side-panel.service';

@Component({
  selector: 'pp-side-panel-reminders',
  templateUrl: './side-panel-reminders.component.html',
  styleUrls: ['./side-panel-reminders.component.scss'],
})
export class SidePanelRemindersComponent {
  @Input() ppRouterPath: string;
  @Input() ppActiveWorkspaceId: string;
  @Input() ppDisabled: boolean;

  sidePanel: TSidePanel = this.sidePanelService.getSidePanel();
  remindersNumber = this.remindersService.getRemindersNumber();
  EIconPath = EIconPath;

  constructor(
    private sidePanelService: SidePanelService,
    private remindersService: RemindersService,
    private router: Router,
  ) {}

  goToReminders(): void {
    if (this.ppActiveWorkspaceId) {
      const routerLink = '/reminders';

      this.sidePanel.notificationsExpanded = false;

      logEventInGTAG(EGoogleEventSidePanel.SIDE_PANEL_REMINDERS, {
        event_category: EGoogleEventCategory.SIDE_PANEL,
      });

      this.router.navigate([routerLink]);
    }
  }
}
