import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '../../../core/pipes/pipes.module';
import { TooltipsModule } from '../../features/tooltip/tooltips.module';
import { IconModule } from '../icon/icon-module';
import { FormulaBuilderCustomFieldsComponent } from './formula-builder-custom-fields/formula-builder-custom-fields.component';
import { FormulaBuilderOperatorsComponent } from './formula-builder-operators/formula-builder-operators.component';
import { FormulaBuilderComponent } from './formula-builder.component';
import { FormulaInputComponent } from './formula-input/formula-input.component';
import { FormulaPieceComponent } from './formula-piece/formula-piece.component';
import { FormulaBuilderFieldComponent } from './formula-builder-field/formula-builder-field.component';
import { RadioModule } from '../input/radio/radio.module';

@NgModule({
  declarations: [
    FormulaBuilderComponent,
    FormulaBuilderFieldComponent,
    FormulaBuilderOperatorsComponent,
    FormulaInputComponent,
    FormulaPieceComponent,
    FormulaBuilderCustomFieldsComponent,
  ],
  imports: [
    CommonModule,
    PipesModule,
    FormsModule,
    TooltipsModule,
    IconModule,
    RadioModule,
    CdkDropList,
    CdkDrag,
  ],
  exports: [FormulaBuilderComponent],
})
export class FormulaBuilderModule {}
