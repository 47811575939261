<li
  id="userDropdownBtn"
  data-m-target="Profile settings button"
  class="sidePanel__user"
  (click)="toggleUserDropdown('userDropdownBtn')"
>
  <img
    ppImage
    [ppSecureSrc]="'api/v1/images/' + ppUser.avatarId + '/file/size/square/40'"
    class="userDropdown__avatar"
    [class.userDropdown__avatar--disabled]="ppDisabled"
    *ngIf="ppUser.avatarId"
  />

  <img
    ppImage
    [src]="EIconPath.PLACEHOLDER_USER"
    class="userDropdown__avatar"
    [class.userDropdown__avatar--disabled]="ppDisabled"
    *ngIf="!ppUser.avatarId"
  />
</li>
