import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { catchError, of, tap } from 'rxjs';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { LimitExceededService } from 'src/app/project/components/change-limit-modal/limit-exceeded.service';
import {
  ConfirmModalComponent,
  TConfirmModalParams,
} from 'src/app/project/components/confirm-modal/confirm-modal.component';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { ResponseErrorService } from '../../errors/response-error.service';
import { AccountSharesService } from '../../share/account-shares.service';
import { ShareBulkService } from '../../share/share-bulk.service';
import { TAccountUser } from '../../users/account.user.model';
import { SelectedSharesService } from '../services/selected-shares.service';

@Injectable({
  providedIn: 'root',
})
export class UserManagementBulkChangesBarService {
  private filteredUsers: TAccountUser[];

  constructor(
    private modalService: ModalService,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private accountSharesService: AccountSharesService,
    private selectedSharesService: SelectedSharesService,
    private responseErrorService: ResponseErrorService,
    private shareBulkService: ShareBulkService,
    private limitExceededService: LimitExceededService,
  ) {}

  deleteShares(accountId: string, shareIds: string[]): void {
    this.setDeleteSharesModalData();

    this.modalService.showModal(ConfirmModalComponent, {
      closeWarning: true,
      callback: () => {
        this.onDeleteSharesConfirm(shareIds, accountId);
      },
    });
  }

  private onDeleteSharesConfirm(shareIds: string[], accountId: string): void {
    this.shareBulkService
      .removeShares(shareIds)
      .pipe(
        tap(() => {
          const promptText = this.translationPipe.transform('prompt_user_shares_deleted');
          this.promptService.showSuccess(promptText);

          this.accountSharesService.removeShares(accountId, shareIds);
          this.selectedSharesService.clearSelectedShares();
        }),
        catchError((error) => {
          if (error.status === EStatusCode.UPGRADE_REQUIRED) {
            this.limitExceededService.showLimitsExceededModal();

            return of(null);
          }
          const promptText = this.translationPipe.transform('prompt_shares_delete_error');
          this.promptService.showError(promptText);

          return this.responseErrorService.handleRequestError(error);
        }),
      )
      .subscribe();
  }

  private setDeleteSharesModalData(): void {
    this.modalService.setData<TConfirmModalParams>({
      message: this.translationPipe.transform('confirm_remove_users'),
      heading: this.translationPipe.transform('remove_users_from_sites'),
      redButton: true,
      confirmText: this.translationPipe.transform('remove_users_button'),
    });
  }

  filterAccountUsers(accountUsers: TAccountUser[], selectedSharesIds: string[]): TAccountUser[] {
    this.filteredUsers = cloneDeep(accountUsers).filter((accountUser) => {
      accountUser.shares = accountUser.shares.filter((share) =>
        selectedSharesIds.includes(share.shareId),
      );

      return accountUser.shares.length > 0;
    });

    return this.filteredUsers;
  }

  getFilteredUsers(): TAccountUser[] {
    return this.filteredUsers;
  }
}
