import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TSidePanel } from '../../side-panel.model';

@Component({
  selector: 'pp-side-panel-backdrop',
  templateUrl: './side-panel-backdrop.component.html',
  styleUrl: './side-panel-backdrop.component.scss',
})
export class SidePanelBackdropComponent {
  @Input() ppSidePanel: TSidePanel;
  @Output() ppClose = new EventEmitter<void>();

  closePanel(): void {
    this.ppClose.emit();
  }
}
