export enum EFormulaOperator {
  PLUS = '+',
  MINUS = '-',
  DIVIDE = '/',
  MULTIPLY = '*',
  DOT = '.',
  ZERO = '0',
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
  FIVE = '5',
  SIX = '6',
  SEVEN = '7',
  EIGHT = '8',
  NINE = '9',
  LEFT_PARENTHESIS = '(',
  RIGHT_PARENTHESIS = ')',
}

export enum EConditionalFormulaOperator {
  GREATER_THAN = '>',
  LESS_THAN = '<',
  GREATER_THAN_OR_EQUAL_TO = '≥',
  LESS_THAN_OR_EQUAL_TO = '≤',
  EQUALS = '=',
  NOT_EQUALS = '≠',
  EMPTY_OR_ZERO = 'Empty or 0',
  EMPTY = 'Empty',
}
