<div class="sitesList" (ppOnRendered)="fixInitScroll($event)">
  <header class="sitesList__title">{{ 'sites_and_accounts' | ppTranslate }}</header>

  <pp-search-box
    [ppPlaceholder]="'search_accounts_and_sites' | ppTranslate"
    [ppFocusOnInit]="false"
    [ppSize]="'wide'"
    ppColor="var(--panelText)"
    (ppAction)="setKeyword($event)"
  ></pp-search-box>

  <pp-side-panel-accounts [ppKeyword]="keyword"></pp-side-panel-accounts>
</div>
