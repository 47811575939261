import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import { TShare } from 'src/app/project/modules/share/share.model';
import { EditAccountUserModalDataService } from '../../edit-account-user-modal-data.service';

@Component({
  selector: 'pp-account-user-modal-shares-list-individual-share',
  templateUrl: './account-user-modal-shares-list-individual-share.component.html',
  styleUrl: './account-user-modal-shares-list-individual-share.component.scss',
})
export class AccountUserModalSharesListIndividualShareComponent implements OnInit {
  @Input() ppShare: TShare;
  @Input() ppHasError: boolean;
  @Output() ppShowCustomFieldsTable = new EventEmitter<void>();
  @Output() ppShareChange = new EventEmitter<TShare>();

  share: TShare;
  EUserRole = EUserRole;

  constructor(private editAccountUserModalDataService: EditAccountUserModalDataService) {}

  ngOnChanges(): void {
    this.share = this.ppShare;

    this.editAccountUserModalDataService.setIndividualShare(this.share);
  }

  ngOnInit(): void {
    this.editAccountUserModalDataService.sharesBulkChange$.subscribe(() => {
      this.share = this.editAccountUserModalDataService.getIndividualShares().find((share) => {
        return share?.shareId === this.ppShare?.shareId;
      });
      this.updateShare(false);
    });

    this.share = this.ppShare;
    this.editAccountUserModalDataService.setIndividualShare(this.share);
  }

  showCustomFieldsTable(): void {
    this.ppShowCustomFieldsTable.emit();
  }

  updateShare(emit: boolean = true): void {
    this.ppShareChange.emit(this.share);
    this.editAccountUserModalDataService.setIndividualShare(this.share);

    if (emit) {
      this.editAccountUserModalDataService.emitSharesChange();
    }
  }
}
