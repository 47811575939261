import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EFormulaPieceType, TFormulaPiece } from '../utils/formula-input.type';
import { EConditionalFormulaOperator, EFormulaOperator } from '../utils/formula-operators.enum';

@Component({
  selector: 'pp-formula-builder-operators',
  templateUrl: './formula-builder-operators.component.html',
  styleUrl: './formula-builder-operators.component.scss',
})
export class FormulaBuilderOperatorsComponent implements OnInit {
  @Output() ppSelectOperator = new EventEmitter<TFormulaPiece>();
  @Input() ppIsConditional: boolean = false;

  operators: TFormulaPiece[] = [];

  ngOnInit(): void {
    const operatorEnums = this.ppIsConditional
      ? { ...EFormulaOperator, ...EConditionalFormulaOperator }
      : EFormulaOperator;

    this.operators = Object.values(operatorEnums).map((operator) => ({
      type: EFormulaPieceType.OPERATOR,
      value: operator,
    }));
  }

  addOperator(operator: TFormulaPiece): void {
    this.ppSelectOperator.emit(operator);
  }
}
