import { cloneDeep } from 'lodash';
import { TColumn } from 'src/app/project/modules/site/site-table/columns/column.model';
import { ETimelineTimeframe } from 'src/app/project/modules/site/site-timeline/site-timeline-utils/timeframes-enums';
import { getBasicColumns, getDefaultWidth } from '../../site/site-table/columns/columns';
import { DEFAULT_DATE_FORMAT } from '../default-date-format';
import { TPreferences } from '../preferences.model';
import { SET_PREFERENCES } from '../preferences.store';

export const processUserPreferencesResponse = (
  response: TPreferences,
  oldPreferences: TPreferences,
): TPreferences => {
  let preferences = getDefaultPreferences(oldPreferences);

  if (response) {
    preferences = processResponse(response, oldPreferences);
  }

  preferences.overviewColumns = checkOverviewPreferencesColumns(preferences.overviewColumns); // fix for people with missing default columns

  for (let i = 0; i < preferences.overviewColumns.length; i++) {
    const column = preferences.overviewColumns[i];

    if (column.customFieldIds) {
      // Remove duplicates
      column.customFieldIds = Array.from(new Set(column.customFieldIds));
    }
  }

  SET_PREFERENCES(preferences);

  return preferences;
};

function getDefaultPreferences(oldPreferences: TPreferences): TPreferences {
  return {
    commentOnEnter: false,
    dateFormat: DEFAULT_DATE_FORMAT,
    mapZoomFactor: 2.0,
    dashlets: { data: [] },
    overviewColumns: [],
    overviewExports: {},
    exports: {},
    workspaces: oldPreferences?.workspaces ? oldPreferences?.workspaces : {},
    timeline: {
      timeframe: ETimelineTimeframe.MONTH,
      group: null,
    },
    favouriteWorkspaceIds: [],
  };
}

function processResponse(
  fetchedPreferences: TPreferences,
  oldPreferences: TPreferences,
): TPreferences {
  const timeline = fetchedPreferences.timeline
    ? {
        timeframe: fetchedPreferences.timeline.timeframe
          ? fetchedPreferences.timeline.timeframe
          : ETimelineTimeframe.MONTH,
        group: fetchedPreferences.timeline.group ? fetchedPreferences.timeline.group : null,
      }
    : {
        timeframe: ETimelineTimeframe.MONTH,
        group: null,
      };

  return {
    commentOnEnter: fetchedPreferences.commentOnEnter,
    dateFormat: fetchedPreferences.dateFormat ? fetchedPreferences.dateFormat : DEFAULT_DATE_FORMAT,
    mapZoomFactor: fetchedPreferences.mapZoomFactor ? fetchedPreferences.mapZoomFactor : 2.0,
    dashlets: fetchedPreferences.dashlets ? fetchedPreferences.dashlets : { data: [] },
    workspaces: fetchedPreferences.workspaces
      ? fetchedPreferences.workspaces
      : oldPreferences.workspaces,
    overviewColumns: fetchedPreferences.overviewColumns ? fetchedPreferences.overviewColumns : [],
    overviewExports: fetchedPreferences.overviewExports ? fetchedPreferences.overviewExports : {},
    timeline,
    exports: fetchedPreferences.exports ? fetchedPreferences.exports : {},
    favouriteWorkspaceIds: fetchedPreferences.favouriteWorkspaceIds
      ? fetchedPreferences.favouriteWorkspaceIds
      : [],
  };
}

function checkOverviewPreferencesColumns(columns: TColumn[]): TColumn[] {
  const basicColumns = getBasicColumns();
  const newColumns = cloneDeep(columns);

  basicColumns.reverse().forEach((_column) => {
    const preferencesColumn = columns.find((prefColumn) => prefColumn.name === _column);

    if (!preferencesColumn) {
      newColumns.unshift({
        name: _column,
        width: getDefaultWidth(_column),
        index: newColumns.length,
      });
    }
  });

  return newColumns;
}
