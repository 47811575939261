import { Component, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSidePanel,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EInstance } from 'src/app/project/shared/enums/instances';
import { EStore } from '../../../../../shared/enums/store.enum';
import { TUser } from '../../../../user/user.model';
import { TSidePanel } from '../../side-panel.model';
import { SidePanelService } from '../../side-panel.service';
import { SidePanelUtilsService } from '../side-panel-utils.service';

@Component({
  selector: 'pp-side-panel-timeline',
  templateUrl: './side-panel-timeline.component.html',
  styleUrls: ['./side-panel-timeline.component.scss'],
})
export class SidePanelTimelineComponent {
  @Input() ppRemindersEnabled: boolean;
  @Input() ppRouterPath: string;
  @Input() ppDisabled: boolean;

  EIconPath = EIconPath;
  timelineBlocked: boolean;
  sidePanel: TSidePanel = this.sidePanelService.getSidePanel();
  private readonly destroy$ = new Subject<void>();
  user: TUser;
  user$: Observable<TUser>;

  constructor(
    @Inject('HOSTNAME') private host: string,
    private store: Store<{
      user: TUser;
    }>,
    private sidePanelService: SidePanelService,
    private sidePanelUtilsService: SidePanelUtilsService,
    private router: Router,
  ) {
    this.user$ = this.store.pipe(select(EStore.USER));

    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;

      this.timelineBlocked = this.user.isSuperUser && this.host !== EInstance.ASM;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  goToTimeline(): void {
    if (this.user.activeWorkspaceId && !this.timelineBlocked) {
      if (!this.router.url.startsWith('/timeline')) {
        this.sidePanel.notificationsExpanded = false;

        this.sidePanelUtilsService.collapsePanel();
        this.sidePanelService.goToTimeline(false);
      }

      logEventInGTAG(EGoogleEventSidePanel.SIDE_PANEL_TIMELINE, {
        event_category: EGoogleEventCategory.SIDE_PANEL,
      });
    }
  }
}
