<pp-side-panel-menu-button
  [ppText]="'reminders' | ppTranslate"
  [ppDataTarget]="'Reminder icon'"
  [ppDataTest]="'remindersMenuItem'"
  [ppShowIndicator]="remindersNumber.number > 0"
  [ppIconSrc]="EIconPath.SIDE_PANEL_REMINDER"
  [ppActive]="ppRouterPath.startsWith('/reminders')"
  [ppDisabled]="ppDisabled"
  (ppAction)="goToReminders()"
></pp-side-panel-menu-button>
