import { Component } from '@angular/core';

@Component({
  selector: 'pp-side-panel-sites-list',
  templateUrl: './side-panel-sites-list.component.html',
  styleUrl: './side-panel-sites-list.component.scss',
})
export class SidePanelSitesListComponent {
  keyword = '';
  private searchKeywordDebounceTime = 500;
  private setKeywordTimeout: NodeJS.Timeout;

  setKeyword(keyword: string): void {
    if (this.setKeywordTimeout) {
      clearTimeout(this.setKeywordTimeout);
    }

    this.setKeywordTimeout = setTimeout(() => {
      this.keyword = keyword;
    }, this.searchKeywordDebounceTime);
  }

  fixInitScroll(event: HTMLElement): void {
    const interval = setInterval(() => {
      if (event.scrollTop === 0 && event.scrollLeft === 0) {
        return;
      }

      event.scrollTop = 0;
      event.scrollLeft = 0;

      clearInterval(interval);
    }, 10);
  }
}
