import { TAccountSimpleResponse } from '@project/view-models';

export class AccountsSimpleModelFactory {
  static createFromDTO(response: TAccountSimpleResponse[]): TAccountSimpleResponse[] {
    return response.map((account) => {
      account.accountFolders = account.accountFolders
        ? account.accountFolders.map((folder) => {
            if (!folder.workspaceIds) {
              folder.workspaceIds = [];
            }

            return folder;
          })
        : [];

      return account;
    });
  }
}
