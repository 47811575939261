import { Component } from '@angular/core';

@Component({
  selector: 'pp-side-panel-favourites-empty-row',
  templateUrl: './side-panel-favourites-empty-row.component.html',
  styleUrl: './side-panel-favourites-empty-row.component.scss'
})
export class SidePanelFavouritesEmptyRowComponent {

}
