import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { catchError, takeUntil, tap } from 'rxjs';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { LimitExceededService } from 'src/app/project/components/change-limit-modal/limit-exceeded.service';
import {
  ConfirmModalComponent,
  TConfirmModalParams,
} from 'src/app/project/components/confirm-modal/confirm-modal.component';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { ResponseErrorService } from 'src/app/project/modules/errors/response-error.service';
import { ShareBulkService } from 'src/app/project/modules/share/share-bulk.service';
import { SharesService } from 'src/app/project/modules/share/shares.service';
import { SelectedSharesService } from 'src/app/project/modules/user-management/services/selected-shares.service';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { SiteSettingsUsersService } from '../../site-settings-users.service';

@Component({
  selector: 'pp-site-settings-users-floating-actions',
  templateUrl: './site-settings-users-floating-actions.component.html',
  styleUrls: ['./site-settings-users-floating-actions.component.scss'],
})
export class SiteSettingsUsersFloatingActionsComponent implements OnInit {
  @Input() ppWorkspaceId: string;
  @Input() ppUserId: string;
  EIconPath = EIconPath;

  private readonly destroy$ = new EventEmitter<void>();

  selectedShares: string[] = [];

  constructor(
    private selectedSharesService: SelectedSharesService,
    private shareBulkService: ShareBulkService,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private responseErrorService: ResponseErrorService,
    private modalService: ModalService,
    private sharesService: SharesService,
    private siteSettingsUsersService: SiteSettingsUsersService,
    private limitExceededService: LimitExceededService,
  ) {}

  ngOnInit(): void {
    this.selectedSharesService.selectedSharesIdsChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedShares) => {
        this.selectedShares = selectedShares;
      });
  }

  showDeleteSharesModal(): void {
    this.setDeleteSharesModalData();
    this.modalService.showModal(ConfirmModalComponent, {
      closeWarning: true,
      callback: () => {
        this.onDeleteSharesConfirm();
      },
    });
  }

  deselectAll(): void {
    this.selectedSharesService.clearSelectedShares();
  }

  private onDeleteSharesConfirm(): void {
    this.shareBulkService
      .removeShares(this.selectedShares)
      .pipe(
        tap(() => {
          this.handleDeleteShareSuccess();
        }),
        catchError((error) => {
          if (error.status === EStatusCode.UPGRADE_REQUIRED) {
            this.limitExceededService.showLimitsExceededModal();
          } else {
            this.promptService.showError(
              this.translationPipe.transform('prompt_share_delete_error'),
            );
          }

          return this.responseErrorService.handleRequestError(error);
        }),
      )
      .subscribe();
  }

  private setDeleteSharesModalData(): void {
    this.modalService.setData<TConfirmModalParams>({
      message: this.translationPipe.transform('confirm_remove_users'),
      heading: this.translationPipe.transform('remove_users_from_sites'),
      redButton: true,
      confirmText: this.translationPipe.transform('remove_users_button'),
    });
  }

  private handleDeleteShareSuccess(): void {
    this.sharesService.removeWorkspaceShares(this.selectedShares);
    this.promptService.showSuccess(
      this.translationPipe.transform('prompt_site_user_shares_deleted'),
    );
    this.siteSettingsUsersService.emitBulkSharesDeleted();
    this.selectedSharesService.clearSelectedShares();
  }
}
