import { Component, Input } from '@angular/core';
import { isEqual } from 'lodash';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { GET_SHARES } from '../../../../share/shares.store';
import { TSidePanel } from '../../side-panel.model';
import { SidePanelService } from '../../side-panel.service';
import { SidePanelUtilsService } from '../side-panel-utils.service';

@Component({
  selector: 'pp-side-panel-sites',
  templateUrl: './side-panel-sites.component.html',
  styleUrls: ['./side-panel-sites.component.scss'],
})
export class SidePanelSitesComponent {
  @Input() ppIsMobile: boolean;
  @Input() ppDisabled: boolean;

  sidePanel: TSidePanel = this.sidePanelService.getSidePanel();
  EIconPath = EIconPath;

  constructor(
    private sidePanelService: SidePanelService,
    private sidePanelUtilsService: SidePanelUtilsService,
  ) {}

  toggleRightPanel(): void {
    if (this.ppIsMobile) {
      this.sidePanelService.setRightPanelExpanded(!this.sidePanel.rightPanelExpanded);
    } else {
      this.togglePanel();
    }
  }

  togglePanel(): void {
    if (isEqual(GET_SHARES(), {})) {
      return;
    }

    if (!isEqual(GET_SHARES(), {})) {
      if (this.sidePanel.expanded) {
        this.sidePanelUtilsService.collapsePanel();
      } else {
        this.sidePanelUtilsService.expandPanel();
      }
    }
  }
}
