import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { TTranslationKey } from '../../features/translate/types';
import { logEventInGTAG } from '../../services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventNewSite,
} from '../../services/analytics/google-analytics.consts';
import { Modal, ModalService } from '../modal/modal.service';
import { TChangeLimitsModalData } from './change-limit-modal.model';

@Component({
  selector: 'pp-change-limit-modal',
  templateUrl: './change-limit-modal.component.html',
  styleUrls: ['./change-limit-modal.component.scss'],
})
export class ChangeLimitModalComponent implements OnInit {
  firstMessageKey: TTranslationKey;
  secondMessageKey: TTranslationKey;
  confirmKey: TTranslationKey;
  header: TTranslationKey;
  processing = false;
  contactUsEmail = 'mailto:support@pinpointworks.com';

  private modal: Modal;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.modal = this.modalService.getModal();
    this.processModalData();
  }

  cancelModal(): void {
    this.modalService.hideModal();
  }

  contactUs(): void {
    logEventInGTAG(EGoogleEventNewSite.CREATE_SITE_SUBSCRIPTION_CONTACT, {
      event_category: EGoogleEventCategory.CREATE_SITE,
    });

    this.document.location.href = this.contactUsEmail;
  }

  private processModalData(): void {
    const modalData: TChangeLimitsModalData = this.modal.data;
    this.firstMessageKey = modalData.firstMessageKey;
    this.secondMessageKey = modalData.secondMessageKey;
    this.confirmKey = modalData.confirmKey;
    this.header = modalData.header;
  }
}
